import { UNPERMITTED_AIDS_RESULTS } from '../utils/constants/constants'
import { createDate } from '../utils/helper/dateTimeHelper'

export class Result {
  constructor({ ...props }) {
    this.entity = 'Result'
    this.cameraAnomalies = props.cameraAnomalies
    this.canMatch = props.canMatch
    this.crosstestResult = props.crosstestResult
    this.grade = props.canMatch === false ? '' : props.grade
    this.gradeLabel = props.gradeLabel
    this.modified = createDate(props.modified)
    this.normResult = props.canMatch === false ? 'n/a' : props.normResult
    this.dimensionScores = props.dimensionScores
    this.notes = props.notes
    this.recommendation = props.recommendation
    this.resultNr = props.resultNr
    this.tabTrackingIndex = props.tabTrackingIndex
    this.unpermittedAids = props.unpermittedAids
    this.unpermittedAidsIndex = getUnpermittedAidsIndex(props.unpermittedAids)
    this.windowChangeCount = props.windowChangeCount
    this.windowChangeCountCutoff = props.windowChangeCountCutoff
    this.windowChangeTime = props.windowChangeTime
  }
}

const getUnpermittedAidsIndex = (unpermittedAids) => {
  if (unpermittedAids.de === null) return UNPERMITTED_AIDS_RESULTS.questionNotShown
  return unpermittedAids?.de?.length > 0
    ? UNPERMITTED_AIDS_RESULTS.usedUnpermittedAids
    : UNPERMITTED_AIDS_RESULTS.didNotUseUnpermittedAids
}
