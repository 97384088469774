import { useContext, useEffect } from 'react'
import { AppContext } from '../../utils/context/AppContext'
import { setState } from '../../utils/helper/Helper'

const useTemplateUpdates = (data, setData, defaultTemplates) => {
  const context = useContext(AppContext)
  useEffect(() => {
    setState(setData, 'availableTemplates', [...defaultTemplates, ...context.completeDataSet.ptInvitationTemplates])
  }, [context.completeDataSet.ptInvitationTemplates])

  useEffect(() => {
    if (data.templateKey) {
      const template = data.availableTemplates.find((t) => t.publicKey === data.templateKey)
      setData((prev) => ({
        ...prev,
        templateName: template.name,
        subject: template.templateData.subject,
        topText: template.templateData.topText,
        bottomText: template.templateData.bottomText,
        salutationType: template.templateData.salutationType
      }))
    }
  }, [data.templateKey, data.templateName])
}

export default useTemplateUpdates
