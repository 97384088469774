import { B, Image, Table } from '../userManualElements'

const Chapter1 = () => (
  <>
    <h2 id="chapter-1">1 Erste Schritte</h2>
    <p>
      Herzlich willkommen im PERSEO Kundenbereich. Der Kundenbereich ist eine Web-Anwendung zur Verwaltung von Tests und
      Teilnehmern. Er bietet u.a. die folgenden Funktionen:
    </p>
    <ul>
      <li>Anlegen, bearbeiten und löschen von Verfahren, Tests und Teilnehmern</li>
      <li>Generieren von anonymen oder nicht-anonymen Login-Codes bzw. Testlinks</li>
      <li>Abrufen von Testergebnissen als PDF-Report oder CSV-Tabelle (z.B. für Excel)</li>
      <li>Aktuell laufende Tests live verfolgen</li>
      <li>Einstellungen wie z.B. Testzeiträume oder Nachteilsausgleiche</li>
      <li>Benachrichtigungen über neu abgeschlossene Tests per E-Mail</li>
      <li>Statistiken über Testteilnahme und Ergebnisse</li>
      <li>Sortier- und Filteroptionen, z.B. nach Testungsstatus und Ergebnissen</li>
      <li>Nutzer- und Rechteverwaltung</li>
      <li>Hilfe und Support</li>
    </ul>
    <p>
      Sie können den Kundenbereich mit jedem beliebigen Betriebssystem nutzen, z.B. Microsoft Windows, macOS oder Linux.
      Sie brauchen keine Software auf Ihrem Computer zu installieren. Sie benötigen lediglich einen aktuellen
      Webbrowser. Wir empfehlen einen der folgenden Browser:
    </p>
    <ul>
      <li>Google Chrome</li>
      <li>Mozilla Firefox</li>
      <li>Microsoft Edge</li>
    </ul>
    <div className="textbox important">
      <p>Bitte beachten Sie:</p>
      <ul>
        <li>Der Internet Explorer wird nicht unterstützt</li>
        <li> Browser-Erweiterungen, die JavaScript oder Cookies blockieren, müssen ausgeschaltet sein</li>
      </ul>
    </div>
    <p>
      Um den Kundenbereich zu öffnen, tippen Sie{' '}
      <a className="textlink" rel="noreferrer" target="_blank" href="https://perseo-assessment.de/">
        https://perseo-assessment.de/
      </a>{' '}
      in die Adresszeile Ihres Browsers und öffnen die Seite.
    </p>
    <h3 id="chapter-1-1">1.1 Login</h3>
    <p>
      Wenn Sie bereits Zugangsdaten besitzen, geben Sie diese ein und klicken auf <B>Einloggen</B>.
    </p>
    <p>
      Sollten Sie noch keine Zugangsdaten besitzen, klicken Sie auf <B>Zugang anfordern</B>, geben den Namen Ihres
      Unternehmens / Ihrer Behörde und Ihre E-Mail-Adresse ein und klicken anschließend auf <B>Zugang anfordern</B>.
      Nach eine kurzen Prüfung durch einen Mitarbeiter erhalten Sie Zugangs­daten per E-Mail.
    </p>
    <Image src="user_manual_images/customer-area-login.png" caption="Abb. 1: Login-Screen" width="300" />
    <p>
      Sollten Sie Ihr Passwort vergessen haben, klicken Sie auf <B>Passwort vergessen</B>, geben Ihre E-Mail-Adresse ein
      und klicken auf <B>Absenden</B>. Sie erhalten nun eine E-Mail mit weiteren Schritten, um ein neues Passwort zu
      vergeben.
    </p>
    <h3 id="chapter-1-2">1.2 Navigation</h3>
    <h4 id="chapter-1-2-1">1.2.1 Menüleiste</h4>
    <Image src="user_manual_images/menu-bar.png" caption="Abb. 2: Menüleiste" />
    <p>Am oberen Rand des Browserfensters befindet sich die Menüleiste (Abb. 2). Dort finden Sie:</p>
    <ol>
      <li>den aktuellen Pfad als Navigationshilfe</li>
      <li>die globale Suche</li>
      <li>das Menü</li>
    </ol>
    <p>
      Mit der globalen Suche können Sie nach Verfahren, Tests, Teilnehmernamen, Teilnehmernummern und TANs suchen. Wenn
      Sie auf ein Suchergebnis klicken, werden Sie zur jeweiligen Tabelle geleitet und das gesuchte Element ist als
      Filter ausgewählt.
    </p>
    <h4 id="chapter-1-2-2">1.2.2 Navigationsleiste</h4>
    <p>
      Über die Navigationsleiste auf der linken Seite gelangen Sie zu den Hauptseiten des Kundenbereichs. Die aktuell
      ausgewählte Seite ist dunkel hervorgehoben. Die folgenden Menüpunkte stehen zur Auswahl:
    </p>
    <NavbarDescriptionTable />
    <h4 id="chapter-1-2-3">1.2.3 Menü</h4>
    <p>
      Um das Menü zu öffnen, klicken Sie in der Menüleiste oben rechts auf <B>Menü</B>. Die folgenden Menüpunkte stehen
      zur Auswahl:
    </p>
    <MenuDescriptionTable />
    <h3 id="chapter-1-3">1.3 Aufbau des Testsystems</h3>
    <p>
      Die vier wichtigsten Elemente im PERSEO Testsystem sind <em>Verfahren</em>, <em>Tests</em>, <em>Teilnehmer</em>{' '}
      und <em>Testungen</em>. Das folgende Schaubild verdeutlicht, wie die vier Elemente zusammenhängen.
    </p>
    <Image src="user_manual_images/perseo-structure.svg" caption="Abb. 3: Aufbau des PERSEO Testsystems" />
    <p>
      Angenommen es sollen kaufmännische Azubis ausgewählt werden. Hierzu wird ein Verfahren{' '}
      <em>Kaufmännische Ausbildung (m/w/d)</em> angelegt.
    </p>
    <p>
      Die Bewerber sollen zwei Tests durchführen: Zum einen den <em>Leistungstest für kaufmännische Berufe</em> und zum
      anderen den <em>Persönlichkeitstest für Azubis</em>. Die beiden Tests sind dem Verfahren eindeutig zugeordnet.
    </p>
    <p>
      Es haben sich zwei Personen beworben: Max Mustermann und Martina Musterfrau. Dies sind die <em>Teilnehmer</em>.
      Jeder Teilnehmer ist nur einmal im System vorhanden und besitzt eine 10-stellige Teilnehmernummer, die mit P
      beginnt (z.B. P-12345-67890).
    </p>
    <p>
      Da beide Teilnehmer an beiden Tests teilnehmen sollen, ergeben sich vier <em>Testungen</em>. Jede Testung hat eine
      10-stellige Nummer, die mit PAS beginnt (z.B. PAS-ABCDE-12345), einen Status (z.B. Gestartet oder Abgeschlossen)
      und – nach Durchführung – ein Ergebnis.
    </p>
  </>
)

export default Chapter1

const NavbarDescriptionTable = () => {
  const header = ['Menüpunkt', 'Beschreibung', 'Beschränkungen']
  const data = [
    [
      'Dashboard',
      'Öffnet das Dashboard. Hier finden Sie die neuesten Testergebnisse, System-Statistiken und können aktuell laufende Tests live verfolgen.'
    ],
    [
      'Verfahren',
      <span key="NavbarDescriptionTable-processes">
        Öffnet die Tabelle <em>Verfahren</em>{' '}
        <span className="textlink internal-link" data-scroll-to="chapter-3-1">
          (siehe 3.1)
        </span>
      </span>
    ],
    [
      'Tests',
      <span key="NavbarDescriptionTable-tests">
        Öffnet die Tabelle <em>Tests</em>{' '}
        <span className="textlink internal-link" data-scroll-to="chapter-3-2">
          (siehe 3.2)
        </span>
      </span>
    ],
    [
      'Teilnehmer',
      <span key="NavbarDescriptionTable-participants">
        Öffnet die Tabelle <em>Teilnehmer</em>{' '}
        <span className="textlink internal-link" data-scroll-to="chapter-3-3">
          (siehe 3.3)
        </span>
      </span>
    ],
    [
      'Testungen',
      <span key="NavbarDescriptionTable-pts">
        Öffnet die Tabelle <em>Testungen</em>{' '}
        <span className="textlink internal-link" data-scroll-to="chapter-3-4">
          (siehe 3.4)
        </span>
      </span>
    ],
    [
      'Buchungen',
      <span key="NavbarDescriptionTable-bookings">
        Öffnet die Tabelle <em>Buchungen</em>
      </span>,
      'Administrator'
    ],
    [
      'Credit Shop',
      <span key="NavbarDescriptionTable-shop">
        Öffnet die Seite <em>Credits aufladen</em>{' '}
        <span className="textlink internal-link" data-scroll-to="chapter-4-2">
          (siehe 4.2)
        </span>
      </span>,
      'Administrator'
    ],
    [
      'E-Mails',
      <span key="NavbarDescriptionTable-emails">
        Öffnet die Tabelle <em>E-Mails</em>{' '}
      </span>
    ],
    [
      'Hilfe',
      <span key="NavbarDescriptionTable-help">
        Öffnet die Seite <em>Hilfe</em>
      </span>
    ],
    [
      'Support',
      <span key="NavbarDescriptionTable-support">
        Öffnet die Seite <em>Support</em>
      </span>
    ]
  ]
  return <Table name="navbar-description" {...{ header, data }} />
}

const MenuDescriptionTable = () => {
  const header = ['Menüpunkt', 'Beschreibung']
  const data = [
    [
      'Einstellungen',
      <span key="MenuDescriptionTable-settings">
        Blendet die Seitenleiste <em>Einstellungen</em> ein und öffnet die erste Unterseite
      </span>
    ],
    [
      'Hilfe',
      <span key="MenuDescriptionTable-help">
        Öffnet die Seite <em>Hilfe</em>
      </span>
    ],
    [
      'Support',
      <span key="MenuDescriptionTable-support">
        Öffnet die Seite <em>Support</em>
      </span>
    ],
    [
      'Rechnungen',
      <span key="MenuDescriptionTable-invoices">
        Öffnet die Seite <em>Rechnungen</em>. Dort können Sie Ihre Rechnungen einsehen und herunterladen.
      </span>
    ],
    [
      'Dokumente',
      <span key="MenuDescriptionTable-documents">
        Öffnet die Seite <em>Dokumente</em>. Dort können Sie verschiedene Dokumente herunterladen, wie z.B. die
        Preisliste oder die AGB.
      </span>
    ],
    [
      'Datenschutz',
      <span key="MenuDescriptionTable-dataSecurity">
        Öffnet die Seite <em>Datenschutz</em>
      </span>
    ],
    [
      'Impressum',
      <span key="MenuDescriptionTable-imprint">
        Öffnet die Seite <em>Impressum</em>
      </span>
    ],
    ['Logout', 'Beendet die aktuelle Session und leitet zur Login-Seite weiter']
  ]
  return <Table name="menu-description" {...{ header, data }} />
}
