import RowActions from '../components/table/RowActions'
import { ENTITIES, USER_CAPABILITIES } from '../utils/constants/constants'
import { createDate } from '../utils/helper/dateTimeHelper'
import { MainEntity } from './MainEntity'

export class Process extends MainEntity {
  constructor({ ...props }) {
    super()
    this.entity = 'Process'
    this.processUuid = props.processUuid
    this.assessmentUuids = props.assessmentUuids
    this.capabilities = props.capabilities
    this.created = createDate(props.created)
    this.createdBy = props.createdBy
    this.designOptions = props.designOptions
    this.elektryonTemplate = props.elektryonTemplate
    this.processDescription = props.processDescription || ''
    this.processName = props.processName || ''
    this.relatedAssessments = props.relatedAssessments
    this.useProcessResult = props.useProcessResult
    this.visibility = props.visibility
    this.relatedAssessments = []
    this.designOptions = []
    this.actions = <RowActions row={this} entity={ENTITIES.processes} />
  }
  addRelatedElements(completeDataSet) {
    const { assessments, designOptions } = completeDataSet
    const relatedAssessments = this.assessmentUuids.map((uuid) => {
      return assessments.find((assessment) => assessment.assessmentUuid === uuid)
    })
    this.relatedAssessments = relatedAssessments.filter((ass) => ass !== undefined)
    this.designOptions = designOptions
  }
  isEditor() {
    return this.capabilities === USER_CAPABILITIES.edit
  }
}
