import { useContext } from 'react'
import { ErrorMessage, GreyButton } from '../../../utils/elements/miscElements'
import { sendEmails } from '../sendEmailUtils'
import styled from 'styled-components'
import { AppContext } from '../../../utils/context/AppContext'
import useTranslate from '../../../utils/hooks/useTranslate'
import useEmailValidation from '../../../utils/hooks/useEmailValidation'

const PreviewMailSender = ({ data }) => {
  const t = useTranslate()
  const { email, showMailError, handleChange, mailError, handleBlur } = useEmailValidation(data.contactEmail)
  const context = useContext(AppContext)

  return (
    <div>
      <span>{t('sendSampleEmail')}</span>
      <InputContainer>
        <div>
          <input
            type="email"
            value={email}
            onChange={(e) => handleChange(e)}
            className={showMailError ? 'has-error' : ''}
            onBlur={handleBlur}
            style={{ flexGrow: 1, height: '36px' }}
          />
          <ErrorMessage visible={showMailError}>{t(mailError?.errorMsgShort)}</ErrorMessage>
        </div>
        <GreyButton
          disabled={mailError}
          style={{ height: '36px' }}
          onClick={(e) => sendPreviewMail(e, data, context, email, t)}>
          {t('sendNow')}
        </GreyButton>
      </InputContainer>
    </div>
  )
}
export default PreviewMailSender

const InputContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: var(--space-3);
  margin-top: var(--space-3);
`

const sendPreviewMail = (e, data, context, mail, t) => {
  e.preventDefault()
  sendEmails({ context: context, data: [data], dummyMailAddress: mail, t: t })
}
