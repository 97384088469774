import React, { useContext } from 'react'
import DataTable from '../../components/table/DataTable'
import { DashboardNotice, getParticipantNumberCol, getTestAndProcessCol, getResultDownloadCol } from './dashboardUtils'
import { TableContentContainer } from '../../components/table/tableElements'
import { DASHBOARD_SHOW_RESULTS_LIMIT } from '../../utils/constants/constants'
import { AppContext } from '../../utils/context/AppContext'
import { getPtResultCol } from '../pts/ptUtils'
import DateDisplay from '../../components/dateDisplay/DateDisplay'

const NewResultsTable = () => {
  const context = useContext(AppContext)
  const newPts = {
    tableData: getNewResultTableData(context.completeDataSet.pts),
    columns: React.useMemo(() => getNewResultCols(), [])
  }

  const showNewestResults = newPts.tableData.length > 0

  return (
    <>
      {showNewestResults ? (
        <DataTable
          data={newPts.tableData}
          columns={newPts.columns}
          tableName="currentResults"
          hideArchiveButton
          hideResultCount
        />
      ) : (
        <DashboardNotice offset={110} text="noTestResultsYet" />
      )}
    </>
  )
}

export default NewResultsTable

const getNewResultCols = () => [
  getParticipantNumberCol(),
  getTestAndProcessCol(),
  getPtFinishedDateCol(),
  getPtResultCol({ disableSortBy: true }),
  getResultDownloadCol()
]

const getNewResultTableData = (pts) => {
  return (
    pts
      .filter((pt) => pt.hasResult())
      .sort((a, b) => b.datePtFinished - a.datePtFinished)
      .slice(0, DASHBOARD_SHOW_RESULTS_LIMIT) || []
  )
}

const getPtFinishedDateCol = () => ({
  Header: 'finished',
  accessor: (row) => row.datePtFinished,
  disableSortBy: true,
  Cell: ({ value }) => (
    <TableContentContainer>
      <DateDisplay date={value} format={'dateAndTime'} />
    </TableContentContainer>
  )
})
