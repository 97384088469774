import { ENTITIES, PT_STATUS } from '../constants/constants'
import {
  allContactsAreAdmins,
  isAssessment,
  isParticipant,
  isProcess,
  isPt,
  isContact,
  isReminder,
  isParticipantMailLog
} from './Helper'

const CapabilitiesHelper = {
  canBulkStartTimer: (entity, selectedRowData) => {
    switch (entity) {
      case ENTITIES.participants:
        return selectedRowData.every((participant) =>
          participant.ptList.some((pt) => CapabilitiesHelper.canBulkStartTimerConditions(pt))
        )
      case ENTITIES.pts:
        return selectedRowData.every((pt) => CapabilitiesHelper.canBulkStartTimerConditions(pt))
      default:
        return false
    }
  },

  canBulkResetTimer: (entity, selectedRowData) => {
    switch (entity) {
      case ENTITIES.participants:
        return selectedRowData.every((participant) =>
          participant.ptList.some((pt) => CapabilitiesHelper.canBulkResetTimerConditions(pt))
        )
      case ENTITIES.pts:
        return selectedRowData.every((pt) => CapabilitiesHelper.canBulkResetTimerConditions(pt))
      default:
        return false
    }
  },

  canDeactivatePts: (entity, selectedRowData) => {
    if (!isPt(entity)) return false
    return selectedRowData.every((pt) => CapabilitiesHelper.canDeactivatePtConditions(pt))
  },

  canActivatePts: (entity, selectedRowData) => {
    if (!isPt(entity)) return false
    return selectedRowData.every((pt) => CapabilitiesHelper.canActivatePtConditions(pt))
  },

  canSetCapabilities: (entity, loggedInAsAdmin, contacts) => {
    if (entity !== ENTITIES.processes || !loggedInAsAdmin || allContactsAreAdmins(contacts)) return false
    return true
  },

  canAddParticipantsToOtherTest: (entity, selectedRowData) => {
    if (!isParticipant(entity)) return false
    return selectedRowData.every((row) => row.isVisible())
  },

  canAddParticipants: (entity, selectedRowData) => {
    if (!isParticipant(entity)) return false
    return selectedRowData.every((row) => row.isVisible())
  },

  canBulkStartTimerConditions: (pt) => {
    return (
      pt.ptInvitedDuration > 0 &&
      !pt.ptIsInvited &&
      pt.ptStatus !== PT_STATUS.finished &&
      pt.ptStatus !== PT_STATUS.deactivated &&
      pt.isVisible()
    )
  },

  canBulkResetTimerConditions: (pt) => {
    return (
      pt.ptInvitedDuration > 0 &&
      pt.ptIsInvited &&
      pt.ptStatus !== PT_STATUS.finished &&
      pt.ptStatus !== PT_STATUS.deactivated &&
      pt.isVisible()
    )
  },

  canDeactivatePtConditions: (pt) => {
    return (
      pt.ptStatus !== PT_STATUS.finished &&
      pt.ptStatus !== PT_STATUS.deactivated &&
      pt.isVisible() &&
      pt.relatedProcess.isEditor()
    )
  },

  canActivatePtConditions: (pt) => pt.isDeactivated() && pt.isVisible(),

  canDeleteEntities: (entity, loggedInAsAdmin) => {
    return !isContact(entity) && loggedInAsAdmin
  },

  canRestoreEntities: (selectedRowData, loggedInAsAdmin, entity) => {
    if (isReminder(entity)) return false
    return selectedRowData.every((row) => row.isArchived()) && loggedInAsAdmin
  },

  canArchiveEntities: (selectedRowData, loggedInAsAdmin, entity) => {
    if (isContact(entity) || isReminder(entity)) return false
    return selectedRowData.every((row) => row.isVisible()) && loggedInAsAdmin
  },

  canAddTestToProcess: (entity, process) => {
    if (entity !== ENTITIES.processes) return false
    return process.isEditor() && process.isVisible()
  },

  canAddParticipantsToProcess: (entity, process) => {
    if (entity !== ENTITIES.processes) return false
    return process.assessmentUuids.length > 0 && process.isEditor() && process.isVisible()
  },

  canDuplicateProcess: (entity, process, loggedInAsAdmin) => {
    if (entity !== ENTITIES.processes) return false
    return process.isEditor() && process.isVisible() && loggedInAsAdmin
  },

  canAddPtToTest: (entity, assessment) => {
    if (!isAssessment(entity)) return false
    return assessment.relatedProcess.isEditor() && assessment.isVisible()
  },

  canDownloadCsv: (entity) => {
    if (entity !== ENTITIES.processes && !isAssessment(entity)) return false
    return true
  },

  canDownloadPdfReportZip: (entity, row) => {
    if (!isAssessment(entity)) return false
    return row.relatedPts.filter((pt) => pt.isFinished()).length > 0
  },

  canDownloadReports: (entity, rowData) => {
    if (!isPt(entity) && !isParticipant(entity)) return false
    const canDownload = (pt) => pt.hasResult() && pt.relatedAssessment.reportAvailable

    if (isPt(entity)) {
      return rowData.every((pt) => canDownload(pt))
    }

    if (isParticipant(entity)) {
      const ptArrays = rowData.map((participant) => participant.ptList)
      return ptArrays.every((ptArray) => ptArray.some((pt) => canDownload(pt)))
    }
  },

  canDownloadFeedback: (entity, pt) => {
    if (!isPt(entity)) return false
    return pt.hasResult() && pt.relatedAssessment?.reportModules.feedbackAvailable
  },

  canBulkEditPtTimeSettings: (pts) => {
    return pts.every(
      (pt) =>
        pt.relatedProcess.isEditor() &&
        !pt.datePtFinished &&
        !pt.datePtStarted &&
        pt.ptStatus !== PT_STATUS.deactivated &&
        !pt.isArchived()
    )
  },

  canBulkEditPtInvitedStatus: (pts) => {
    return pts.every((pt) => pt.relatedProcess.isEditor() && !pt.isArchived())
  },

  canBulkEditPtValidFrom: (pts) => {
    return CapabilitiesHelper.canBulkEditPtTimeSettings(pts)
  },

  canBulkEditPtStartedDuration: (pts) => {
    return CapabilitiesHelper.canBulkEditPtTimeSettings(pts)
  },

  canBulkEditPtDurationMultiplier: (pts) => {
    return CapabilitiesHelper.canBulkEditPtTimeSettings(pts)
  },

  canBulkEditPtValidUntil: (pts) => {
    return pts.every(
      (pt) =>
        pt.relatedProcess.isEditor() && !pt.datePtFinished && pt.ptStatus !== PT_STATUS.deactivated && !pt.isArchived()
    )
  },

  canBulkEditPtTimer: (pts) => {
    return CapabilitiesHelper.canBulkEditPtValidUntil(pts) && pts.every((pt) => !pt.ptIsInvited)
  },

  canSendEmails: (entity, rows) => {
    if (!isPt(entity) && !isParticipant(entity)) return false
    if (isPt(entity)) {
      return rows.every((row) => row.relatedParticipant.pMail && row.relatedProcess.isEditor())
    }
    if (isParticipant(entity)) {
      return rows.every((row) => row.pMail && row.ptList.every((pt) => pt.relatedProcess.isEditor()))
    }
  },

  canEditParticipantInfos: (participant) => {
    if (participant.isArchived() || participant.pAnon) return false
    return true
  },

  canEditParticipant: (participant) => {
    if (participant.isArchived()) return false
    return true
  },

  canDeleteContacts: (entity, loggedInAsAdmin, numberOfContacts) => {
    return isContact(entity) && loggedInAsAdmin && numberOfContacts > 1
  },

  canEditProcess: (process) => {
    return process.isEditor() && !process.isArchived()
  },

  canEditAssessment: (assessment) => {
    return assessment.relatedProcess.isEditor() && !assessment.isArchived()
  },

  canEditElektryonTemplate: (context) => {
    return context.loggedInAsAdmin
  },

  canCopyLink: (entity, row) => {
    if (isPt(entity)) {
      const pt = row
      return pt.isReady() || pt.isWaiting() || pt.isStarted()
    }
    if (isParticipant(entity)) {
      return row.ptList
        .filter((pt) => pt.relatedAssessment.inHub)
        .some((pt) => pt.isReady() || pt.isWaiting() || pt.isStarted())
    }
    return false
  },

  canArchive: (entity) => {
    return isProcess(entity)
  },

  canEditNorms: (entity, rows) => {
    if (!isAssessment(entity)) return false
    return rows.every(
      (assessment) => assessment.producesNumericResult() && CapabilitiesHelper.canEditAssessment(assessment)
    )
  },

  canEditScreens: (entity, rows) => {
    if (!isAssessment(entity)) return false
    return rows.every((assessment) => CapabilitiesHelper.canEditAssessment(assessment))
  },

  canEditReminder: (entity) => isReminder(entity),
  canActivateReminder: (entity, row) => isReminder(entity) && !row.active,
  canDeactivateReminder: (entity, row) => isReminder(entity) && row.active,
  canResendMail: (entity) => isParticipantMailLog(entity),
  canShowMailPreview: (entity) => isParticipantMailLog(entity),
  canDuplicateReminder: (entity) => isReminder(entity)
}

export default CapabilitiesHelper
