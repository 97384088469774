import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'
import BulkEdit from '../bulkEdit/BulkEdit'
import Table from './Table'
import IndeterminateCheckbox from './IndeterminateCheckbox'
import { toggleExpanded, getTrClassName, getHiddenColumns, reorderColumns } from './tableUtils'
import {
  useTable,
  useSortBy,
  useRowSelect,
  useExpanded,
  useFilters,
  usePagination,
  useMountedLayoutEffect
} from 'react-table'
import './Table.scss'
import TableBar from './tableBar/TableBar'
import HeadlineTableActionsContainer from './HeadlineTableActionsContainer'
import { AppContext } from '../../utils/context/AppContext'
import FilterBar from './filterBar/FilterBar'
import { FilterContext } from '../../utils/context/FilterContext'
import filterTypes from '../filter/filtertypes'
import { CUSTOMER_VISIBILITY_STATUS } from '../../utils/constants/constants'
import { useSecondValue } from '../filter/filterUtils'
import { sortTypes } from './sortTypes'
import { toggleCol } from './tableCols/tableCols'
import TableActions, { FilterActionsContainer } from './tableBar/tableActions/TableActions'

const useInstance = (instance) => {
  if (instance && instance.getInstanceCallback) {
    instance.getInstanceCallback(instance)
  }
}

export const TableContext = createContext({})
export const PaginationContext = createContext({})

const DataTable = ({
  columns,
  data,
  RowSubComponent,
  showRowSelection,
  onSelectedRowsChange,
  hideIndeterminateCheckbox,
  selectSingleRow,
  getInstanceCallback,
  showFilters,
  tableName,
  hideResultCount,
  hideArchiveButton,
  showAddButton,
  hasSubrows,
  checkboxType,
  hideBulkMenu,
  hideHeader,
  showColumnController,
  showCustomConfigFilter,
  smallHeadline,
  selectedRows
}) => {
  const context = useContext(AppContext)
  const { activeFilters, deleteOtherTableFilters } = useContext(FilterContext)
  const [showCustomConfigs, setShowCustomConfigs] = useState(false)
  const [sorting, setSorting] = useState([])

  useEffect(() => {
    deleteOtherTableFilters(tableName)
  }, [tableName])

  const filterTypesMemo = React.useMemo(() => ({ ...filterTypes }), [])

  const stateFilters = activeFilters.filter((af) => af.tableName === tableName)
  const filterFields = stateFilters.map((af) => af.field.value).join()
  const filterValues = stateFilters
    .map((af) => {
      if (af.value.value?.from && af.value.value?.until) {
        return af.value.value.from + af.value.value.until
      }
      return af.value.value
    })
    .join()
  const filterRelations = stateFilters.map((af) => af.relation.value).join()

  const getFilters = () => {
    const filters = stateFilters.map((f) => {
      if (useSecondValue(f.relation)) {
        return { id: f.field.value, value: [f.value.value, f.value2.value] }
      }

      return { id: f.field.value, value: f.value.value }
    })

    const archiveFilter = context.showArchivedItems
      ? { id: 'visibility', value: [CUSTOMER_VISIBILITY_STATUS.visible, CUSTOMER_VISIBILITY_STATUS.archived] }
      : { id: 'visibility', value: [CUSTOMER_VISIBILITY_STATUS.visible] }

    const customConfigFilter = showCustomConfigs ? { id: 'status', value: 'custom' } : {}

    return [...filters, archiveFilter, customConfigFilter]
  }

  const initialState = {
    pageSize: 25,
    hiddenColumns: getHiddenColumns(columns, context.completeDataSet.customTableSettings, tableName),
    sortBy: sorting,
    selectedRowIds: selectedRows || {}
  }

  const modifiedColumns = useMemo(() => {
    const orderedCols = reorderColumns(columns, context.completeDataSet.customTableSettings, tableName)

    const updatedCols = orderedCols.map((col) => ({
      ...col,
      filter: stateFilters.find((f) => f.field.value === col.id)?.relation.value || 'exact'
    }))

    const visibilityFilter = updatedCols.find((col) => col.id === 'visibility')
    if (visibilityFilter) {
      visibilityFilter.filter = 'includes'
    }

    return updatedCols
  }, [columns, filterFields, filterValues, filterRelations])

  const checkboxColumn = {
    id: 'selection',
    Header: ({ getToggleAllRowsSelectedProps }) =>
      !hideIndeterminateCheckbox && (
        <IndeterminateCheckbox
          toggleAllRowsSelected={toggleAllRowsSelected}
          isToggleAllCheckbox
          {...getToggleAllRowsSelectedProps()}
        />
      ),
    Cell: ({ row }) => <IndeterminateCheckbox row={row} type={checkboxType} {...row.getToggleRowSelectedProps()} />,
    disableSortBy: true,
    className: 'checkbox-col',
    alwaysHidden: true
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    selectedFlatRows,
    toggleAllRowsSelected,
    page,
    rows,
    preFilteredRows,
    canPreviousPage,
    canNextPage,
    previousPage,
    nextPage,
    gotoPage,
    pageOptions,
    setPageSize,
    visibleColumns,
    allColumns,
    state: { pageIndex, pageSize, selectedRowIds, sortBy }
  } = useTable(
    {
      columns: modifiedColumns,
      data,
      getInstanceCallback,
      stateReducer: (newState, action) => {
        if (action.type === 'toggleRowSelected' && selectSingleRow) {
          newState.selectedRowIds = {
            [action.id]: true
          }
        }

        return newState
      },
      autoResetPage: false,
      disableSortRemove: true,
      filterTypes: filterTypesMemo,
      initialState: initialState,
      autoResetExpanded: false,
      autoResetSelectedRows: false,
      useControlledState: (state) => {
        return React.useMemo(
          () => ({
            ...state,
            filters: getFilters()
          }),
          [state, context.showArchivedItems, filterFields, filterValues, filterRelations, showCustomConfigs]
        )
      },
      sortTypes: sortTypes
    },
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    (hooks) => {
      showRowSelection &&
        hooks.visibleColumns.push((columns) => [
          checkboxColumn,
          ...(hasSubrows ? [toggleCol] : []), // Conditionally add toggleColumn
          ...columns
        ])
    },
    (hooks) => hooks.useInstance.push(useInstance)
  )

  useEffect(() => {
    setSorting(sortBy)
  }, [sortBy])

  useEffect(() => {
    if (activeFilters.find((f) => f.id === 'globalFilter')) {
      gotoPage(0)
    }
  }, [activeFilters.length])

  useMountedLayoutEffect(() => {
    onSelectedRowsChange && onSelectedRowsChange(selectedRowIds)
  }, [onSelectedRowsChange, selectedRowIds])

  const rowData = selectedFlatRows.map((flatRow) => flatRow.original)

  const showTableBar = canPreviousPage || canNextPage || pageSize !== 25
  const showTableActions = !hideArchiveButton || showColumnController
  const showHeadlineTableActions = !hideResultCount || showAddButton

  return (
    <TableContext.Provider
      value={{
        rows,
        preFilteredRows,
        tableName,
        gotoPage,
        toggleAllRowsSelected,
        smallHeadline
      }}>
      {showHeadlineTableActions && <HeadlineTableActionsContainer {...{ showAddButton, hideResultCount }} />}
      {(showFilters || showTableActions) && (
        <FilterActionsContainer id="filter-actions-container">
          {showFilters && (
            <FilterBar
              {...{
                columns,
                showCustomConfigFilter,
                showCustomConfigs,
                setShowCustomConfigs
              }}
            />
          )}
          {showTableActions && <TableActions {...{ hideArchiveButton, showColumnController, allColumns }} />}
        </FilterActionsContainer>
      )}
      <Table
        hasSubrows={hasSubrows}
        getTableProps={getTableProps}
        headerGroups={headerGroups}
        getTableBodyProps={getTableBodyProps}
        page={page}
        prepareRow={prepareRow}
        getTrClassName={getTrClassName}
        toggleExpanded={toggleExpanded}
        showRowSelection={showRowSelection}
        RowSubComponent={RowSubComponent}
        visibleColumns={visibleColumns}
        hideHeader={hideHeader}
      />
      {showTableBar && (
        <PaginationContext.Provider
          value={{
            canPreviousPage,
            canNextPage,
            pageIndex,
            pageSize,
            pageOptions,
            setPageSize,
            previousPage,
            nextPage,
            gotoPage
          }}>
          <TableBar />
        </PaginationContext.Provider>
      )}
      {showRowSelection && !hideBulkMenu && (
        <BulkEdit
          rowData={rowData}
          toggleAllRowsSelected={toggleAllRowsSelected}
          isVisible={rowData.length > 0}
          visibleColumns={visibleColumns}
        />
      )}
    </TableContext.Provider>
  )
}

export default DataTable
