import { SettingsContainer } from './sharedElements/sharedElements'
import ScrollContainer from '../scrollContainer/ScrollContainer'
import TabGroup from '../tabGroup/TabGroup'
import useTranslate from '../../utils/hooks/useTranslate'
import CustomFieldsComponent from './customFieldDefinitions/CustomFieldDefinitionsComponent'
import Editor from '../editor/Editor'

const CustomizationSettings = () => {
  const t = useTranslate()

  const content = (
    <SettingsContainer>
      <ScrollContainer type="settings">
        <h2>{t('customization')}</h2>
        <TabGroup
          position="settings-nav"
          tabs={[
            {
              label: 'customFields',
              id: 'customFields',
              content: <CustomFieldsComponent />
            },
            {
              label: 'gradingRules',
              id: 'gradingRules',
              content: <Editor key="normEditor" type="norms" />
            },
            {
              label: 'customScreens',
              id: 'customScreens',
              content: <Editor key="screenEditor" type="screens" />
            }
          ]}
        />
      </ScrollContainer>
    </SettingsContainer>
  )

  return content
}

export default CustomizationSettings
