import {
  getDurationString,
  getParticipantCount,
  getPtCount,
  getHubLink,
  getPtLink,
  getEntityLabel,
  showMissingReferenceNotice,
  getOptionByValue,
  arrayToCsvString,
  getGlobalStatusByKey,
  getFormattedNumber
} from '../../utils/helper/Helper'
import { CSVLink } from 'react-csv'
import React, { forwardRef, useContext, useImperativeHandle, useRef, useState } from 'react'
import {
  ENTITIES,
  FIELD_LABELS,
  GLOBAL_STATUS_KEYS,
  PT_STATUS_NAMES,
  SELECT_OPTIONS,
  ANONYM_MSG,
  DEFAULT_LANGUAGE,
  INVOICE_STATUS_NAMES,
  PAYMENT_OPTIONS
} from '../../utils/constants/constants'
import { formatDate, secondsToDHM } from '../../utils/helper/dateTimeHelper'
import { AppContext } from '../../utils/context/AppContext'
import { getTabTrackingLabelData } from '../tabTrackingLabel/TabTrackingLabel'
import { getUnpermittedAidsText } from '../unpermittedAidsLabel/UnpermittedAidsLabel'
import useTranslate from '../../utils/hooks/useTranslate'
import { getDesignOptions } from '../../utils/helper/designUtils'

const CsvDownload = forwardRef(({ rowData, entity, toggleAllRowsSelected, visibleColumns }, ref) => {
  const t = useTranslate()
  const context = useContext(AppContext)
  const customFieldDefinitions = context.completeDataSet.customFieldDefinitions
  const csvButtonRef = useRef(null)
  const [headerData, setHeaderData] = useState([])
  const [bodyData, setBodyData] = useState([])
  const handleClick = () => {
    prepareCsvData(rowData, entity, visibleColumns, customFieldDefinitions, t, context.language).then((csvData) => {
      setHeaderData(t(csvData.headers))
      setBodyData(csvData.body)
      csvButtonRef.current.link.click()
      if (toggleAllRowsSelected) toggleAllRowsSelected(false)
    })
  }

  const style = {
    textDecoration: 'none',
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0
  }

  const filename = getExportFilename(entity, t)

  useImperativeHandle(ref, () => ({
    handleClick
  }))

  return (
    <>
      <span style={style} onClick={handleClick} />
      <CSVLink
        id="csv-download"
        tabIndex="-1"
        separator=";"
        headers={headerData}
        data={bodyData}
        filename={filename}
        ref={csvButtonRef}
      />
    </>
  )
})

export default CsvDownload

const prepareCsvData = (rowData, entity, visibleColumns, customFieldDefinitions, t, locale) => {
  const csvData = {}
  const dimensions = rowData[0]?.relatedResult?.dimensionScores
  const pt = entity === ENTITIES.ptResults ? rowData[0] : null
  const showResults = pt && pt.resultIsVisible()

  return new Promise((resolve) => {
    csvData.headers = getHeaders(entity, dimensions, showResults, visibleColumns, locale)
    csvData.body = getBody(entity, rowData, customFieldDefinitions, t, locale)
    resolve(csvData)
  })
}

const getHeaders = (entity, dimensions, showResults, visibleColumns, locale) => {
  const exportableColumns = visibleColumns?.filter((col) => col.canExport)
  switch (entity) {
    case ENTITIES.processes:
    case ENTITIES.assessments:
    case ENTITIES.bookings:
    case ENTITIES.invoices:
    case ENTITIES.participantMailLogs:
      return exportableColumns.map((col) => ({ label: col.Header, key: col.id }))
    case ENTITIES.participants:
      addFirstAndLastNameHeaders(exportableColumns)
      return exportableColumns.map((col) => ({ label: col.Header, key: col.id }))
    case ENTITIES.pts:
      addNormResultAndGradeHeaders(exportableColumns)
      addFirstAndLastNameHeaders(exportableColumns)
      return exportableColumns.map((col) => ({ label: col.Header, key: col.id }))

    case ENTITIES.ptResults: {
      const labels_1 = [
        { label: 'pNumber', key: 'pNr' },
        { label: FIELD_LABELS.firstName, key: 'pFirstName' },
        { label: FIELD_LABELS.lastName, key: 'pLastName' },
        { label: 'TAN', key: 'ptNumber' },
        { label: 'process', key: 'processName' },
        { label: 'Test', key: 'assessmentName' },
        { label: 'startedAt', key: 'datePtStarted' },
        { label: 'dateCompleted', key: 'datePtFinished' }
      ]
      const dimLabels = dimensions.map((dim, i) => ({
        label: dim.alias[locale] || dim.alias[DEFAULT_LANGUAGE] || dim.alias,
        key: 'dim_' + i
      }))
      const labels_2 = showResults
        ? [
            { label: 'result', key: 'normResult' },
            { label: 'grade', key: 'grade' },
            { label: 'windowChanges', key: 'tabTracking' },
            { label: 'unpermittedAids', key: 'unpermittedAidsIndex' },
            { label: 'cameraAnomalies', key: 'cameraAnomalies' }
          ]
        : []
      return labels_1.concat(dimLabels, labels_2)
    }
    case ENTITIES.accessData:
      return [
        { label: 'pNumber', key: 'pNr' },
        { label: 'TAN', key: 'ptNumber' },
        { label: 'testLink', key: 'link' },
        { label: 'process', key: 'processName' },
        { label: 'Test', key: 'assessmentName' },
        { label: 'Status', key: 'ptStatus' }
      ]
    case ENTITIES.accessDataHub:
      return [
        { label: 'pNumber', key: 'pNr' },
        { label: FIELD_LABELS.firstName, key: 'pFirstName' },
        { label: FIELD_LABELS.lastName, key: 'pLastName' },
        { label: 'Tests', key: 'assessmentNames' },
        { label: 'linkToTestOverview', key: 'link' }
      ]
    default:
      return null
  }
}

const getBody = (entity, rowData, customFieldDefinitions, t, locale) => {
  switch (entity) {
    case ENTITIES.processes:
      return rowData.map((row) => {
        const assessmentNames = row.relatedAssessments.map((row) => row.assessmentName)
        const assessmentNameString = arrayToCsvString(assessmentNames)
        const participantCount = getParticipantCount(row.relatedAssessments)
        const ptCount = getPtCount(row.relatedAssessments)
        const capabilities = getOptionByValue(t(SELECT_OPTIONS.userCapabilities), row.capabilities).label || ''
        const designOptions = getDesignOptions(row.designOptions)
        const template = getOptionByValue(designOptions, row.elektryonTemplate).label || ''

        return {
          processName: row.processName,
          assessmentName: assessmentNameString,
          participantCount: row.isVisible() ? participantCount.visible : participantCount.archived,
          ptCount: row.isVisible() ? ptCount.visible : ptCount.archived,
          capabilities: capabilities,
          elektryonTemplate: template,
          processCreated: formatDate(row.created).androFormat,
          processCreatedBy: row.createdBy
        }
      })
    case ENTITIES.assessments:
      return rowData.map((row) => ({
        assessmentName: row.assessmentName,
        processName: row.relatedProcess.processName,
        ptCount: row.relatedPts.length,
        testDuration: getDurationString(row.relatedConfig.configDuration, t).long,
        validFrom: row.validFrom || '',
        validUntil: row.validUntil || '',
        invitedDuration: row.invitedDuration ? t('dhmString', ...secondsToDHM(row.invitedDuration)) : '',
        startedDuration: row.startedDuration ? t('dhmString', ...secondsToDHM(row.startedDuration)) : '',
        assessmentCreated: formatDate(row.created).androFormat,
        assessmentCreatedBy: row.createdBy
      }))
    case ENTITIES.participants:
      return rowData.map((row) => {
        const assessmentNames = row.ptList.map((pt) => pt.relatedAssessment.assessmentName + addResult(pt, locale))
        const assessmentNameString = arrayToCsvString(assessmentNames)
        const hasTestsInHub = row.ptList.filter((pt) => pt.relatedAssessment.inHub === true)?.length > 0
        const processNames = row.ptList.map((pt) => pt.relatedProcess.processName)
        const uniqueProcessNames = [...new Set(processNames)]
        const processNamesString = arrayToCsvString(uniqueProcessNames)
        const processResult = arrayToCsvString(
          row.relatedProcessResults.map((pr) => getFormattedNumber(pr.processResult, locale))
        )

        const data = {
          pNr: row.pNr,
          pFirstName: row.pAnon ? '' : row.pFirstName,
          pLastName: row.pAnon ? t(ANONYM_MSG) : row.pLastName,
          pFullName: t(row.nameLabel),
          pGender: row.pGender,
          pMail: row.pMail,
          processName: processNamesString,
          hubLink: hasTestsInHub ? getHubLink(row.pnrHash) : t('notActivated'),
          assessmentName: assessmentNameString,
          participantCreated: formatDate(row.created).androFormat,
          participantCreatedBy: row.createdBy,
          processResult: processResult
        }

        addCustomFields(row, data)
        return data
      })
    case ENTITIES.pts:
      return rowData.map((pt) => {
        const tabTrackingIndex = pt?.relatedResult?.tabTrackingIndex
        const tabTrackingText = getTabTrackingLabelData(tabTrackingIndex).text
        const unpermittedAidsText = getUnpermittedAidsText(pt?.relatedResult?.unpermittedAidsIndex)
        const normResult = getNormResult(pt, locale)
        const grade = getGrade(pt, locale)
        const cameraAnomalies = getCameraAnomalies(pt)

        const invitationStatus = getGlobalStatusByKey(pt.globalStatus, GLOBAL_STATUS_KEYS.invitationStatus)
        const invitationStatusLabel = getOptionByValue(
          t(SELECT_OPTIONS.invitationStatus),
          invitationStatus?.statusValue
        ).label

        const data = {
          ptNumber: pt.ptNumber,
          ptLink: getPtLink(pt.referenceToken),
          processName: pt.relatedProcess.processName,
          assessmentName: pt.relatedAssessment.assessmentName,
          ptStatus: t(PT_STATUS_NAMES[pt.ptStatus]),
          datePtStarted: pt.datePtStarted,
          datePtFinished: pt.datePtFinished,
          normResult: normResult,
          grade: grade,
          tabTrackingIndex: pt.isFinished() ? t(tabTrackingText) : '',
          unpermittedAidsIndex: pt.isFinished() ? t(unpermittedAidsText) : '',
          ptValidFrom: pt.ptValidFrom,
          ptValidUntil: pt.ptValidUntil,
          ptCreated: formatDate(pt.created).androFormat,
          ptCreatedBy: pt.createdBy,
          pNr: pt.relatedParticipant.pNr,
          pFirstName: pt.relatedParticipant.pFirstName,
          pLastName: pt.relatedParticipant.pAnon ? t(ANONYM_MSG) : pt.relatedParticipant.pLastName,
          pGender: pt.relatedParticipant.pGender,
          timer: pt.ptInvitedDuration ? t('dhmString', ...secondsToDHM(pt.ptInvitedDuration)) : '',
          ptStartedDuration: pt.ptStartedDuration ? t('dhmString', ...secondsToDHM(pt.ptStartedDuration)) : '',
          invitation: invitationStatusLabel,
          pFullName: t(pt.relatedParticipant.nameLabel),
          pMail: pt.relatedParticipant.pMail,
          ptCredits: pt.ptCredits,
          cameraAnomalies: pt.isFinished() ? t(cameraAnomalies) : ''
        }

        addCustomFields(pt.relatedParticipant, data)
        return data
      })
    case ENTITIES.ptResults:
      return rowData.map((row) => {
        const dimensions = row.relatedResult.dimensionScores
        const tabTrackingIndex = row.relatedResult.tabTrackingIndex
        const tabTrackingText = getTabTrackingLabelData(tabTrackingIndex).text
        const unpermittedAidsText = getUnpermittedAidsText(row?.relatedResult?.unpermittedAidsIndex)
        const cameraAnomalies = getCameraAnomalies(row)

        const data_1 = {
          pNr: row.relatedParticipant.pNr,
          pFirstName: row.relatedParticipant.pFirstName,
          pLastName: row.relatedParticipant.pAnon ? t(ANONYM_MSG) : row.relatedParticipant.pLastName,
          ptNumber: row.ptNumber,
          processName: row.relatedProcess.processName,
          assessmentName: row.relatedAssessment.assessmentName,
          datePtStarted: row.datePtStarted,
          datePtFinished: row.datePtFinished
        }

        const data_2 = {
          normResult: getNormResult(row, locale) || '',
          grade: getGrade(row, locale) || '',
          tabTracking: row.isFinished() ? t(tabTrackingText) : '',
          unpermittedAidsIndex: row.isFinished() ? t(unpermittedAidsText) : '',
          cameraAnomalies: row.isFinished() ? t(cameraAnomalies) : ''
        }

        const allData = Object.assign(data_1, data_2)

        dimensions.map((dim, i) => {
          const obj = { ['dim_' + i]: dim.score }
          return Object.assign(allData, obj)
        })

        return allData
      })
    case ENTITIES.accessData:
      return rowData.map((row) => ({
        pNr: row.pNr,
        ptNumber: row.ptNumber,
        link: getPtLink(row.referenceToken),
        processName: row.relatedProcess.processName,
        assessmentName: row.relatedAssessment.assessmentName,
        ptStatus: PT_STATUS_NAMES[row.ptStatus]
      }))
    case ENTITIES.accessDataHub: {
      const allParticipantNrs = rowData.map((pt) => pt.relatedParticipant.pNr)
      const uniqueParticipantNrs = [...new Set(allParticipantNrs)]

      return uniqueParticipantNrs.map((pNr) => {
        const relatedParticipant = rowData.find((pt) => pt.relatedParticipant.pNr === pNr).relatedParticipant
        const assessmentNames = relatedParticipant.ptList.map((pt) => pt.relatedAssessment.assessmentName)
        assessmentNames.sort((a, b) => a.localeCompare(b))
        return {
          pNr: pNr,
          pFirstName: relatedParticipant.pAnon ? '' : relatedParticipant.pFirstName,
          pLastName: relatedParticipant.pAnon ? t(ANONYM_MSG) : relatedParticipant.pLastName,
          assessmentNames: arrayToCsvString(assessmentNames),
          link: getHubLink(relatedParticipant.pnrHash)
        }
      })
    }
    case ENTITIES.bookings:
      return rowData.map((row) => {
        const data = {
          bookingKeyDescription: t(row.bookingKeyDescription),
          bookingNr: row.bookingNr,
          creditsValue: row.creditsValue,
          assessmentName: showMissingReferenceNotice(row.relatedAssessment, row.bookingKey)
            ? t('noReference')
            : row.relatedAssessment.assessmentName,
          processName: showMissingReferenceNotice(row.relatedProcess, row.bookingKey)
            ? t('noReference')
            : row.relatedProcess.processName,
          ptNumber: showMissingReferenceNotice(row.relatedPt, row.bookingKey) ? t('noReference') : row.ptNumber,
          pNr: showMissingReferenceNotice(row.relatedParticipant, row.bookingKey) ? t('noReference') : row.pNr,
          bookingCreated: formatDate(row.created).androFormat,
          comment: row.comment
        }

        customFieldDefinitions.forEach((cfd) => {
          Object.assign(data, {
            [cfd.slug]: showMissingReferenceNotice(row.relatedParticipant, row.bookingKey)
              ? t('noReference')
              : row.relatedParticipant?.customFields?.find((customField) => customField.slug === cfd.slug)?.content
          })
        })

        return data
      })
    case ENTITIES.invoices:
      return rowData.map((row) => ({
        invoiceNo: row.invoiceNo,
        dateBilled: row.dateBilled ? formatDate(row.dateBilled).dateOnly : '',
        status: t(INVOICE_STATUS_NAMES[row.status]),
        net: getFormattedNumber(row.net, locale),
        gross: getFormattedNumber(row.gross, locale),
        paymentMethod: t(PAYMENT_OPTIONS.find((opt) => opt.title === row.paymentMethod)?.content || t('invoice')),
        recipient: row.billingMail,
        recipientCc: row.billingMailCc,
        vatSum: getFormattedNumber(row.vatSum, locale)
      }))
    case ENTITIES.participantMailLogs:
      return rowData.map((row) => ({
        pNr: row.pNr,
        email: row.mailAddress,
        subject: row.subject,
        emailType: t(row.emailType),
        assessmentNames: row.assessmentNames.join(', '),
        mailCreatedBy: row.createdBy,
        mailCreated: formatDate(row.created).androFormat
      }))
    default:
      return []
  }
}

const getNormResult = (pt, locale) => {
  if (pt.hasCrosstestResult()) {
    const crosstestResult =
      pt.relatedResult?.crosstestResult[locale] || pt.relatedResult?.crosstestResult[DEFAULT_LANGUAGE]
    return crosstestResult?.peculiarityText
  }
  if (pt.hasResult()) {
    return pt.relatedResult.normResult
  }
  return undefined
}

const addResult = (pt, locale) => {
  if (pt.hasResult()) {
    return ' (' + getNormResult(pt, locale) + ')'
  }
  return ''
}

const getGrade = (pt, locale) => {
  if (!pt.hasResult()) return ''
  return pt.hasCrosstestResult() ? '' : getFormattedNumber(pt?.relatedResult.grade, locale)
}

const getCameraAnomalies = (pt) => {
  if (!pt.hasResult()) return ''
  return pt.relatedResult.cameraAnomalies ? 'yes' : ''
}

const getExportFilename = (entity, t) => {
  const today = new Date()
  const date = formatDate(today).urlParamFormat
  const tableName = t(getEntityLabel(entity, 2))
  const exportFilename = ['perseo', tableName.toLowerCase(), date].join('_') + '.csv'
  return exportFilename
}

const addFirstAndLastNameHeaders = (exportableColumns) => {
  const pNrColumnIndex = exportableColumns.findIndex((vc) => vc.id === 'pNr')
  if (pNrColumnIndex !== -1) {
    exportableColumns.splice(
      pNrColumnIndex + 1,
      0,
      { Header: FIELD_LABELS.firstName, id: 'pFirstName' },
      { Header: FIELD_LABELS.lastName, id: 'pLastName' }
    )
  }
}

const addNormResultAndGradeHeaders = (exportableColumns) => {
  const resultColumnIndex = exportableColumns.findIndex((vc) => vc.id === 'result')
  if (resultColumnIndex !== -1) {
    exportableColumns.splice(
      resultColumnIndex,
      0,
      { Header: 'result', id: 'normResult' },
      { Header: 'grade', id: 'grade' }
    )
    exportableColumns.splice(resultColumnIndex + 2, 1)
  }
}

const addCustomFields = (participant, data) => {
  participant.customFields.forEach((cf) => {
    Object.assign(data, {
      [cf.slug]: participant.customFields.find((customField) => customField.slug === cf.slug)?.content
    })
  })
}
