import Select from 'react-select'
import ScrollContainer from '../../../components/scrollContainer/ScrollContainer'
import ToggleButton from '../../../components/toggleButton/ToggleButton'
import { FORM_ERRORS, SELECT_OPTIONS } from '../../../utils/constants/constants'
import {
  ErrorMessage,
  Label,
  OptionDescription,
  OptionEntry,
  PageHeadline,
  WizardForm,
  selectStyles
} from '../../../utils/elements/miscElements'
import { getOptionByValue, setState, someParticipantsUsingTimer } from '../../../utils/helper/Helper'
import useTranslate from '../../../utils/hooks/useTranslate'
import { getDayOptions } from '../../../components/timePicker/TimePicker'

const MailSettings = ({ data, setData, emailIsValid }) => {
  const t = useTranslate()
  const usingTimer = someParticipantsUsingTimer(data.participants)
  const daysOptions = getDayOptions(t, 15).filter((option) => option.value > 0)

  return (
    <>
      <PageHeadline>{t('settings')}</PageHeadline>
      <ScrollContainer>
        <WizardForm style={{ gap: 'var(--space-7)' }}>
          {data.isReminder && (
            <OptionEntry hasHint>
              <Label fullWidth>{t('reminderTitle')}</Label>
              <input type="text" value={data.title} onChange={(e) => setState(setData, 'title', e.target.value)} />
              <OptionDescription description="onlyVisibleForYou" />
            </OptionEntry>
          )}

          {!data.isReminder && (
            <OptionEntry hasHint>
              <Label fullWidth>{t('emailType')}</Label>
              <ToggleButton options={SELECT_OPTIONS.emailTypes} data={data} setData={setData} dataKey="emailType" />
              <OptionDescription description="emailTypeDescription" />
            </OptionEntry>
          )}
          {usingTimer && !data.isReminder && (
            <OptionEntry hasHint>
              <Label fullWidth>{t('startTimerAutomaticallyAfterEmail')}</Label>
              <ToggleButton options={SELECT_OPTIONS.yesNo} data={data} setData={setData} dataKey="startTimerOnSend" />
              <OptionDescription description="activateOptionTimeStarts" />
            </OptionEntry>
          )}
          <OptionEntry hasHint>
            <div style={{ display: 'flex', gap: 'var(--space-3)' }}>
              <div>
                <Label fullWidth>{t('replyToName')}</Label>
                <input
                  type="text"
                  style={{ width: '100%' }}
                  value={data.replyToName}
                  onChange={(e) => setState(setData, 'replyToName', e.target.value)}
                />
              </div>
              <div>
                <Label fullWidth>{t('replyToEmail')}</Label>
                <input
                  type="text"
                  style={{ width: '100%' }}
                  value={data.replyToEmail}
                  onChange={(e) => setState(setData, 'replyToEmail', e.target.value)}
                  onBlur={() => setState(setData, 'formErrorsVisible', !emailIsValid)}
                  className={data.formErrorsVisible && !emailIsValid ? 'has-error' : ''}
                />
                <ErrorMessage visible={data.formErrorsVisible && !emailIsValid}>
                  {t(FORM_ERRORS.emailInvalid)}
                </ErrorMessage>
              </div>
            </div>
            <OptionDescription style={{ marginTop: '20px' }} description="replyToDescription" />
          </OptionEntry>
          {data.isReminder && (
            <>
              <div>
                <Label fullWidth>{t('sendTime')}</Label>
                <OptionEntry hasHint>
                  <div style={{ display: 'flex', gap: 'var(--space-3)' }}>
                    <Select
                      options={daysOptions}
                      onChange={(selectedOption) => setState(setData, 'days', selectedOption.value)}
                      value={getOptionByValue(daysOptions, data.days)}
                      styles={selectStyles}
                      maxMenuHeight={120}
                    />
                    <Select
                      options={t(SELECT_OPTIONS.reminderTriggerType)}
                      onChange={(selectedOption) => setState(setData, 'triggerType', selectedOption.value)}
                      value={getOptionByValue(t(SELECT_OPTIONS.reminderTriggerType), data.triggerType)}
                      styles={selectStyles}
                      isSearchable={false}
                    />
                  </div>
                  <OptionDescription description="sendTimeDescription" />
                </OptionEntry>
              </div>

              <OptionEntry hasHint>
                <Label fullWidth>{t('activateReminder')}</Label>
                <ToggleButton options={t(SELECT_OPTIONS.activate)} data={data} setData={setData} dataKey="active" />
                <OptionDescription description="activateLaterInfo" />
              </OptionEntry>
            </>
          )}
        </WizardForm>
      </ScrollContainer>
    </>
  )
}

export default MailSettings
