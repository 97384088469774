import { ENTITIES } from '../../../../utils/constants/constants'
import { PAGES } from '../../../../utils/constants/pages'
import useTranslate from '../../../../utils/hooks/useTranslate'
import { AddButton } from '../../tableElements'
import { TableContext } from '../../DataTable'
import { useContext } from 'react'
import useRedirect from '../../../../utils/hooks/useRedirect'

const TableAddButton = () => {
  const t = useTranslate()
  const { tableName } = useContext(TableContext)
  const wizardPage = getWizardPage(tableName)
  const redirect = useRedirect()
  const buttonText = getButtonText(tableName, t)
  return (
    <AddButton style={{ height: 'var(--space-7)' }} onClick={() => redirect(wizardPage)}>
      {buttonText}
    </AddButton>
  )
}

export default TableAddButton

const getButtonText = (tableName, t) => {
  switch (tableName) {
    case ENTITIES.processes:
      return `+ ${t('process')}`
    case ENTITIES.assessments:
      return '+ Test'
    case ENTITIES.participants:
      return `+ ${t('participant')}`
    case ENTITIES.users:
      return `+ ${t('user')}`
    case ENTITIES.bookings:
      return t('chargeCredits')
    case ENTITIES.reminders:
      return `+ ${t('reminder')}`
    case ENTITIES.participantMailLogs:
      return `+ ${t('email')}`
    default:
      return 'Neu anlegen'
  }
}

const getWizardPage = (tableName) => {
  switch (tableName) {
    case ENTITIES.processes:
      return PAGES.newProcess
    case ENTITIES.assessments:
      return PAGES.newTest
    case ENTITIES.participants:
      return PAGES.newParticipant
    case ENTITIES.users:
      return PAGES.newUser
    case ENTITIES.bookings:
      return PAGES.shop
    case ENTITIES.reminders:
      return PAGES.editReminder
    case ENTITIES.participantMailLogs:
      return PAGES.sendEmails
    default:
      return PAGES.login
  }
}
