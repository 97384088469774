import { Screen } from '../../../entities/Screen'
import {
  DEFAULT_SCREEN_NR_END,
  DEFAULT_SCREEN_NR_START,
  SCREEN_TYPE_END,
  SCREEN_TYPE_START
} from '../../../utils/constants/constants'

export const getScreenTemplates = (customScreens, t) => {
  const templates = [
    new Screen({
      name: t('startScreenDefault'),
      screenNr: DEFAULT_SCREEN_NR_START,
      disabled: true,
      id: DEFAULT_SCREEN_NR_START,
      createdBy: 'PERSEO',
      type: SCREEN_TYPE_START,
      html: {
        de: `<h1>Herzlich willkommen</h1>
            <p>Sehr geehrte Teilnehmerin, sehr geehrter Teilnehmer,<br /><br />herzlich willkommen zum Eignungstest! Bevor Sie den Test starten, sollten Sie die Instruktionen sorgfältig durchlesen.</p>
            <p>Bitte blättern Sie nun zur nächsten Seite.</p>`,
        en: `<h1>Welcome</h1>
            <p>Dear participant,<br /><br />welcome to the aptitude test! Before you start, please carefully read the instructions.</p>
            <p>Please proceed to the next page.</p>`,
        ded: `<h1>Herzlich willkommen</h1>
            <p>Liebe Teilnehmerin, lieber Teilnehmer,<br /><br />herzlich willkommen zum Eignungstest! Bevor du den Test startest, solltest du die Instruktionen sorgfältig durchlesen.</p>
            <p>Bitte blättere nun zur nächsten Seite.</p>`
      }
    }),
    new Screen({
      name: t('endScreenDefault'),
      screenNr: DEFAULT_SCREEN_NR_END,
      disabled: true,
      id: DEFAULT_SCREEN_NR_END,
      createdBy: 'PERSEO',
      type: SCREEN_TYPE_END,
      html: {
        de: `<h1>Test abgeschlossen</h1>
            <p><span class="notice online-notice">Sie können das Browserfenster jetzt schließen.</span></p>
            <p>Herzlichen Dank für Ihre Teilnahme!</p>`,
        en: `<h1>Test completed</h1>
            <p><span class="notice online-notice">You can now close the browser window.</span></p>
            <p>Thank you very much for your participation!</p>`,
        ded: `<h1>Test abgeschlossen</h1>
            <p><span class="notice online-notice">Du kannst das Browserfenster jetzt schließen.</span></p>
            <p>Herzlichen Dank für deine Teilnahme!</p>`
      }
    })
  ]

  return [...templates, ...customScreens]
}
