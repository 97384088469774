import { API_DATA, CUSTOMER_VISIBILITY_STATUS, STATUS_BAR_TYPES } from '../constants/constants'
import { fetchData, setStatusBar } from './Helper'
import sessionHandler from './sessionHandler'

export const getDeleteContactModalText = (contact, t) => {
  if (contact.loggedInContact) {
    return t('confirmDeleteOwnAccount')
  } else {
    return t('confirmDeleteAccount', contact.getContactLabel())
  }
}

export const deleteContacts = (contact, context) => {
  const SUCCESS_STATUS = 1
  const payload = { contactUuids: [contact.contactUuid] }
  const endPoint = API_DATA.contacts.endPointDelete

  fetchData(payload, endPoint, context).then((responseData) => {
    const success = responseData.response.status === SUCCESS_STATUS
    if (success) {
      handleLogoutOnSelfDelete(contact, context)
      handleBillingMailUpdate(responseData, context)
      setContactAndRelatedDataDeleted(context, contact.contactUuid)
      setStatusBar({
        controller: context.statusBarController,
        type: STATUS_BAR_TYPES.success,
        text: ['userSuccessfullyDeleted', contact.getContactLabel()]
      })
    } else {
      setStatusBar({
        controller: context.statusBarController,
        type: STATUS_BAR_TYPES.error,
        text: ['userDeletionFailed', contact.getContactLabel()]
      })
    }
  })
}

const setContactAndRelatedDataDeleted = (context, contactUuid) => {
  const i = context.completeDataSet.contacts.findIndex((c) => c.contactUuid === contactUuid)
  context.completeDataSet.contacts[i].visibility = CUSTOMER_VISIBILITY_STATUS.deleted

  const j = context.completeDataSet.contactProcesses.findIndex((c) => c.contactUuid === contactUuid)
  context.completeDataSet.contactProcesses[j].visibility = CUSTOMER_VISIBILITY_STATUS.deleted

  context.setCompleteDataSet({ ...context.completeDataSet })
}

const handleBillingMailUpdate = (responseData, context) => {
  if (responseData.response.deleteBillingMail) {
    deleteBillingMail(context)
  }
}

const deleteBillingMail = (context) => {
  context.completeDataSet.billing.billingEmail = ''
  context.setCompleteDataSet({ ...context.completeDataSet })
}

const handleLogoutOnSelfDelete = (contact, context) => {
  if (contact.loggedInContact) {
    sessionHandler.logout(context)
  }
}
