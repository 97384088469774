import { useContext } from 'react'
import {
  END_SCREEN_KEY,
  GLOBAL_STATUS_KEYS,
  NORM_KEY,
  NOT_SET_TEXT,
  PERSEO_DEFAULT,
  SELECT_OPTIONS,
  START_SCREEN_KEY
} from '../../utils/constants/constants'
import { secondsToDHM } from '../../utils/helper/dateTimeHelper'
import { getOptionByValue } from '../../utils/helper/Helper'
import useTranslate from '../../utils/hooks/useTranslate'
import DateDisplay from '../dateDisplay/DateDisplay'
import { AppContext } from '../../utils/context/AppContext'

const BulkEditPreviewTable = ({ visibleFields, designOptions }) => {
  const context = useContext(AppContext)
  const { customNorms, customScreens } = context.completeDataSet

  const t = useTranslate()
  return (
    <div className="table-outer-container">
      <div className="table-inner-container">
        <table style={{ textAlign: 'left', width: '100%' }} className="bulk-edit-preview-table">
          <thead>
            <tr>
              <th>{t('field')}</th>
              <th>{t('newValue')}</th>
            </tr>
          </thead>
          <tbody>
            {visibleFields.map((field) => (
              <tr key={field.label}>
                <td className="closed">{field.label}</td>
                <td className="closed">{getFieldValue(field, designOptions, t, customNorms, customScreens)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default BulkEditPreviewTable

const getFieldValue = (field, designOptions, t, customNorms, customScreens) => {
  switch (field.name) {
    case 'invitedDuration':
    case 'startedDuration':
      return field.value === 0 ? t(NOT_SET_TEXT) : t('dhmString', ...secondsToDHM(field.value))
    case 'validFrom':
    case 'validUntil':
      return !field.value ? t(NOT_SET_TEXT) : <DateDisplay date={field.value} format={'dateAndTimeString'} />
    case 'elektryonTemplate':
      return designOptions.find((option) => option.id === field.value).title
    case GLOBAL_STATUS_KEYS.invitationStatus:
      return getOptionByValue(t(SELECT_OPTIONS.invitationStatus), field.value).label
    case NORM_KEY:
      return customNorms.find((cn) => cn.normNr === field.value)?.name || PERSEO_DEFAULT
    case START_SCREEN_KEY:
    case END_SCREEN_KEY:
      return customScreens.find((cs) => cs.screenNr === field.value)?.name || PERSEO_DEFAULT
    default:
      return field.value
  }
}
