import { useState } from 'react'
import { TableButton, TableButtonContainer } from '../../../pages/shop/subcomponents/CreditCalculator'
import styled from 'styled-components'
import { EditDiscardButton, EditSaveButton, Icon } from '../../customInput/customInputElements'
import useTranslate from '../../../utils/hooks/useTranslate'

const NormTableButtons = ({ i, dispatch, showDelete, showAdd }) => {
  const [confirmationVisible, setConfirmationVisisble] = useState(false)
  return (
    <TableButtonContainer>
      <TableButton warning isVisible={showDelete} onClick={() => setConfirmationVisisble(true)}>
        <span className="svg-icon icon-trash"></span>
      </TableButton>
      {confirmationVisible && (
        <ConfirmDelete
          onCancel={() => setConfirmationVisisble(false)}
          onOk={() => {
            dispatch({ type: 'removed_row', index: i })
            setConfirmationVisisble(false)
          }}
        />
      )}
      <TableButton isVisible={showAdd} onClick={() => dispatch({ type: 'added_row', index: i })}>
        <span className="svg-icon icon-plus"></span>
      </TableButton>
    </TableButtonContainer>
  )
}

const ConfirmDelete = ({ onOk, onCancel }) => {
  const t = useTranslate()
  return (
    <DialogContainer>
      <span>{t('deleteRow')}?</span>
      <ButtonContainer>
        <EditDiscardButton onClick={onCancel}>
          <Icon red style={{ transform: 'scale(0.7)' }} className="svg-icon icon-x-noborder-mask" />
        </EditDiscardButton>
        <EditSaveButton onClick={onOk}>
          <Icon className="svg-icon icon-check-noborder-mask" />
        </EditSaveButton>
      </ButtonContainer>
    </DialogContainer>
  )
}

export default NormTableButtons

export const DialogContainer = styled.div`
  width: auto;
  background: var(--modal-container-bg-color);
  position: absolute;
  z-index: 999;
  right: 0;
  display: flex;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  padding: var(--space-3);
  box-shadow: var(--shadow-default);
  border-radius: var(--bdr-3);
  align-items: center;
  gap: var(--space-4);
`

const ButtonContainer = styled.div`
  display: flex;
  gap: var(--space-2);
`
