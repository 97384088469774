import BillingDataForm from '../../pages/shop/subcomponents/BillingDataForm'
import { ButtonPrimary, Modalfooter } from '../../utils/elements/miscElements'
import { useState, useContext, useRef } from 'react'
import ScrollContainer from '../scrollContainer/ScrollContainer'
import { AppContext } from '../../utils/context/AppContext'
import { saveBillingFormData } from '../../pages/shop/shopUtils'

const BillingDataSettingsComponent = () => {
  const submitButtonRef = useRef(null)
  const context = useContext(AppContext)
  const completeDataSet = context.completeDataSet
  const [formValid, setFormValid] = useState(false)
  const [formData, setFormData] = useState({
    billingCustomer: '' || completeDataSet.billing.billingCustomer,
    billingStreet: '' || completeDataSet.billing.billingStreet,
    billingAddressAddition: '' || completeDataSet.billing.billingAddressAddition,
    billingZipCode: '' || completeDataSet.billing.billingZipCode,
    billingCity: '' || completeDataSet.billing.billingCity,
    billingCountry: '' || completeDataSet.billing.billingCountry,
    billingSalutation: '' || completeDataSet.billing.billingSalutation,
    billingTitle: '' || completeDataSet.billing.billingTitle,
    billingFirstName: '' || completeDataSet.billing.billingFirstName,
    billingLastName: '' || completeDataSet.billing.billingLastName,
    billingEmail: '' || completeDataSet.billing.billingEmail,
    billingEmailCc: '' || completeDataSet.billing.billingEmailCc,
    billingCustomField1Label: '' || completeDataSet.billing.billingCustomField1Label,
    billingCustomField1Content: '' || completeDataSet.billing.billingCustomField1Content,
    billingCustomField2Label: '' || completeDataSet.billing.billingCustomField2Label,
    billingCustomField2Content: '' || completeDataSet.billing.billingCustomField2Content,
    visibleErrors: []
  })

  const content = (
    <ScrollContainer type="settings">
      <BillingDataForm setFormValid={setFormValid} formData={formData} setFormData={setFormData} />
      <Modalfooter type="settings">
        <ButtonPrimary
          ref={submitButtonRef}
          disabled={!formValid}
          content="save"
          onClick={() =>
            saveBillingFormData({
              data: formData,
              context: context
            })
          }
        />
      </Modalfooter>
    </ScrollContainer>
  )

  return content
}

export default BillingDataSettingsComponent
