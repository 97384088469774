import { DEFAULT_LANGUAGE } from '../utils/constants/constants'
import { TestConfig } from './TestConfig'

export class ParentConfig {
  constructor({ ...props }) {
    this.availableConfigs = props.availableConfigs.map((config) => new TestConfig(config)) || []
    this.availableLanguages = props.availableLanguages || []
    this.status = props.status
    this.testImage = props.testImage
    this.testType = props.testType
    this.translatedCareerLevel = props.translatedCareerLevel
    this.translatedJobGroup = props.translatedJobGroup
    this.translatedName = props.translatedName
    this.translatedSpecificJobs = props.translatedSpecificJobs
    this.uuid = props.uuid
    this.name = props.translatedName[DEFAULT_LANGUAGE]
    this.careerLevel = props.translatedCareerLevel[DEFAULT_LANGUAGE]
    this.specificJobs = props.translatedSpecificJobs[DEFAULT_LANGUAGE]
    this.jobGroup = props.translatedJobGroup[DEFAULT_LANGUAGE]
  }
  translate(language) {
    this.name = this.translatedName[language] || this.translatedName[DEFAULT_LANGUAGE]
    this.careerLevel = this.translatedCareerLevel[language] || this.translatedCareerLevel[DEFAULT_LANGUAGE]
    this.specificJobs = this.translatedSpecificJobs[language] || this.translatedSpecificJobs[DEFAULT_LANGUAGE]
    this.jobGroup = this.translatedJobGroup[language] || this.translatedJobGroup[DEFAULT_LANGUAGE]
  }
}
