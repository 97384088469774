import { PASSWORD_MIN_LENGTH } from '../../../utils/constants/constants'
import useTranslate from '../../../utils/hooks/useTranslate'
import NormsHelp from '../../helpModal/NormsHelp'
import ScreensHelp from '../../helpModal/ScreensHelp'
import { Entry, TimeLineContainer } from '../../timeline/timeLineElements'
import { B, Table, WizardStep, WizardStepContainer } from '../userManualElements'

const Chapter5 = () => {
  const t = useTranslate()
  return (
    <>
      <h2 id="chapter-5">5 Einstellungen</h2>
      <p>
        Um zu den Einstellungen zu gelangen, öffnen Sie zunächst das Menü, indem Sie oben rechts in der Menüleiste auf
        <B>Menü</B> klicken. Klicken Sie anschließend auf den Menüpunkt <B>Einstellungen</B>. Es öffnet sich eine zweite
        Navigationsleiste auf der linken Seite, über die Sie die verschiedenen Einstellungsseiten ansteuern können.
      </p>
      <h3 id="chapter-5-1">5.1 Unternehmen</h3>
      <h4 id="chapter-5-1-1">5.1.1 Kontoeinstellungen</h4>
      <h5>Unternehmensdaten</h5>
      <p>
        Hier können Sie die Stammdaten Ihres Unternehmens / Ihrer Behörde eintragen und editieren. Diese Funktion steht
        nur Administratoren zur Verfügung.
      </p>
      <h5>Rechnungsdaten</h5>
      <p>
        Als Administrator können Sie hier Ihre Rechnungsdaten eintragen und editieren. Neben den üblichen Feldern
        besteht die Möglichkeit, zwei Zusatzfelder mit Bezeichnung und Inhalt anzulegen. Benötigen Sie z.B. eine
        Bestell-Nr. auf der Rechnung, so geben Sie unter <em>Zusatzfeld 1 Bezeichnung</em> „Bestell-Nr.“ und unter{' '}
        <em>Zusatzfeld 1</em> Inhalt die benötigte Nummer ein.
      </p>
      <h5>Konto</h5>
      <p>
        Als Administrator können Sie hier Ihr Unternehmenskonto löschen. Bitte beachten Sie, dass dabei alle Daten
        verloren gehen. Ihr Nutzerkonto können Sie in der Nutzerverwaltung löschen.
      </p>
      <h4 id="chapter-5-1-2">5.1.2 Nutzerverwaltung</h4>
      <p>Als Administrator sehen und verwalten Sie hier alle Nutzer des Systems.</p>
      <h5>Zugriffsrechte festlegen</h5>
      <p>Hier können Sie die Zugriffsrechte je Verfahren festlegen. Es bestehen die folgenden drei Optionen:</p>
      <UserRolesTable />
      <div className="textbox important">
        <p>Nutzer mit Administrator-Rechten sind automatisch Editor für alle Verfahren.</p>
      </div>
      <h5>Einstellungen und Aktionen</h5>
      <p>
        Unter <em>Persönliche Daten</em> können Sie die Stammdaten des Nutzers editieren und Administrator-Rechte
        vergeben oder entfernen.
      </p>
      <p>
        Unter <em>Aktionen</em> können Sie den Nutzer-Account löschen. Gelöschte Nutzer können sich nicht mehr einloggen
        und erhalten keine Benachrichtigungen mehr. Sofern dem Kundenaccount mehr als ein Nutzer zugewiesen ist, können
        Sie hier auch Ihren eigenen Nutzer-Account löschen. Sie werden danach automatisch ausgeloggt. Gelöschte Nutzer
        können jederzeit wieder neu hinzugefügt werden.
      </p>
      <h5>Nutzer anlegen</h5>
      <p>
        So öffnen Sie den Prozess <em>Nutzer anlegen</em>: Klicken Sie am oberen rechten Rand des Fensters auf{' '}
        <B>Menü</B>, klicken auf
        <B>Einstellungen</B> und dann auf <B>Nutzerverwaltung</B>. Klicken Sie nun auf <B>Neuen Nutzer anlegen</B>.
      </p>
      <TimeLineContainer className="wizard">
        <Entry>
          <WizardStepContainer>
            <WizardStep>1. Nutzerdaten</WizardStep>
            <ul>
              <li>Tragen Sie die Daten des neuen Nutzers in das Formular ein.</li>
              <li>
                Wählen Sie, ob der neue Nutzer Administrator-Rechte erhalten soll. Wenn der neue Nutzer
                Administrator-Rechte erhält, wird die folgende Seite übersprungen.
              </li>
            </ul>
          </WizardStepContainer>
        </Entry>

        <Entry>
          <WizardStepContainer>
            <WizardStep>2. Berechtigungen festlegen</WizardStep>
            <ul>
              <li>Legen Sie die Berechtigungen für den neuen Nutzer fest.</li>
            </ul>
          </WizardStepContainer>
        </Entry>
        <Entry>
          <WizardStepContainer>
            <WizardStep>3. Eingaben prüfen</WizardStep>
            <ul>
              <li>Überprüfen Sie Ihre Eingaben.</li>
              <li>
                Klicken Sie auf <B>Nutzer einladen</B>. Der Nutzer erhält anschließend eine E-Mail mit seinen
                Zugangsdaten.
              </li>
            </ul>
          </WizardStepContainer>
        </Entry>
      </TimeLineContainer>

      <h4 id="chapter-5-1-3">5.1.3 {t('dataMaintenance')}</h4>
      <h5>{t('automaticAnonymization')}</h5>
      <p>{t('anonymizationExplanation')}</p>
      <h5>Automatische Archivierung</h5>
      <p>{t('archiveExplanation')}</p>

      <h4 id="chapter-5-1-4">5.1.4 {t('customization')}</h4>

      <h5>Eigene Felder</h5>
      <p>
        Hier können Sie eigene Felder für Ihre Teilnehmer anlegen. In der Teilnehmerübersicht können Sie Daten in Ihre
        Felder eintragen und danach filtern und sortieren. Sie können auch Daten per CSV-Upload in Ihre Felder
        hochladen, wie z.B. externe IDs eines Bewerbermanagementsystems.{' '}
      </p>
      <CustomFieldsTable />

      <h5>{t('gradingRules')}</h5>
      <NormsHelp />

      <h5>{t('customScreens')}</h5>
      <ScreensHelp />

      <h4 id="chapter-5-1-5">5.1.5 {t('emailSettings')}</h4>
      <h5>E-Mail-Vorlagen</h5>
      <p>Hier können Sie eigene E-Mail-Vorlagen erstellen und speichern.</p>
      <p>
        Wählen Sie zunächst eine bestehende Vorlage aus der Liste aus. Anschließend können Sie den E-Mail-Betreff, die
        Anrede sowie zwei Freitextfelder anpassen. Die Zugangsdaten werden automatisch zwischen den beiden
        Freitextfeldern eingefügt. Um Ihre Vorlage zu speichern, klicken Sie auf <B>Speichern...</B>.
      </p>
      <h5>Automatische Erinnerungen</h5>
      <p>
        Hier haben Sie die Möglichkeit, automatische Erinnerungen (E-Mail-Reminder) für Ihre Tests anzulegen und zu
        verwalten. Erinnerungen mit Status <em>Aktiv</em> werden automatisch zum festgelegten Versandzeitpunkt
        verschickt.
      </p>
      <p>
        Um eine neue Erinnerung hinzuzufügen, klicken Sie auf <B>+ Erinnerung</B>
      </p>
      <TimeLineContainer className="wizard">
        <Entry>
          <WizardStepContainer>
            <WizardStep>1. Betreff und Inhalt</WizardStep>
            <ul>
              <li>Sie können den Betreff, die Anrede sowie zwei Freitextfelder anpassen.</li>
              <li>Die Zugangsdaten werden automatisch zwischen den beiden Freitextfeldern eingefügt.</li>
            </ul>
          </WizardStepContainer>
        </Entry>
        <Entry>
          <WizardStepContainer>
            <WizardStep>2. Vorschau</WizardStep>
            <ul>
              <li>Auf dieser Seite wird Ihnen eine Vorschau Ihrer E-Mail angezeigt.</li>
              <li>
                Die Vorschaudaten können Sie unter <em>Daten für Vorschau</em> ändern.
              </li>
              <li>
                Wenn Sie eine Muster-E-Mail versenden möchten, tragen Sie eine E-Mail-Adresse in das entsprechende Feld
                in der Seitenleiste ein und drücken auf <B>Jetzt senden</B>.
              </li>
            </ul>
          </WizardStepContainer>
        </Entry>
        <Entry>
          <WizardStepContainer>
            <WizardStep>3. Einstellungen</WizardStep>
            <ul>
              <li>Geben Sie der Erinnerung einen aussagekräftige Titel.</li>
              <li>
                Optional können Sie einen Namen und eine E-Mail-Adresse für das <em>Antwort an</em> Feld eingeben. Wenn
                der Empfänger auf <B>Antworten</B> klickt, wird die Antwort an die hier angegebene Adresse gesendet.
              </li>
              <li>
                Bestimmen Sie den Versandzeitpunkt. Wenn Sie die Option <em>nach Einladung</em> wählen, werden nur
                solche Teilnehmer erinnert, die zuvor eine Einladungsmail erhalten haben. Wenn Sie die Option{' '}
                <em>vor Ablauf</em> wählen, muss bei der Testung ein Ablaufdatum hinterlegt sein.
              </li>
              <li>Wählen Sie aus, ob die Erinnerung sofort nach dem Anlegen aktiviert werden soll.</li>
            </ul>
          </WizardStepContainer>
        </Entry>
        <Entry>
          <WizardStepContainer>
            <WizardStep>4. Tests wählen</WizardStep>
            <ul>
              <li>Wählen Sie mindestens einen Test, für den die Erinnerungen verschickt werden sollen.</li>
            </ul>
          </WizardStepContainer>
        </Entry>
        <Entry>
          <WizardStepContainer>
            <WizardStep>5. Eingaben prüfen</WizardStep>
            <ul>
              <li>Überprüfen Sie Ihre Eingaben.</li>
              <li>
                Klicken Sie auf <B>Speichern</B>.
              </li>
            </ul>
          </WizardStepContainer>
        </Entry>
      </TimeLineContainer>
      <h3 id="chapter-5-2">5.2 Nutzer</h3>
      <h4 id="chapter-5-2-1">5.2.1 Nutzereinstellungen</h4>
      <h5>Persönliche Daten</h5>
      <p>
        Hier können Sie Ihre persönlichen Daten einsehen und editieren. Beachten Sie, dass die hier eingetragene
        E-Mail-Adresse gleichzeitig als Login für den Kundenbereich dient.
      </p>
      <h5>Passwort</h5>
      <p>
        Hier können Sie Ihr Passwort für den Login ändern. Geben Sie dazu zunächst Ihr altes Passwort und anschließend
        ein neues Passwort in die vorgesehenen Felder ein. Das Passwort muss aus mindestens {PASSWORD_MIN_LENGTH}{' '}
        Zeichen bestehen.
      </p>
      <h4 id="chapter-5-2-2">5.2.2 Benachrichtigungen</h4>
      <h5>Abgeschlossene Tests</h5>
      <p>
        Hier können Sie einstellen, wie oft Sie per E-Mail über neu abgeschlossene Tests informiert werden möchten. Die
        Benachrichtigung umfasst alle für Sie sichtbaren Tests.
      </p>
      <h5>Newsletter</h5>
      <p>
        Hier können Sie sich für unseren Newsletter ein- und austragen. Im Newsletter informieren wir ca. zwei mal pro
        Jahr über Neuigkeiten und Änderungen unserer Tests und Software.
      </p>
      <h5>Start-Nachrichten</h5>
      <p>Hier finden Sie alle bisherigen Start-Nachrichten und können diese nochmal lesen.</p>
      <h4 id="chapter-5-2-3">5.2.3 Darstellung und Sprache</h4>
      <p>
        Hier können Sie zwischen dem hellen und dunklen Anzeigemodus wechseln und die Sprache des Kundenbereichs
        einstellen. Bitte beachten Sie, dass die hier gewählte Sprache nur den Kundenbereich betrifft. Die Sprache der
        Tests können Sie in den Testoptionen einstellen.
      </p>
    </>
  )
}
export default Chapter5

const UserRolesTable = () => {
  const header = ['Option', 'Beschreibung']
  const data = [
    ['keine Freigabe', 'Verfahren ist für diesen Nutzer nicht sichtbar'],
    ['Betrachter', 'Nutzer kann das Verfahren einsehen, aber nicht bearbeiten'],
    ['Editor', 'Nutzer kann das Verfahren einsehen und bearbeiten']
  ]
  return <Table name="user-roles" {...{ header, data }} />
}

const CustomFieldsTable = () => {
  const header = ['Feld', 'Beschreibung']
  const data = [
    ['Bezeichnung', 'Der Name Ihres neuen Feldes'],
    ['Typ', 'Hier können Sie zwischen Texteingabe und Auswahlfeld wählen.'],
    [
      'Optionen',
      'Wenn Sie ein Auswahlfeld anlegen möchten, können Sie hier die zur Wahl stehenden Optionen eintragen. Optionen werden mit Komma getrennt.'
    ],
    [
      'Feld-Schlüssel',
      'Der Feldschlüssel wird verwendet, um Ihr Feld technisch eindeutig zu referenzieren. Sie benötigen den Feld-Schlüssel, wenn Sie Daten per CSV-Upload in Ihr eigenes Feld laden möchten.'
    ]
  ]
  return <Table name="user-roles" {...{ header, data }} />
}
