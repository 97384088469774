import { CustomTableCheckbox } from '../../../components/table/tableElements'
import useTranslate from '../../../utils/hooks/useTranslate'
import styled from 'styled-components'
import { ErrorMessage, Label } from '../../../utils/elements/miscElements'
import { setState } from '../../../utils/helper/Helper'
import { FORM_ERRORS } from '../../../utils/constants/constants'

const SaveTemplateModal = ({ data, setData }) => {
  const t = useTranslate()

  const options = [
    {
      label: 'saveAsNewTemplate',
      value: 'add'
    },
    {
      label: 'editCurrentTemplate',
      value: 'edit'
    }
  ]

  const cannotEditTemplate = data.availableTemplates.find((t) => t.publicKey === data.templateKey).disabled
  const translatedOptions = t(options)
  const nameEmpty = data.newTemplateName.length === 0
  return (
    <div>
      <List>
        {translatedOptions.map((option) => {
          const isChecked = option.value === data.saveMethod
          const className = isChecked ? 'svg-icon icon-circle' : ''
          const disabled = cannotEditTemplate && option.value === 'edit'
          return (
            <ListElement key={option.label}>
              <ListLabel disabled={disabled} onClick={() => setState(setData, 'saveMethod', option.value)}>
                <CustomTableCheckbox
                  type="radio"
                  style={{ justifySelf: 'left', transform: 'none' }}
                  checked={isChecked}
                  onKeyPress={() => setState(setData, 'saveMethod', option.value)}
                  className={'custom-checkbox ' + className + (disabled ? ' disabled' : '')}
                  disabled={disabled}
                />
                <span>{option.label}</span>
              </ListLabel>
            </ListElement>
          )
        })}
      </List>
      <Label style={{ width: '100%', textAlign: 'left' }} key="customerName">
        <span>{t('title')}</span>
        <input
          type="text"
          value={data.newTemplateName}
          onChange={(e) => setState(setData, 'newTemplateName', e.target.value)}
          className={nameEmpty ? 'has-error' : ''}
        />
        <ErrorMessage visible={nameEmpty}>{t(FORM_ERRORS.fieldEmpty)}</ErrorMessage>
      </Label>
    </div>
  )
}

export default SaveTemplateModal

const List = styled.ul`
  padding: 0;
  list-style: none;
  gap: var(--space-3);
  display: grid;
  margin-bottom: var(--space-6);
`

const ListElement = styled.li`
  display: flex;
`

const ListLabel = styled.label`
  display: inline-grid;
  grid-template-columns: 30px 1fr;
  align-items: center;
  font-size: var(--fs-3);
  pointer-events: ${(props) => (props.disabled ? 'none' : '')};
  color: ${(props) => (props.disabled ? 'var(--text-color-secondary)' : '')};
`
