import { useContext, useEffect, useState } from 'react'
import {
  ButtonPrimary,
  ButtonSecondary,
  ErrorMessage,
  Hint,
  Label,
  Modalfooter,
  selectStyles,
  WizardForm
} from '../../../utils/elements/miscElements'
import { getOptionByValue } from '../../../utils/helper/Helper'
import { FORM_ERRORS, SELECT_OPTIONS, MODAL_TYPES, FIELD_LABELS } from '../../../utils/constants/constants'
import { AppContext } from '../../../utils/context/AppContext'
import Select from 'react-select'
import {
  saveCustomFieldDefinitions,
  getDuplicateLabels,
  deleteCustomFieldDefinition
} from './customFieldDefinitionsUtils'
import { CustomFieldContainer, Trashbutton } from './customFieldDefinitionsElements'
import ScrollContainer from '../../scrollContainer/ScrollContainer'
import useTranslate from '../../../utils/hooks/useTranslate'

const CustomFieldsComponent = () => {
  const t = useTranslate()
  const context = useContext(AppContext)
  const customFieldDefinitions = context.completeDataSet.customFieldDefinitions
  const fieldTemplate = {
    publicKey: '',
    slug: '',
    model: 'participants',
    label: '',
    type: 'text',
    options: ''
  }

  const slugsString = JSON.stringify(customFieldDefinitions.map((cfd) => cfd.slug))
  const [fieldDefinitions, setFieldDefinitions] = useState(customFieldDefinitions)
  const [duplicateLabels, setDuplicateLabels] = useState([])
  const [emptyLabels, setEmptyLabels] = useState([])
  const [tooLongLabels, setTooLongLabels] = useState([])
  const [emptyOptionFields, setEmptyOptionFields] = useState([])
  const [tooLongOptionFields, setTooLongOptionFields] = useState([])
  const [showEmptyFieldError, setShowEmptyFieldError] = useState(false)
  const keyCount = customFieldDefinitions.filter((cf) => cf.publicKey).length

  const MAX_LABEL_LENGTH = 30
  const MAX_OPTIONS_LENGTH = 250

  useEffect(() => {
    setFieldDefinitions([...customFieldDefinitions])
  }, [keyCount, slugsString]) // trigger Rerender

  const editField = (index, prop, value) => {
    const updatedFieldDefinitions = fieldDefinitions.map((field, i) =>
      i === index ? { ...field, [prop]: value } : field
    )
    setFieldDefinitions(updatedFieldDefinitions)
    setEmptyLabels(updatedFieldDefinitions.filter((fd) => fd.publicKey && !fd.label))
    setTooLongLabels(updatedFieldDefinitions.filter((fd) => fd.label.length > MAX_LABEL_LENGTH))
    setTooLongOptionFields(
      updatedFieldDefinitions.filter((fd) => fd.type === 'select' && fd.options?.length > MAX_OPTIONS_LENGTH)
    )
    setEmptyOptionFields(updatedFieldDefinitions.filter((fd) => fd.type === 'select' && fd.options === ''))

    getDuplicateLabels(updatedFieldDefinitions).then((labels) => {
      setDuplicateLabels(labels)
    })
  }

  const hasErrors =
    duplicateLabels.length > 0 ||
    emptyLabels.length > 0 ||
    tooLongLabels.length > 0 ||
    emptyOptionFields.length > 0 ||
    tooLongOptionFields.length > 0

  const handleDelete = (e, field, index) => {
    e.preventDefault()
    if (field.slug) {
      context.setModalProps({
        headline: 'deleteCustomField',
        content: t('confirmDeleteField', field.label),
        buttonPrimary: (
          <ButtonPrimary
            modalButton
            warning
            content="deleteField"
            onClick={() => {
              context.setModalOpen(false)
              deleteCustomFieldDefinition({
                fieldDefinition: field,
                context: context
              })
            }}
          />
        ),
        icon: 'icon-trash modal',
        type: MODAL_TYPES.alert
      })
      context.setModalOpen(true)
    } else {
      const tmp = fieldDefinitions.filter((_, i) => i !== index)
      setFieldDefinitions(tmp)
    }
  }

  const handleSave = () => {
    if (duplicateLabels.length === 0) {
      saveCustomFieldDefinitions({
        fieldDefinitions: fieldDefinitions,
        context: context
      })
    }
  }

  const addField = (e) => {
    e.preventDefault()
    setFieldDefinitions((prev) => [...prev, fieldTemplate])
  }

  return (
    <div>
      <ScrollContainer type="settings">
        <p>{t('customFieldsExplanation')}</p>
        <WizardForm style={{ maxWidth: '600px', minWidth: '350px' }}>
          {fieldDefinitions.map((field, i) => {
            const labelIsDuplicate = duplicateLabels.includes(field.label)
            const labelIsTooLong = field.label.length > MAX_LABEL_LENGTH
            const labelIsEmpty = field.publicKey && !field.label
            const optionFieldIsEmpty = field.type === 'select' && field.options === ''
            const optionFieldIsTooLong = field.type === 'select' && field.options?.length > MAX_OPTIONS_LENGTH

            return (
              <CustomFieldContainer key={`customField${i}`}>
                <Label key={`field${i}Label`}>
                  <span>{t(FIELD_LABELS.elementName)} *</span>
                  <div style={{ display: 'grid' }}>
                    <input
                      className={labelIsDuplicate || labelIsEmpty || labelIsTooLong ? 'has-error' : ''}
                      type="text"
                      value={field.label}
                      onChange={(e) => editField(i, 'label', e.target.value)}
                      onKeyPress={(e) => (e.key === 'Enter' ? e.preventDefault() : '')}
                    />
                    <ErrorMessage visible={labelIsDuplicate}>{t(FORM_ERRORS.labelIsDuplicate)}</ErrorMessage>
                    <ErrorMessage visible={labelIsEmpty}>{t(FORM_ERRORS.fieldEmpty)}</ErrorMessage>
                    <ErrorMessage visible={labelIsTooLong}>{t('maxCharsPolite', MAX_LABEL_LENGTH)}</ErrorMessage>
                    <Hint visible={true}>{t('maxChars', 30)}</Hint>
                  </div>
                </Label>
                <Label key={`field${i}Type`}>
                  <span>{t('type')}</span>
                  <Select
                    options={t(SELECT_OPTIONS.customFieldTypes)}
                    onChange={(selectedOption) => editField(i, 'type', selectedOption.value)}
                    value={getOptionByValue(t(SELECT_OPTIONS.customFieldTypes), field.type || '')}
                    styles={selectStyles}
                    placeholder=""
                  />
                </Label>
                <Label key={`field${i}slug`}>
                  <span>{t('fieldKey')}</span>
                  <div>
                    <input type="text" value={field.slug} readOnly disabled />
                    <Hint visible>{t('createdAutomatically')}</Hint>
                  </div>
                </Label>
                {field.type === 'select' && (
                  <Label style={{ gridRow: 2, gridColumn: '1/3' }} key={`field${i}options`}>
                    <span>{t('options')} *</span>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <input
                        type="text"
                        className={
                          (optionFieldIsEmpty && showEmptyFieldError) || optionFieldIsTooLong ? 'has-error' : ''
                        }
                        value={field.options}
                        onChange={(e) => editField(i, 'options', e.target.value)}
                        onKeyPress={(e) => (e.key === 'Enter' ? e.preventDefault() : '')}
                        onBlur={() => setShowEmptyFieldError(true)}
                      />
                      <ErrorMessage visible={optionFieldIsEmpty && showEmptyFieldError}>
                        {t(FORM_ERRORS.fieldEmpty)}
                      </ErrorMessage>
                      <ErrorMessage
                        visible={optionFieldIsTooLong}>{`Bitte maximal ${MAX_OPTIONS_LENGTH} Zeichen`}</ErrorMessage>
                      <Hint visible>
                        {t('seperateWithComma')}; {t('maxChars', 250)}
                      </Hint>
                    </div>
                  </Label>
                )}
                <Trashbutton onClick={(e) => handleDelete(e, field, i)}>
                  <span className="svg-icon icon-trash custom-fields"></span>
                </Trashbutton>
              </CustomFieldContainer>
            )
          })}
        </WizardForm>
        <Modalfooter type="settings">
          <ButtonSecondary style={{ width: '80px', flexGrow: 0 }} content="newField" onClick={addField} />
          {fieldDefinitions.length > 0 && <ButtonPrimary content="save" onClick={handleSave} disabled={hasErrors} />}
        </Modalfooter>
      </ScrollContainer>
    </div>
  )
}

export default CustomFieldsComponent
