import { useCallback, useMemo, useState } from 'react'
import useTranslate from '../../../../../utils/hooks/useTranslate'
import useEventListener from '../../../../../utils/hooks/useEventListener'
import { ButtonPrimary, ButtonSecondary } from '../../../../../utils/elements/miscElements'
import styled from 'styled-components'
import { CustomTableCheckbox } from '../../../tableElements'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { computeInitialState, reorder, saveTableColumns } from './columnControllerUtils'
import useTrapFocus from '../../../../../utils/hooks/useTrapFocus'

const ColumnControllerModal = ({ allColumns, setShow, context, tableName }) => {
  const t = useTranslate()
  const initialState = useMemo(() => computeInitialState(allColumns), [])

  const [tmpVisibleColumns, setTmpVisibleColumns] = useState(initialState)
  const clearState = () => {
    setTmpVisibleColumns(initialState)
  }

  const handleColumnVisibilityChange = (selectedColumn) => {
    const updatedState = toggleColumnVisibility(selectedColumn)
    setTmpVisibleColumns([...updatedState])
  }

  const handleKeyDown = useCallback(
    (event) => {
      if (event.code === 'Escape') {
        event.preventDefault()
        setShow(false)
      }
    },
    [setShow]
  )

  useEventListener('keydown', handleKeyDown)
  useTrapFocus()

  const toggleColumnVisibility = (selectedColumn) => {
    return tmpVisibleColumns.map((column) =>
      column.id === selectedColumn.id ? { ...column, isVisible: !column.isVisible } : column
    )
  }

  const onDragEnd = (result) => {
    if (!result.destination) {
      return
    }

    const newColumns = reorder(tmpVisibleColumns, result.source.index, result.destination.index)
    setTmpVisibleColumns([...newColumns])
  }

  return (
    <Container className="modal alert-modal column-controller">
      <h2 style={{ marginBottom: 'var(--space-6)' }}>{t('adjustTableColumns')}</h2>
      <TableContainer className="table-outer-container column-controller" style={{ width: 'auto' }}>
        <div className="table-inner-container" style={{ height: '250px' }}>
          <ColumnsTable>
            <thead>
              <tr>
                <th />
                <th>{t('column')}</th>
                <th>{t('visible')}</th>
              </tr>
            </thead>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppableTableBody" direction="vertical" type="droppableForGroups">
                {(provided) => (
                  <tbody {...provided.droppableProps} ref={provided.innerRef}>
                    {tmpVisibleColumns.map((column, index) => (
                      <Draggable key={column.id} draggableId={column.id.toString()} index={index}>
                        {(provided, snapshot) => (
                          <tr
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            style={{
                              ...provided.draggableProps.style,
                              background: snapshot.isDragging ? 'var(--table-hover-bg-color)' : '',
                              borderTop: snapshot.isDragging ? 'none' : ''
                            }}>
                            <td className="drag-handle" {...provided.dragHandleProps} tabIndex={-1}>
                              <span className="svg-icon icon-grip" />
                            </td>
                            <td className="col-name" onClick={() => handleColumnVisibilityChange(column)}>
                              {t(column.header)}
                            </td>
                            <td className="col-checkbox" onClick={() => handleColumnVisibilityChange(column)}>
                              <CustomTableCheckbox
                                onKeyPress={() => handleColumnVisibilityChange(column)}
                                className={`custom-checkbox ${column.isVisible ? 'svg-icon icon-check-noborder' : ''}`}
                                checked={column.isVisible}
                                style={{ transform: 'unset', justifySelf: 'unset' }}
                              />
                            </td>
                          </tr>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </tbody>
                )}
              </Droppable>
            </DragDropContext>
          </ColumnsTable>
        </div>
      </TableContainer>
      <div className="text-secondary" style={{ marginTop: 'var(--space-2)', textAlign: 'center' }}>
        {t('dragAndDropLines')}
      </div>

      <ButtonContainer>
        <ButtonSecondary
          content="cancel"
          style={{ height: '36px', minHeight: '36px' }}
          onClick={() => {
            clearState()
            setShow(false)
          }}
        />

        <ButtonPrimary
          content="save"
          style={{ height: '36px', minHeight: '36px' }}
          onClick={() => {
            saveTableColumns({ data: tmpVisibleColumns, context: context, tableName: tableName })
            setShow(false)
          }}
        />
      </ButtonContainer>
    </Container>
  )
}
export default ColumnControllerModal

const Container = styled.div`
  position: absolute;
  right: 48px;
  top: 18px;
  z-index: 99;
  padding: var(--space-6);
  background: var(--modal-container-bg-color);
  box-shadow: var(--shadow-default);
  border-radius: 6px;
  width: 400px;
  @media (max-width: 576px) {
    right: 0;
    top: 0;
    width: 100%;
    padding: var(--inset);
  }
`

const ColumnsTable = styled.table`
  text-align: left;
  white-space: normal;
  border-collapse: collapse;
`

const ButtonContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--space-3);
  margin-top: var(--space-6);
`

const TableContainer = styled.div`
  box-shadow: none;
  width: 100%;
  margin: 0 auto;
  font-size: var(--fs-3);
  overflow: auto;
  max-height: 250px;
`
