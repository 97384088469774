import ContextMenu from '../contextMenu/ContextMenu'
import sessionHandler from '../../utils/helper/sessionHandler'
import styled from 'styled-components'
import { useCallback, useContext, useState } from 'react'
import { loginMessages } from '../../utils/constants/constants'
import { PAGES } from '../../utils/constants/pages'
import { AppContext } from '../../utils/context/AppContext'
import { trapFocus } from '../../utils/helper/Helper'
import useEventListener from '../../utils/hooks/useEventListener'
import useTranslate from '../../utils/hooks/useTranslate'

const TopMenu = () => {
  const [isVisible, setIsVisible] = useState(false)
  const context = useContext(AppContext)
  const t = useTranslate()

  const hideMenu = () => {
    setIsVisible(false)
    document.body.classList.remove('focus-trapped')
  }

  const handleLogout = () => {
    hideMenu()
    context.setLoginMessage(loginMessages.logout)
    sessionHandler.logout(context)
  }

  if (isVisible) {
    setTimeout(() => {
      const focusableElements = document.querySelectorAll('#mainMenu a')
      if (focusableElements) {
        focusableElements[0].focus()
        trapFocus(focusableElements)
      }
    }, 50)
  }

  const handleKeyDown = useCallback(
    (event) => {
      if (event.code === 'Escape') {
        event.preventDefault()
        hideMenu()
      }
    },
    [hideMenu]
  )

  useEventListener('keydown', handleKeyDown)

  return (
    <TopMenuWrapper isActive={isVisible}>
      <MainMenuButton id="mainMenu" onClick={() => setIsVisible(!isVisible)}>
        <MenuLabel id="mainMenuLabel">{t('menu')}</MenuLabel>
        <span id="mainMenuIcon" className="svg-icon icon-menu menu" />
      </MainMenuButton>

      {isVisible && (
        <ContextMenu
          isVisible={isVisible}
          hideMenu={hideMenu}
          id="mainMenu"
          entries={[
            [
              {
                type: 'link',
                to: context.loggedInAsAdmin ? PAGES.accountSettings : PAGES.userSettings,
                clickHandler: hideMenu,
                icon: 'icon-settings',
                label: 'settings'
              }
            ],
            [
              {
                type: 'link',
                to: PAGES.help,
                clickHandler: hideMenu,
                icon: 'icon-help',
                label: 'help'
              },
              {
                type: 'link',
                to: PAGES.support,
                clickHandler: hideMenu,
                icon: 'icon-support-dark',
                iconStyle: { transform: 'scale(.9)' },
                label: 'Support'
              }
            ],
            [
              {
                type: 'link',
                to: PAGES.invoices,
                clickHandler: hideMenu,
                icon: 'icon-credit-card',
                label: 'invoices',
                adminOnly: true
              },
              {
                type: 'link',
                to: PAGES.downloads,
                clickHandler: hideMenu,
                icon: 'icon-download',
                label: 'documents'
              }
            ],
            [
              {
                type: 'link',
                to: PAGES.privacy,
                clickHandler: hideMenu,
                icon: 'icon-data-security',
                label: 'dataSecurity'
              },
              {
                type: 'link',
                to: PAGES.imprint,
                clickHandler: hideMenu,
                icon: 'icon-imprint',
                label: 'imprint'
              }
            ],
            [
              {
                type: 'link',
                to: PAGES.login,
                clickHandler: () => handleLogout(),
                icon: 'icon-logout',
                label: 'logout'
              }
            ]
          ]}
        />
      )}
    </TopMenuWrapper>
  )
}

export default TopMenu

const MainMenuButton = styled.button`
  display: inline-flex;
  background-color: transparent;
  align-items: center;
  justify-content: center;
  padding: var(--space-2);
  width: 83px;
  height: 32px;
  border-radius: var(--bdr-2);
  &:hover {
    background-color: var(--main-menu-button-hover-color);
  }
`

const TopMenuWrapper = styled.div`
  border-radius: var(--bdr-2);
  margin-left: var(--space-3);
  position: relative;
  display: flex;
  background-color: ${(props) => (props.isActive ? 'var(--main-menu-bg-color)' : 'transparent')};
`

const MenuLabel = styled.span`
  color: var(--text-color-primary);
  margin-right: var(--space-3);
  font-size: var(--fs-4);
  font-weight: var(--fw-2);
`
