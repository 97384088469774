import React, { useContext } from 'react'
import Arrow from '../../components/arrow/Arrow'
import EmptyTableMessage from './EmptyTableMessage'
import { getCellClassName, getTableClassName } from './tableUtils'
import useCurrentRect from '../../utils/hooks/useCurrentRect'
import useScrollPosition from '../../utils/hooks/useScrollPosition'
import useStickyColumnShadow from '../../utils/hooks/useStickyColumnShadow'
import useTranslate from '../../utils/hooks/useTranslate'
import { TableContext } from './DataTable'
import { AppContext } from '../../utils/context/AppContext'

export const Table = ({
  hasSubrows,
  getTableProps,
  headerGroups,
  getTableBodyProps,
  page,
  prepareRow,
  getTrClassName,
  toggleExpanded,
  RowSubComponent,
  visibleColumns,
  hideHeader
}) => {
  const { tableName } = useContext(TableContext)
  const { showArchivedItems } = useContext(AppContext)

  const subRowColSpan = visibleColumns.length
  const width = useCurrentRect().width
  const innerContainer = document.querySelectorAll('.table-inner-container')[0]
  const scrollPosition = useScrollPosition(innerContainer)
  const hasShadow = useStickyColumnShadow(width, visibleColumns, page, scrollPosition)
  const tableClassName = getTableClassName(tableName, hasSubrows, hasShadow)
  const selectRowOnClick = ['participant-select-for-email', 'configs', 'test-select-for-reminder'].includes(tableName)
  const t = useTranslate()

  return (
    <div className={`data-table table-outer-container ${showArchivedItems ? 'show-archived' : ''}`}>
      <div className="table-inner-container">
        <table {...getTableProps()} className={tableClassName}>
          {!hideHeader && (
            <thead>
              {headerGroups.map((headerGroup) => {
                const { key, ...restHeaderGroupProps } = headerGroup.getHeaderGroupProps()
                return (
                  <tr key={key} {...restHeaderGroupProps}>
                    {headerGroup.headers.map((column, i) => (
                      <th
                        className={column.className}
                        style={{
                          zIndex: 999 - i,
                          textAlign: column.textAlign
                        }}
                        key={i}
                        {...column.getHeaderProps}>
                        {!column.disableSortBy ? (
                          <span
                            {...column.getSortByToggleProps({
                              title: t('sort')
                            })}>
                            {t(column.render('Header'))}
                          </span>
                        ) : (
                          <>{t(column.render('Header'))}</>
                        )}
                        <div className="sort-arrow" style={{ marginLeft: 'var(--space-1)' }}>
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <Arrow sortArrow direction="down" lower />
                            ) : (
                              <Arrow sortArrow direction="up" lower />
                            )
                          ) : (
                            ''
                          )}
                        </div>
                      </th>
                    ))}
                  </tr>
                )
              })}
            </thead>
          )}
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row)
              return (
                <React.Fragment key={row.getRowProps().key}>
                  <tr
                    style={{ position: 'relative' }}
                    className={getTrClassName(row)}
                    onClick={(e) => {
                      const clickedCheckboxCol = e.target.classList.contains('checkbox-col')
                      if (!clickedCheckboxCol && hasSubrows) {
                        toggleExpanded(row, e, hasSubrows)
                      }
                      if (selectRowOnClick) {
                        row.toggleRowSelected()
                      }
                    }}>
                    {row.cells.map((cell) => {
                      const className = getCellClassName(row, cell)
                      const { key, ...restCellProps } = cell.getCellProps()
                      return (
                        <td style={cell.column.style} className={className} key={key} {...restCellProps}>
                          {cell.render('Cell')}
                        </td>
                      )
                    })}
                  </tr>
                  {row.isExpanded && (
                    <tr className="subrow">
                      <td colSpan={subRowColSpan}>
                        <div className="subrow-container">{RowSubComponent(row)}</div>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              )
            })}
          </tbody>
        </table>
      </div>
      {page.length === 0 && <EmptyTableMessage headerGroups={headerGroups} />}
    </div>
  )
}

export default Table
