import { FORM_ERRORS } from '../../../utils/constants/constants'
import { ErrorMessage, Label, selectStyles } from '../../../utils/elements/miscElements'
import { getOptionByValue } from '../../../utils/helper/Helper'
import useTranslate from '../../../utils/hooks/useTranslate'
import { getSalutationOptions } from '../../../wizards/sendEmailWizard/subComponents/SalutationSelection'
import { AccessDataPlaceholder, TextArea } from '../../../wizards/sendEmailWizard/subPages/EmailTemplate'
import Select from 'react-select'
import styled from 'styled-components'

const TemplatePreview = ({ template, dispatch }) => {
  const t = useTranslate()
  const subjectEmpty = false
  const salutationOptions = getSalutationOptions(t)
  return (
    <TemplateContainer>
      <div>
        <Label fullWidth>{t('subject')}</Label>
        <div>
          <input
            className={subjectEmpty ? 'has-error' : ''}
            value={template.templateData.subject}
            onChange={(e) =>
              dispatch({
                type: 'updated_template',
                field: 'subject',
                value: e.target.value
              })
            }
            type="text"
            style={{ width: '100%' }}
          />

          <ErrorMessage visible={subjectEmpty}>{t(FORM_ERRORS.fieldEmpty)}</ErrorMessage>
        </div>
      </div>
      <div>
        <Label fullWidth>{t('salutation')}</Label>
        <div style={{ maxWidth: '270px' }}>
          <Select
            options={salutationOptions}
            onChange={(selectedOption) =>
              dispatch({
                type: 'updated_template',
                field: 'salutationType',
                value: selectedOption.value
              })
            }
            styles={selectStyles}
            value={getOptionByValue(salutationOptions, template.templateData.salutationType)}
            isSearchable={false}
          />
        </div>
      </div>

      <div>
        <Label fullWidth>{t('freeTextTop')}</Label>
        <TextArea
          rows={5}
          onChange={(e) =>
            dispatch({
              type: 'updated_template',
              field: 'topText',
              value: e.target.value
            })
          }
          value={template.templateData.topText}
        />
      </div>

      <div>
        <AccessDataPlaceholder style={{ backgroundColor: 'var(--box-background-hover)' }}>
          {t('accessDataPlaceholder')}
        </AccessDataPlaceholder>
      </div>

      <div>
        <Label fullWidth>{t('freeTextBottom')}</Label>
        <TextArea
          rows={5}
          onChange={(e) =>
            dispatch({
              type: 'updated_template',
              field: 'bottomText',
              value: e.target.value
            })
          }
          value={template.templateData.bottomText}
        />
      </div>
    </TemplateContainer>
  )
}

export default TemplatePreview

const TemplateContainer = styled.div`
  display: grid;
  gap: var(--space-5);
  background: var(--box-background);
  padding: var(--space-5);
  border-radius: var(--bdr-2);
`
