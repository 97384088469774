import styled from 'styled-components'
import { getOptionByValue } from '../../utils/helper/Helper'
import { CustomInputList, Tile, TileHeadline } from '../table/tableElements'
import { useContext } from 'react'
import CustomInput from '../customInput/CustomInput'
import {
  API_DATA,
  DEFAULT_SCREEN_NR_END,
  DEFAULT_SCREEN_NR_START,
  END_SCREEN_KEY,
  ERROR_MSG,
  FIELD_LABELS,
  PERSEO_DEFAULT,
  SCREEN_TYPE_END,
  SCREEN_TYPE_START,
  START_SCREEN_KEY
} from '../../utils/constants/constants'
import { AppContext } from '../../utils/context/AppContext'
import CapabilitiesHelper from '../../utils/helper/CapabilitiesHelper'
import useRedirect from '../../utils/hooks/useRedirect'
import useTranslate from '../../utils/hooks/useTranslate'
import { PAGES } from '../../utils/constants/pages'
import ScreensHelp from '../helpModal/ScreensHelp'
import ScreenPreview from '../screenPreview/ScreenPreview'

const ScreenSelectTile = ({ assessment, type }) => {
  const context = useContext(AppContext)
  const { customScreens } = context.completeDataSet
  const redirect = useRedirect()
  const t = useTranslate()

  const canEdit = CapabilitiesHelper.canEditAssessment(assessment)

  const startScreenOptions = getStartScreenOptions(customScreens)
  const endScreenOptions = getEndScreenOptions(customScreens)

  const map = {
    start: {
      label: FIELD_LABELS.startScreen,
      dataKey: START_SCREEN_KEY,
      options: startScreenOptions,
      currentNr: assessment.screens.screenNrStart || DEFAULT_SCREEN_NR_START
    },
    end: {
      label: FIELD_LABELS.endScreen,
      dataKey: END_SCREEN_KEY,
      options: endScreenOptions,
      currentNr: assessment.screens.screenNrEnd || DEFAULT_SCREEN_NR_END
    }
  }

  map.start.currentScreen = customScreens.find((cs) => cs.screenNr === map.start.currentNr)
  map.end.currentScreen = customScreens.find((cs) => cs.screenNr === map.end.currentNr)

  return (
    <Tile maxWidth="400px">
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <TileHeadline content={map[type].label} />
      </div>
      <CustomInputList style={{ marginTop: 0 }}>
        <CustomInput
          label={map[type].label}
          content={getOptionByValue(map[type].options, map[type].currentNr).label || ERROR_MSG}
          value={map[type].currentNr}
          dataKey={map[type].dataKey}
          type="select"
          options={map[type].options}
          apiData={API_DATA.assessments}
          idValue={assessment.assessmentUuid}
          disabled={!canEdit}
          assessment={assessment}
          ScreenPreview={ScreenPreview}
          helpContent={<ScreensHelp />}
          isScreen
        />
      </CustomInputList>
      {context.loggedInAsAdmin && (
        <EditorLink>
          {t('changeScreensInSettings', () => redirect(PAGES.customization, [{ id: 'tab', value: 'customScreens' }]))}
        </EditorLink>
      )}
    </Tile>
  )
}

export default ScreenSelectTile

export const EditorLink = styled.div`
  margin-top: var(--space-3);
  font-size: var(--fs-2);
  color: var(--text-color-secondary);
  line-height: var(--lh-2);
`

export const LabelIconContainer = styled.div`
  display: flex;
  justify-content: space-between;
  button {
    background-color: transparent;
    padding: 0;
    border-radius: 2px;
    z-index: 9;
  }
  .icon-pen {
    transform: scale(0.8);
  }
  .textlink {
    font-size: var(--fs-1);
    border: none;
    line-height: var(--lh-2);
    background-color: transparent;
    padding: 0;
    z-index: 9;
    border-radius: var(--bdr-1);
  }
`

export const getStartScreenOptions = (customScreens) => [
  { label: PERSEO_DEFAULT, value: DEFAULT_SCREEN_NR_START },
  ...customScreens.filter((cs) => cs.type === SCREEN_TYPE_START).map((cs) => ({ label: cs.name, value: cs.screenNr }))
]

export const getEndScreenOptions = (customScreens) => [
  { label: PERSEO_DEFAULT, value: DEFAULT_SCREEN_NR_END },
  ...customScreens.filter((cs) => cs.type === SCREEN_TYPE_END).map((cs) => ({ label: cs.name, value: cs.screenNr }))
]
