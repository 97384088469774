import { setSlowCSSTransition } from '../../components/settings/DisplayAndLanguageComponent'
import { Assessment } from '../../entities/Assessment'
import { TestConfig } from '../../entities/TestConfig'
import { Contact } from '../../entities/Contact'
import { CreditBooking } from '../../entities/CreditBooking'
import { Invoice } from '../../entities/Invoice'
import { Participant } from '../../entities/Participant'
import { Process } from '../../entities/Process'
import { PT } from '../../entities/Pt'
import { Result } from '../../entities/Result'
import { API_DATA, DEFAULT_DISPLAY_MODE, DEFAULT_LANGUAGE } from '../constants/constants'
import { fetchData, translateNotifications } from './Helper'
import { Screen } from '../../entities/Screen'
import { ParentConfig } from '../../entities/ParentConfig'
import { Reminder } from '../../entities/Reminder'
import { ParticipantMailLog } from '../../entities/ParticipantMailLog'
import { PtInvitationTemplate } from '../../entities/PtInvitationTemplate'

export const loadInitialData = async (context) => {
  loadContactSettings(context)

  const data = await fetchData({}, 'data', context, 'dataLoadError', false)

  try {
    setLoggedInContact(data.contacts, context.setLoggedInAsAdmin)
    return data
  } catch (e) {
    console.error(e)
  }
}

const setLoggedInContact = (contacts, setLoggedInAsAdmin) => {
  const contact = contacts.find((contact) => contact.loggedInContact)
  setLoggedInAsAdmin(contact.contactIsAdmin)
}

export const addRemainingData = async (allData, context, setIsLoading, setProgress, setLoadingMessage) => {
  const promises = getPromises(allData.assessment_uuids.visible, context, setLoadingMessage, setProgress)

  Promise.all(promises)
    .then((values) => {
      let tmpParticipants = []
      values
        .filter((value) => value)
        .forEach((value) => {
          allData.assessments.push(value.assessment)
          allData.usedConfigs.push(value.usedConfig)
          allData.pts = allData.pts.concat(value.pts)
          allData.results = allData.results.concat(value.results)
          tmpParticipants = tmpParticipants.concat(value.participants)
        })

      const uniqueParticipants = [...new Map(tmpParticipants.map((p) => [p.pNr, p])).values()]
      allData.participants = uniqueParticipants

      translateNotifications(allData.notifications, context.language)

      allData.processes = allData.processes.map((process) => new Process(process))
      allData.assessments = allData.assessments.map((assessment) => new Assessment(assessment))
      allData.participants = allData.participants.map((participant) => new Participant(participant))
      allData.pts = allData.pts.map((pt) => new PT(pt))
      allData.contacts = allData.contacts.map((pt) => new Contact(pt))
      allData.results = allData.results.map((result) => new Result(result))
      allData.usedConfigs = allData.usedConfigs.map((config) => new TestConfig(config))
      allData.creditBookings = allData.creditBookings.map((booking) => new CreditBooking(booking))
      allData.invoices = allData.invoices.map((invoice) => new Invoice(invoice))
      allData.customScreens = allData.customScreens.map((screen) => new Screen(screen))
      allData.reminders = allData.reminders.map((reminder) => new Reminder(reminder))
      allData.participantMailLogs = allData.participantMailLogs.map((pml) => new ParticipantMailLog(pml))
      allData.ptInvitationTemplates = allData.ptInvitationTemplates.map((tmpl) => new PtInvitationTemplate(tmpl))

      allData.parentConfigs = allData.parentConfigs
        .filter((pc) => pc.availableConfigs)
        .map((pc) => new ParentConfig(pc))

      allData.processes.forEach((process) => process.addRelatedElements(allData))
      allData.assessments.forEach((ass) => ass.addRelatedElements(allData))
      allData.participants.forEach((p) => p.addRelatedElements(allData))
      allData.pts.forEach((pt) => pt.addRelatedElements(allData))
      allData.parentConfigs.forEach((pc) => pc.translate(context.language))
      allData.participantMailLogs.forEach((pml) => pml.addRelatedElements(allData))

      context.setCompleteDataSet(allData)
      setIsLoading(false)
    })
    .catch((e) => {
      console.error(e)
    })
}

export const getPromises = (assessmentUuids, context, setLoadingMessage, setProgress, loadArchivedData = false) => {
  let count = 0

  return assessmentUuids.map(async (uuid) => {
    const body = {
      assessmentUuid: uuid,
      archived: loadArchivedData
    }

    const data = await fetchData(body, 'load_data', context, 'notAllDataLoaded', false)

    try {
      count++
      if (count === assessmentUuids.length - 1) {
        if (setLoadingMessage) {
          setLoadingMessage('processingData')
        }
      }
      if (setProgress) {
        setProgress(count / assessmentUuids.length)
      }
      return data
    } catch (e) {
      console.error(e)
    }
  })
}

const loadContactSettings = async (context) => {
  const settings = await fetchData({}, API_DATA.contactSettings.endPointGet, context, 'notAllDataLoaded', false)

  try {
    setSlowCSSTransition()
    context.setDisplayMode(settings.displayMode || DEFAULT_DISPLAY_MODE)
    context.setLanguage(settings.language || DEFAULT_LANGUAGE)
  } catch (e) {
    console.error(e)
  }
}
