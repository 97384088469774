import { useContext } from 'react'
import useTranslate from '../../utils/hooks/useTranslate'
import useRedirect from '../../utils/hooks/useRedirect'
import { getFormattedNumber } from '../../utils/helper/Helper'
import styled from 'styled-components'
import GradeLabel from '../gradeLabel/GradeLabel'
import { AppContext } from '../../utils/context/AppContext'
import { PAGES } from '../../utils/constants/pages'

const NormsTable = ({ norm }) => {
  const t = useTranslate()
  const context = useContext(AppContext)
  const redirect = useRedirect()
  const { language, loggedInAsAdmin } = context

  return (
    <div>
      <div className="table-outer-container norm-table">
        <div className="table-inner-container">
          <table style={{ width: '100%' }}>
            <thead>
              <tr>
                <th>{t('from')}</th>
                <th>{t('upTo')}</th>
                <th>
                  <GradeLabel label={norm.gradeLabel} />
                </th>
              </tr>
            </thead>
            <tbody>
              {norm.normTable.map((row, i) => (
                <tr key={row.grade + i}>
                  <td className="closed">{row.lower_limit}</td>
                  <td className="closed">{Math.min(row.upper_limit, 100)}</td>
                  <td className="closed">{getFormattedNumber(row.grade, language)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {loggedInAsAdmin && (
        <EditorLink>
          {t('changeGradingInSettings', () => redirect(PAGES.customization, [{ id: 'tab', value: 'gradingRules' }]))}
        </EditorLink>
      )}
    </div>
  )
}

export default NormsTable

export const EditorLink = styled.div`
  margin-top: var(--space-3);
  font-size: var(--fs-2);
  color: var(--text-color-secondary);
  line-height: var(--lh-2);
`
