import { useContext } from 'react'
import ScrollContainer from '../../../components/scrollContainer/ScrollContainer'
import DataTable from '../../../components/table/DataTable'
import { TileContainer, TileHeadline, Tile } from '../../../components/table/tableElements'
import { EMAIL_FROM_ADDRESS, EMAIL_FROM_NAME } from '../../../utils/constants/constants'
import { AppContext } from '../../../utils/context/AppContext'
import { InfoList, InfoElement, PageHeadline } from '../../../utils/elements/miscElements'
import { someParticipantsUsingTimer } from '../../../utils/helper/Helper'
import useTranslate from '../../../utils/hooks/useTranslate'
import { getColumns } from '../sendEmailUtils'
import {
  getAssessmentNameCol,
  getAssessmentProcessNameCol,
  getSpacerCol
} from '../../../components/table/tableCols/tableCols'
import { getReminderRule } from '../../editReminder/reminderUtils'

const CheckInput = ({ data }) => {
  const t = useTranslate()
  return (
    <>
      <PageHeadline>{t('checkInputs')}</PageHeadline>
      <ScrollContainer>
        <TileContainer>
          <BaseInfos {...{ data }} />
          {data.isReminder ? (
            <SelectedTests assessmentUuids={data.assessmentUuids} />
          ) : (
            <ParticipantOverview participants={data.participants} />
          )}
        </TileContainer>
      </ScrollContainer>
    </>
  )
}

export default CheckInput

const BaseInfos = ({ data }) => {
  const t = useTranslate()
  const context = useContext(AppContext)
  const senderName = EMAIL_FROM_NAME[context.language]
  const senderPreview = `${senderName} <${EMAIL_FROM_ADDRESS}>`
  const replyToPreview = `${data.replyToName || senderName} <${data.replyToEmail || EMAIL_FROM_ADDRESS}>`
  const usingTimer = someParticipantsUsingTimer(data.participants)
  return (
    <Tile maxWidth="250px">
      <TileHeadline content="baseInfos" />
      <InfoList>
        {!data.isReminder && (
          <InfoElement label="emailType" value={data.emailType === 'invitation' ? t('invitation') : t('reminder')} />
        )}
        <InfoElement label="sender" value={senderPreview} />
        <InfoElement label="replyTo" value={replyToPreview} />
        <InfoElement label="emailSubject" value={data.subject} />
        {!data.isReminder && <InfoElement label="numberOfEmails" value={data.participants.length} />}
        {usingTimer && !data.isReminder && (
          <InfoElement label="startTimerAfterSending" value={data.startTimerOnSend ? t('yes') : t('no')} />
        )}
        {data.isReminder && (
          <>
            <InfoElement label="title" value={data.title} />
            <InfoElement label="sendTime" value={getReminderRule(data.days, data.triggerType, t)} />
            <InfoElement label="activateReminder" value={data.active ? 'yes' : 'no'} />
          </>
        )}
      </InfoList>
    </Tile>
  )
}

const ParticipantOverview = ({ participants }) => {
  const columns = getColumns()
  columns.push(getSpacerCol())

  return (
    <Tile>
      <TileHeadline content="participantsReceiveEmail" />
      <DataTable data={participants} columns={columns} hideResultCount hideArchiveButton tableName="email-preview" />
    </Tile>
  )
}

const SelectedTests = ({ assessmentUuids }) => {
  const { completeDataSet } = useContext(AppContext)
  const assessments = completeDataSet.assessments.filter((ass) => assessmentUuids.includes(ass.assessmentUuid))
  const columns = [getAssessmentNameCol(), getAssessmentProcessNameCol(true)]
  return (
    <Tile>
      <TileHeadline content="selectedTests" />
      <DataTable data={assessments} columns={columns} hideResultCount hideArchiveButton tableName="email-preview" />
    </Tile>
  )
}
