import { BOOKING_KEYS } from '../utils/constants/constants'
import { createDate } from '../utils/helper/dateTimeHelper'
import { MainEntity } from './MainEntity'

export class CreditBooking extends MainEntity {
  constructor({ ...props }) {
    super()
    this.entity = 'CreditBooking'
    this.bookingKey = props.bookingKey
    this.bookingKeyDescription = getBookingKeyDescription(this.bookingKey)
    this.bookingNr = props.bookingNr
    this.comment = props.comment
    this.created = createDate(props.created)
    this.creditsValue = props.creditsValue
    this.invoiceNo = props.invoiceNo
    this.pNr = props.pNr
    this.ptNumber = props.ptNumber
  }
  addRelatedElements(completeDataSet) {
    const { assessments } = completeDataSet
    this.relatedAssessment = assessments.find((ass) => ass.relatedPts.find((pt) => pt.ptNumber === this.ptNumber)) || {}
    this.relatedProcess = this.relatedAssessment?.relatedProcess || {}
    this.relatedPt = this.relatedAssessment?.relatedPts?.find((pt) => pt.ptNumber === this.ptNumber) || {}
    this.relatedParticipant = this.relatedPt?.relatedParticipant || {}
  }
}

const getBookingKeyDescription = (key) => {
  switch (key) {
    case BOOKING_KEYS.pt_finished:
      return 'ptUsed'
    case BOOKING_KEYS.pt_reset:
      return 'ptReset'
    case BOOKING_KEYS.credit_charge_perseo:
      return 'creditChargePerseo'
    case BOOKING_KEYS.credit_charge_contact:
      return 'creditChargeContact'
    case BOOKING_KEYS.credit_charge_monthly:
      return 'creditChargeMonthly'
    case BOOKING_KEYS.misc:
    default:
      return 'miscBooking'
  }
}
