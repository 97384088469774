import RowActions from '../components/table/RowActions'
import { ENTITIES } from '../utils/constants/constants'
import { createDate } from '../utils/helper/dateTimeHelper'
import { MainEntity } from './MainEntity'

export class ParticipantMailLog extends MainEntity {
  constructor({ ...props }) {
    super()
    this.entity = 'ParticipantMailLog'
    this.email_nr = props.email_nr
    this.emailType = props.emailType === 1 ? 'invitation' : 'reminder'
    this.mailAddress = props.mailAddress
    this.created = createDate(props.created)
    this.createdBy = props.createdBy
    this.pNr = props.pNr
    this.ptNrs = props.ptNrs
    this.subject = props.mailData.subject
    this.visibility = props.visibility
    this.relatedParticipant = {}
    this.relatedPts = []
    this.replyToEmail = props.mailData.replyToEmail
    this.replyToName = props.mailData.replyToName
    this.topText = props.mailData.topText
    this.bottomText = props.mailData.bottomText
    this.actions = <RowActions row={this} entity={ENTITIES.participantMailLogs} />

    this.payloadFormat = {
      emailType: this.emailType,
      subject: this.subject,
      replyToEmail: this.replyToEmail,
      replyToName: this.replyToName,
      topText: this.topText,
      bottomText: this.bottomText,
      formattedEmailData: [
        {
          pNr: this.pNr,
          ptList: this.ptNrs,
          salutation: ''
        }
      ]
    }
  }
  addRelatedElements(completeDataSet) {
    const { participants, pts } = completeDataSet
    this.relatedParticipant = participants.find((p) => p.pNr === this.pNr)
    this.relatedPts = pts
      .filter((pt) => this.ptNrs.includes(pt.ptNumber))
      .map((pt) => ({
        ...pt,
        selected: true
      }))
    this.assessmentNames = this.relatedPts.map((pt) => pt.relatedAssessment.assessmentName)
  }
}
