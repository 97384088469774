import React from 'react'
import DateDisplay from '../../components/dateDisplay/DateDisplay'
import PtListForTable from '../../components/ptListForTable/PtListForTable'
import { getParticipantNumberCol } from '../../pages/dashboard/dashboardUtils'
import {
  API_DATA,
  DEFAULT_TEMPLATE_NR,
  EMAIL_FROM_ADDRESS,
  EMAIL_FROM_NAME,
  EMAIL_TEXTS,
  ENTITIES,
  FIELD_LABELS,
  MODAL_TYPES,
  SALUTATION_TYPES,
  STATUS_BAR_TYPES
} from '../../utils/constants/constants'
import { ButtonPrimary } from '../../utils/elements/miscElements'
import { fetchData, getEntityLabel, setState, setStatusBar, updateEntities } from '../../utils/helper/Helper'
import { getParamsJson } from '../../utils/helper/urlParamsHelper'
import EmailConfirmationModalContent from './subComponents/EmailConfirmationModalContent'
import { getSalutation } from './subComponents/SalutationSelection'
import { PtInvitationTemplate } from '../../entities/PtInvitationTemplate'

export const getParticipantsFromUrlParams = (location, participants) => {
  const urlParams = getParamsJson(location)
  const ptNumbers = urlParams.find((param) => param.id === 'ptNumber')?.value || []
  const filteredParticipants = participants.filter((participant) =>
    participant.ptList.find((pt) => ptNumbers.includes(pt.ptNumber))
  )

  filteredParticipants.forEach((p) => {
    p.ptList.forEach((pt) => {
      pt.selected = false
      if (ptNumbers.includes(pt.ptNumber)) pt.selected = true
    })
  })

  return filteredParticipants
}

export const getColumns = () => [
  getParticipantNumberCol(),
  {
    Header: FIELD_LABELS.email,
    accessor: 'pMail',
    Cell: ({ value }) => <span style={{ wordWrap: 'break-word' }}>{value}</span>
  },
  {
    Header: 'Tests',
    id: 'assessmentName',
    show: true,
    accessor: (row) => row.ptList.map((pt) => pt.relatedAssessment.assessmentName),
    Cell: ({ row }) => <PtListForTable ptList={getSelectedPts(row.original.ptList)} />
  }
]

export const handleEmailModal = (
  context,
  data,
  setActivePageId,
  activePageId,
  setResponse,
  submitButtonClicked,
  setSubmitButtonClicked,
  t
) => {
  const count = data.participants.length
  const emailLabel = getEntityLabel(ENTITIES.emails, count)
  const showLoadingMessage = submitButtonClicked

  context.setModalProps({
    headline: 'sendMails',
    content: <EmailConfirmationModalContent {...{ count, emailLabel, showLoadingMessage }} />,
    buttonPrimary: (
      <ButtonPrimary
        disabled={submitButtonClicked}
        content="send"
        modalButton
        onClick={async () => {
          setSubmitButtonClicked(true)
          const responseData = await sendEmails({ context: context, data: [data], t: t })
          context.setModalOpen(false)
          try {
            const updates = {
              pts: responseData.response.data.pts,
              participantMailLogs: responseData.response.data.participantMailLogs
            }
            updateEntities(updates, context)
            setResponse(responseData.response)
            setActivePageId(activePageId + 1)
          } catch (e) {
            console.error(e)
            setSubmitButtonClicked(false)
          }
        }}
      />
    ),
    icon: 'icon-email modal',
    type: MODAL_TYPES.alert
  })
  context.setModalOpen(true)
}

export const sendEmails = async ({ context, data, dummyMailAddress = '', t }) => {
  const payload = {
    emails: data.map((d) => ({
      emailType: d.emailType,
      subject: getEmailSubject(d.subject, dummyMailAddress) || '',
      participants: d.formattedEmailData || formatEmailData(d, dummyMailAddress, t),
      dummyMailAddress: dummyMailAddress,
      startTimerOnSend: d.startTimerOnSend,
      topText: d.topText || '',
      bottomText: d.bottomText || '',
      replyToName: d.replyToName || EMAIL_FROM_NAME[context.language],
      replyToEmail: d.replyToEmail || EMAIL_FROM_ADDRESS,
      language: context.language
    }))
  }
  const responseData = await fetchData(payload, API_DATA.sendEmails, context, 'emailsNotSent', false)

  try {
    if (responseData && dummyMailAddress) {
      setStatusBar({
        controller: context.statusBarController,
        type: STATUS_BAR_TYPES.success,
        text: ['testEmailSent', dummyMailAddress],
        setVisible: true
      })
    }
    return responseData
  } catch (e) {
    console.error(e)
  }
}

const getEmailSubject = (subject, isDummy) => {
  if (isDummy) {
    return '[TEST] ' + subject
  }
  return subject
}

const formatEmailData = (data, dummyMailAddress, t) => {
  const isDummy = dummyMailAddress
  const emailData = data.participants.map((participant) => {
    const selectedPts = getSelectedPts(participant.ptList)
    const ptNumbers = selectedPts.map((pt) => pt.ptNumber)
    return {
      pNr: participant.pNr,
      ptList: ptNumbers,
      salutation: getSalutation(participant, data.salutationType, t)
    }
  })
  if (isDummy) {
    const previewData = emailData.find((p) => p.pNr === data.previewParticipantNr)
    return [previewData]
  }
  return emailData
}

export const getPreviewData = (data) => {
  if (data.participants.length === 0) return []
  const previewParticipant = data.participants.find((p) => p.pNr === data.previewParticipantNr)
  const selectedPts = getSelectedPts(previewParticipant.ptList)
  const previewData = selectedPts.map((pt) => {
    const obj = {
      title: pt.relatedAssessment.assessmentName,
      ptNumber: pt.ptNumber,
      validFrom: pt.ptValidFrom ? <DateDisplay date={pt.ptValidFrom} format="dateAndTimeString" /> : null,
      validUntil: pt.ptValidUntil ? <DateDisplay date={pt.ptValidUntil} format="dateAndTimeString" /> : null,
      token: pt.referenceToken,
      requiredMaterials: pt.relatedAssessment.getRequiredMaterials()
    }
    return obj
  })

  return previewData
}

export const getSelectedPts = (ptList) => {
  if (!Array.isArray(ptList || !ptList)) return []
  if (ptList.some((pt) => pt.selected)) {
    return ptList.filter((pt) => pt.selected)
  }
  return ptList
}

export const getDefaultNewTitle = (currentTitle, saveMethod, t) => {
  if (saveMethod === 'edit') {
    return currentTitle
  }
  return `${currentTitle} ${t('copy')}`
}

export const setEmailSubject = (e, setData) => {
  setState(setData, 'subject', e.target.value)
  setState(setData, 'isDirty', true)
}

export const getDefaultTemplates = (customerName, t) => {
  return [
    new PtInvitationTemplate({
      publicKey: DEFAULT_TEMPLATE_NR,
      title: t('defaultInvitation'),
      createdBy: 'PERSEO',
      disabled: true,
      templateData: {
        salutationType: SALUTATION_TYPES.politeLastName,
        subject: t(EMAIL_TEXTS.invitationSubject),
        topText: t('defaultTopTextInvitation'),
        bottomText: t('defaultBottomText', customerName)
      }
    }),
    new PtInvitationTemplate({
      publicKey: 'default_invitation_casual',
      title: t('defaultInvitationCasual'),
      createdBy: 'PERSEO',
      disabled: true,
      templateData: {
        salutationType: SALUTATION_TYPES.casualFirstName,
        subject: t(EMAIL_TEXTS.invitationSubject),
        topText: t('defaultTopTextInvitationCasual'),
        bottomText: t('defaultBottomTextCasual', customerName)
      }
    }),
    new PtInvitationTemplate({
      publicKey: 'default_reminder',
      title: t('defaultReminder'),
      createdBy: 'PERSEO',
      disabled: true,
      templateData: {
        salutationType: SALUTATION_TYPES.politeLastName,
        subject: t(EMAIL_TEXTS.reminderSubject),
        topText: t('defaultTopTextReminder'),
        bottomText: t('defaultBottomText', customerName)
      }
    }),
    new PtInvitationTemplate({
      publicKey: 'default_reminder_casual',
      title: t('defaultReminderCasual'),
      createdBy: 'PERSEO',
      disabled: true,
      templateData: {
        salutationType: SALUTATION_TYPES.casualFirstName,
        subject: t(EMAIL_TEXTS.reminderSubjectCasual),
        topText: t('defaultTopTextReminderCasual'),
        bottomText: t('defaultBottomTextCasual', customerName)
      }
    })
  ]
}

export const detectEmailType = (data) => {
  if (data.templateKey === 'default_reminder') return 'reminder'
  if (data.templateKey === DEFAULT_TEMPLATE_NR) return 'invitation'

  const strings = [data.subject.toLowerCase(), data.topText.toLowerCase()]
  if (strings.some((string) => string.includes('erinner') || string.includes('remind'))) {
    return 'reminder'
  }
  return 'invitation'
}
