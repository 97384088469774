import RowActions from '../components/table/RowActions'
import { ANONYM_MSG, ENTITIES } from '../utils/constants/constants'
import { createDate, getLatestDateFromArray } from '../utils/helper/dateTimeHelper'
import { MainEntity } from './MainEntity'

export class Participant extends MainEntity {
  constructor({ ...props }) {
    super()
    this.entity = 'Participant'
    this.created = createDate(props.created)
    this.createdBy = props.createdBy
    this.customFields = props.customFields
    this.pAnon = props.pAnon
    this.pFirstName = props.pFirstName
    this.pLastName = props.pLastName
    this.pFullName = getParticipantFullName(this)
    this.nameLabel = getNameLabel(this.pFullName)
    this.pGender = props.pGender
    this.pMail = props.pMail
    this.pNr = props.pNr
    this.pnrHash = props.pnrHash
    this.ptList = []
    this.relatedProcessResults = []
    this.visibility = props.visibility
    this.lastMailDate = null
    this.actions = <RowActions row={this} entity={ENTITIES.participants} />
  }
  addRelatedElements(completeDataSet) {
    const { pts, processResults, participantMailLogs } = completeDataSet
    this.ptList = pts.filter((pt) => pt.pNr === this.pNr)
    this.relatedProcessResults = processResults.filter((processResult) => processResult.participantNr === this.pNr)

    const mailSendDates = participantMailLogs.filter((pml) => pml.pNr === this.pNr).map((pml) => pml.created)
    this.lastMailDate = getLatestDateFromArray(mailSendDates)
  }
}

const getParticipantFullName = (participant) => {
  if (participant.pAnon) return 'ANONYM'
  if (!participant.pLastName && !participant.pFirstName) return ''
  return (participant.pLastName || '') + ', ' + (participant.pFirstName || '')
}

export const getNameLabel = (pFullName) => {
  switch (pFullName) {
    case 'ANONYM':
      return ANONYM_MSG
    case '':
      return 'noNameYet'
    default:
      return pFullName
  }
}
