import { useContext } from 'react'
import { AppContext } from '../../utils/context/AppContext'
import useTranslate from '../../utils/hooks/useTranslate'

const NormsHelp = () => {
  const context = useContext(AppContext)
  const lang = context.language
  if (lang === 'en') {
    return <NormsHelpEnglish />
  } else {
    return <NormsHelpGerman />
  }
}

export default NormsHelp

const NormsHelpGerman = () => {
  const t = useTranslate()
  return (
    <div>
      <p>
        Zur besseren Lesbarkeit werden die Testergebnisse in Schulnoten von 1 (sehr gut) bis 5 (mangelhaft) umgerechnet.
        Unter{' '}
        <em>
          Menü {'>'} Einstellungen {'>'} Personalisierung {'>'} {t('gradingRules')}
        </em>{' '}
        können Sie die standardmäßig genutzten Benotungsregeln an Ihre Anforderungen anpassen und als eigene Regeln
        speichern.
      </p>
      <p>Die folgenden Anpassungen sind möglich:</p>
      <ul>
        <li>Verschieben der Punktgrenzen</li>
        <li>Verändern des Notensystems (z.B. Nutzung von Nachkommastellen oder eines 15er-Notenschemas)</li>
        <li>Verändern des Empfehlungstextes, der auf dem PDF-Report erscheint</li>
      </ul>
      <p>
        Um eine Benotungsregel anzuwenden, müssen Sie sie in der Test-Detailansicht unter{' '}
        <em>
          {t('scoring')} {'>'} {t('grading')}
        </em>{' '}
        auswählen.
      </p>

      <div className="textbox important">
        <p>
          Bitte beachten Sie, dass zugeordnete Testergebnisse neu berechnet werden, wenn die Benotungsregel geändert
          wurde.
        </p>
      </div>
      <p>
        Wenn Sie die Benotungsregel ändern, werden die Rohdaten der Teilnehmer nicht verändert. Sie können die
        Benotungsregel daher gefahrlos ändern und jederzeit zur voreingestellten Benotungsregel zurückkehren.
      </p>
    </div>
  )
}

const NormsHelpEnglish = () => {
  const t = useTranslate()
  return (
    <div>
      <p>
        For better readability, the test results are converted into German school grades ranging from 1 (very good) to 5
        (poor). Under{' '}
        <em>
          Menu {'>'} Settings {'>'} Customization {'>'} {t('gradingRules')}
        </em>{' '}
        you can adjust the default grading rules to your requirements and save it as custom rules.
      </p>
      <p>The following adjustments are possible:</p>
      <ul>
        <li>Shifting the score limits</li>
        <li>Changing the grading system (e.g., using decimal points)</li>
        <li>Modifying the recommendation text that appears on the PDF report</li>
      </ul>
      <p>
        To apply a grading rule, you need to select it in the test detail view under{' '}
        <em>
          {t('scoring')} {'>'} {t('grading')}
        </em>
        .
      </p>

      <div className="textbox important">
        <p>Please note that related test results will be recalculated if the grading rule is changed.</p>
      </div>
      <p>
        Changing the grading rule will not affect the raw data of the participants. Therefore, you can safely modify the
        rule and revert to the default rule at any time.
      </p>
    </div>
  )
}
