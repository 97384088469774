import { useContext } from 'react'
import { DEFAULT_SCREEN_NR_END, DEFAULT_SCREEN_NR_START, LOCALES } from '../../utils/constants/constants'
import { AppContext } from '../../utils/context/AppContext'
import {
  ElektryonContentContainer,
  ElektryonContentWrapper,
  ElektryonH1,
  ElektryonWrapper,
  Separator
} from '../editor/screenEditor/ScreenEditor'

const ScreenPreview = ({ screenNr, assessment }) => {
  const { completeDataSet } = useContext(AppContext)
  const customScreens = completeDataSet.customScreens
  const screen = getScreen(screenNr, customScreens, assessment)
  const locale = LOCALES[assessment.languageIds[0]]

  return (
    <ElektryonWrapper className="smaller" style={{ overflow: 'scroll', height: '300px' }}>
      {screen && (
        <ElektryonContentWrapper>
          <ElektryonContentContainer style={{ padding: 'var(--space-6) 0' }}>
            <ElektryonH1 disabled value={screen.content?.[locale]?.headline || ''} />
            <Separator />
            <div dangerouslySetInnerHTML={{ __html: screen.content?.[locale]?.body || '' }} />
          </ElektryonContentContainer>
        </ElektryonContentWrapper>
      )}
    </ElektryonWrapper>
  )
}

export default ScreenPreview

const getScreen = (screenNr, customScreens, assessment) => {
  const customScreen = customScreens.find((cs) => cs.screenNr === screenNr)
  if (customScreen) {
    return customScreen
  }
  if (screenNr === DEFAULT_SCREEN_NR_START) {
    return assessment.screens.defaultStartScreen
  }
  if (screenNr === DEFAULT_SCREEN_NR_END) {
    return assessment.screens.defaultEndScreen
  }
}
