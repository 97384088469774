import { CUSTOMER_VISIBILITY_STATUS } from '../utils/constants/constants'
import { createDate } from '../utils/helper/dateTimeHelper'

export class PtInvitationTemplate {
  constructor({ ...props }) {
    this.entity = 'PtInvitationTemplate'
    this.publicKey = props.publicKey
    this.name = props.title || ''
    this.templateData = props.templateData
    this.created = createDate(props.created)
    this.createdBy = props.createdBy || ''
    this.disabled = props.disabled || false
    this.visibility = props.visibility || CUSTOMER_VISIBILITY_STATUS.visible
  }
}
