import { useContext } from 'react'
import {
  CONTENT_TYPES,
  FIELD_CATEGORIES,
  FIELD_LABELS,
  FILTER_TYPES,
  PT_STATUS_NAMES
} from '../../../utils/constants/constants'
import { CopyIcon } from '../../../utils/elements/miscElements'
import { getHubLink, getPtCount, getPtLink, getTableLinkParams } from '../../../utils/helper/Helper'
import useRedirect from '../../../utils/hooks/useRedirect'
import Arrow from '../../arrow/Arrow'
import DateDisplay from '../../dateDisplay/DateDisplay'
import { EntityCountDisplay, SmallDescription, TableContentContainer, TableLink } from '../tableElements'
import { FilterContext } from '../../../utils/context/FilterContext'
import { PAGES } from '../../../utils/constants/pages'
import ProcessListForTable from '../../processListForTable/ProcessListForTable'
import PtListForTable from '../../ptListForTable/PtListForTable'

export const getHubLinkCol = (options) => ({
  Header: 'linkToTestOverview',
  accessor: (row) => row.referenceToken,
  id: 'hubLink',
  canExport: true,
  show: !options?.isHidden,
  disableSortBy: options?.disableSortBy,
  Cell: ({ row }) => {
    const hubLink = getHubLink(row.original.pnrHash)
    return (
      <TableContentContainer className="has-fading-edge" style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
        <div style={{ width: '100px', overflow: 'scroll' }}>{hubLink.replace('https://', '')}</div>
        <CopyIcon content={hubLink} elementLabel="Link" position="table" />
      </TableContentContainer>
    )
  }
})

export const toggleCol = {
  Header: '',
  accessor: 'detailsToggle',
  id: 'expander',
  className: 'details-toggle-col',
  disableSortBy: true,
  show: true,
  Cell: ({ row }) => (
    <button className="expander-container" style={{ background: 'none', padding: 0, borderRadius: 'var(--bdr-1)' }}>
      {row.isExpanded ? <Arrow direction="up" /> : <Arrow direction="down" />}
    </button>
  )
}

export const getPtLinkCol = () => ({
  Header: 'testLink',
  accessor: (row) => row.referenceToken,
  id: 'ptLink',
  canExport: true,
  show: 'true',
  Cell: ({ row }) => {
    const ptLink = getPtLink(row.original.referenceToken)
    return (
      <TableContentContainer className="has-fading-edge" style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
        <div style={{ width: '100px', overflow: 'scroll' }}>{ptLink.replace('https://', '')}</div>
        <CopyIcon content={ptLink} elementLabel="Link" position="table" />
      </TableContentContainer>
    )
  }
})

export const getVisibilityCol = (category) => ({
  Header: 'Visibility',
  accessor: 'visibility',
  id: 'visibility',
  alwaysHidden: true,
  filterOptions: {
    showInFieldSelector: true,
    contentType: CONTENT_TYPES.text,
    category: category,
    label: 'archived',
    dataLabels: {
      1: 'no',
      2: 'yes'
    }
  }
})

export const getActionsCol = () => ({
  Header: 'actions',
  accessor: 'actions',
  className: 'actions-col',
  id: 'actions',
  show: true,
  disableSortBy: true,
  Cell: ({ value }) => <div style={{ display: 'flex', justifyContent: 'center' }}>{value}</div>
})

export const getSpacerCol = () => ({
  Header: '',
  width: '0',
  id: 'spacer',
  className: 'spacer-col',
  show: true
})

export const getDateCreatedCol = (id = '', category, header = 'dateCreated') => ({
  Header: header,
  accessor: (row) => row.created,
  id: id,
  show: true,
  canExport: true,
  Cell: ({ value }) => (
    <TableContentContainer>
      <DateDisplay date={value} format={'dateAndTime'} />
    </TableContentContainer>
  ),
  filterOptions: {
    showInFieldSelector: true,
    category: category,
    contentType: CONTENT_TYPES.date,
    excludeFutureDates: true
  }
})

export const getCreatedByCol = (id = '', category, header = 'createdBy') => ({
  Header: header,
  id: id,
  canExport: true,
  show: true,
  accessor: (row) => row.createdBy,
  filterOptions: { showInFieldSelector: true, category: category }
})

export const getAssessmentFreeTextFilterCol = () => ({
  Header: 'freeTextFilter',
  id: 'freeText',
  accessor: (row) => row.assessmentName + row.relatedProcess.processName,
  filterOptions: { type: FILTER_TYPES.textInput },
  canExport: false,
  show: false
})

export const getAssessmentNameCol = () => ({
  Header: 'Test',
  accessor: 'assessmentName',
  id: 'assessmentName',
  canExport: true,
  filterOptions: { showInFieldSelector: true, category: FIELD_CATEGORIES.test, label: 'testName' },
  Cell: ({ row }) => {
    return (
      <TableContentContainer>
        <span className={row.original.isArchived() ? 'archived' : ''}>{row.original.assessmentName}</span>
        {row.original.assessmentDescription && (
          <SmallDescription>{row.original.assessmentDescription}</SmallDescription>
        )}
      </TableContentContainer>
    )
  }
})

export const getAssessmentProcessNameCol = (disableLink = false) => ({
  Header: 'processes',
  id: 'processName',
  canExport: true,
  filterOptions: { showInFieldSelector: true, category: FIELD_CATEGORIES.process, label: 'processName' },
  show: true,
  accessor: (row) => row.relatedProcess.processName,
  Cell: ({ row }) => {
    const { addLinkFilters } = useContext(FilterContext)
    const process = row.original.relatedProcess
    const tableLinkParams = getTableLinkParams(process, 'processName', 'processes')
    const redirect = useRedirect()

    if (disableLink) {
      return <>{process.processName}</>
    }

    return (
      <TableLink
        className={tableLinkParams.className}
        onClick={() => {
          addLinkFilters(tableLinkParams.filterParams)
          redirect(PAGES.processes)
        }}>
        {process.processName}
      </TableLink>
    )
  }
})

export const getNumberPtsCol = (disableLink = false) => ({
  Header: 'numberOfPts',
  id: 'ptCount',
  filterOptions: { showInFieldSelector: true, category: FIELD_CATEGORIES.test, contentType: CONTENT_TYPES.number },
  accessor: (row) => {
    const ptCount = getPtCount([row])
    return ptCount.archived + ptCount.visible
  },
  textAlign: 'right',
  canExport: true,
  Cell: ({ row }) => {
    const { addLinkFilters } = useContext(FilterContext)
    const redirect = useRedirect()
    const ptCount = getPtCount([row.original])

    const assessmentFilter = {
      field: { value: 'assessmentName' },
      value: { value: row.original.assessmentName },
      tableName: 'pts'
    }

    const processFilter = {
      field: { value: 'processName' },
      value: { value: row.original.relatedProcess.processName },
      tableName: 'pts'
    }

    if (disableLink) {
      return (
        <div style={{ textAlign: 'right' }}>
          <EntityCountDisplay visible={ptCount.visible} archived={ptCount.archived} />
        </div>
      )
    }

    return (
      <div style={{ textAlign: 'right' }}>
        <TableLink
          onClick={() => {
            addLinkFilters([assessmentFilter, processFilter])
            redirect(PAGES.pts)
          }}>
          <EntityCountDisplay visible={ptCount.visible} archived={ptCount.archived} />
        </TableLink>
      </div>
    )
  }
})

export const getParticipantEmailCol = (show = false) => ({
  Header: FIELD_LABELS.email,
  id: 'pMail',
  filterOptions: { showInFieldSelector: true, category: FIELD_CATEGORIES.participant },
  canExport: true,
  accessor: (row) => row.pMail || '',
  show: show,
  Cell: ({ value }) => <span style={{ wordWrap: 'break-word' }}>{value}</span>
})

export const getParticipantProcessCol = () => ({
  Header: 'process',
  id: 'processName',
  canExport: true,
  show: true,
  filterOptions: { showInFieldSelector: true, category: FIELD_CATEGORIES.process, label: 'processName' },
  accessor: (row) => row.ptList.map((pt) => pt.relatedProcess.processName),
  Cell: ({ row }) => <ProcessListForTable ptList={row.original.ptList} />
})

export const getPtsAndResultsCol = () => ({
  Header: 'ptsAndStatus',
  id: 'assessmentName',
  canExport: true,
  show: true,
  accessor: (row) => row.ptList.map((pt) => pt.relatedAssessment.assessmentName),
  Cell: ({ row }) => <PtListForTable ptList={row.original.ptList} />
})

export const getParticipantFreeTextCol = (t) => ({
  Header: 'freeTextFilter',
  id: 'freeText',
  accessor: (row) =>
    row.pFullName +
    (row.pMail || '') +
    row.pNr +
    row.ptList.map((pt) => pt.relatedProcess.processName).join() +
    row.ptList.map((pt) => pt.ptNumber).join() +
    row.ptList.map((pt) => pt.relatedAssessment.assessmentName).join() +
    row.ptList.map((pt) => t(PT_STATUS_NAMES[pt.ptStatus])).join() +
    row.customFields.map((cf) => cf.content).join(),
  filterOptions: { type: FILTER_TYPES.textInput },
  canExport: false,
  show: false
})

export const getParticipantLastMailDateCol = (show = false) => ({
  Header: 'lastMailDate',
  id: 'lastMailDate',
  canExport: true,
  show: show,
  accessor: (row) => row.lastMailDate,
  Cell: ({ value }) => (
    <TableContentContainer>
      <DateDisplay date={value} format={'dateAndTime'} />
    </TableContentContainer>
  ),
  filterOptions: {
    showInFieldSelector: true,
    category: FIELD_CATEGORIES.email,
    contentType: CONTENT_TYPES.date,
    excludeFutureDates: true
  }
})

export const getParticipantAssessmentNameCol = () => ({
  Header: 'assessment',
  id: 'assessment',
  canExport: true,
  show: false,
  alwaysHidden: true,
  filterOptions: { showInFieldSelector: true, category: FIELD_CATEGORIES.test, label: 'assessmentName' },
  accessor: (row) => row.ptList.map((pt) => pt.relatedAssessment.assessmentName)
})
