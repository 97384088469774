import {
  getEntityLabel,
  getFlatArray,
  getArrayIntersectionByKey,
  isParticipant,
  isPt,
  isAssessment,
  fetchData,
  setStatusBar,
  updateEntities,
  updateResults
} from '../../utils/helper/Helper'
import {
  BULK_ACTIONS,
  BULK_EDITABLE_FIELDS,
  THE_FUTURE,
  THE_PAST,
  ENTITIES,
  MODAL_TYPES,
  API_DATA,
  STATUS_BAR_TYPES,
  DEFAULT_NORM_NR,
  DEFAULT_SCREEN_NR_START,
  DEFAULT_SCREEN_NR_END,
  START_SCREEN_KEY,
  END_SCREEN_KEY,
  NORM_KEY
} from '../../utils/constants/constants'
import { ButtonPrimary, ButtonSecondary } from '../../utils/elements/miscElements'
import CapabilitiesHelper from '../../utils/helper/CapabilitiesHelper'
import { createDate, formatDate } from '../../utils/helper/dateTimeHelper'
import BulkEditPreviewTable from './BulkEditPreviewTable'
import { showCopyToPtModal } from '../modal/modalUtils'
import useTranslate from '../../utils/hooks/useTranslate'

export const getBulkActions = (selectedRowData, entity, loggedInAsAdmin, contacts) => {
  if (selectedRowData.length === 0) return

  const bulkCapabilities = getBulkCapabilities(entity, selectedRowData, loggedInAsAdmin, contacts)

  const bulkActions = []
  bulkActions.push(BULK_ACTIONS.downloadSelectionAsCsv)
  if (bulkCapabilities.canDownloadReports) bulkActions.push(BULK_ACTIONS.downloadSelectionAsPdf)
  if (bulkCapabilities.canAddParticipantsToOtherTest) bulkActions.push(BULK_ACTIONS.addToOtherTest)
  if (bulkCapabilities.canSetCapabilities) bulkActions.push(BULK_ACTIONS.setCapabilities)
  if (bulkCapabilities.canSendEmails) bulkActions.push(BULK_ACTIONS.sendEmail)
  if (bulkCapabilities.canBulkStartTimer) bulkActions.push(BULK_ACTIONS.startTimer)
  if (bulkCapabilities.canBulkResetTimer) bulkActions.push(BULK_ACTIONS.resetTimer)
  if (bulkCapabilities.canActivatePts) bulkActions.push(BULK_ACTIONS.activate)
  if (bulkCapabilities.canDeactivatePts) bulkActions.push(BULK_ACTIONS.deactivate)
  if (bulkCapabilities.canArchiveEntities) bulkActions.push(BULK_ACTIONS.archive)
  if (bulkCapabilities.canDeleteEntities) bulkActions.push(BULK_ACTIONS.delete)
  if (bulkCapabilities.canRestoreEntities) bulkActions.push(BULK_ACTIONS.unarchive)
  if (bulkCapabilities.canRestoreEntities) bulkActions.push(BULK_ACTIONS.unarchive)
  if (bulkCapabilities.canResendMails) bulkActions.push(BULK_ACTIONS.resendEmail)
  return bulkActions
}

export const getEditableFields = (entity, rowData, context) => {
  const editableFields = []

  switch (entity) {
    case ENTITIES.processes:
      if (CapabilitiesHelper.canEditElektryonTemplate(context)) {
        editableFields.push(BULK_EDITABLE_FIELDS.elektryonTemplates)
      }
      break
    case ENTITIES.assessments: {
      const canEditSelectedAssessments = rowData.every((assessment) => assessment.relatedProcess.isEditor())
      if (canEditSelectedAssessments) {
        editableFields.push(
          BULK_EDITABLE_FIELDS.validFrom,
          BULK_EDITABLE_FIELDS.validUntil,
          BULK_EDITABLE_FIELDS.invitedDuration,
          BULK_EDITABLE_FIELDS.startedDuration
        )
      }
      if (CapabilitiesHelper.canEditNorms(entity, rowData)) {
        editableFields.push(BULK_EDITABLE_FIELDS.norm)
      }
      if (CapabilitiesHelper.canEditScreens(entity, rowData)) {
        editableFields.push(BULK_EDITABLE_FIELDS.startScreen)
        editableFields.push(BULK_EDITABLE_FIELDS.endScreen)
      }
      break
    }
    case ENTITIES.pts:
      editableFields.push(
        CapabilitiesHelper.canBulkEditPtValidFrom(rowData) ? BULK_EDITABLE_FIELDS.validFrom : '',
        CapabilitiesHelper.canBulkEditPtValidUntil(rowData) ? BULK_EDITABLE_FIELDS.validUntil : '',
        CapabilitiesHelper.canBulkEditPtTimer(rowData) ? BULK_EDITABLE_FIELDS.invitedDuration : '',
        CapabilitiesHelper.canBulkEditPtStartedDuration(rowData) ? BULK_EDITABLE_FIELDS.startedDuration : '',
        CapabilitiesHelper.canBulkEditPtInvitedStatus(rowData) ? BULK_EDITABLE_FIELDS.invitationStatus : ''
      )
      break
    default:
  }

  const customFieldDefinitions = context.completeDataSet.customFieldDefinitions
  const customFieldKeys = customFieldDefinitions.filter((cfd) => cfd.model === entity).map((cfd) => cfd.publicKey)
  return [...editableFields, ...customFieldKeys]
}

export const getRowDataForBulk = (entity, rowData) => {
  if (isParticipant(entity)) {
    const ptLists = rowData.map((participant) => participant.ptList)
    const allPts = ptLists.flat(1)
    return allPts
  } else {
    return rowData
  }
}

export const getBulkModalProps = (context, bulkData, entity, rowData, toggleAllRowsSelected, t) => {
  const designOptions = context.completeDataSet.designOptions
  const allFields = Object.keys(bulkData).map((e) => bulkData[e])
  const visibleDefaultFields = allFields.filter((field) => field.visible)
  const visibleCustomFields = bulkData.customFields.filter((cf) => cf.visible)
  const visibleFields = [...visibleDefaultFields, ...visibleCustomFields]
  const selectRowCount = Object.entries(rowData).length
  const entityLabel = getEntityLabel(entity, selectRowCount)

  return {
    headline: t('confirmChanges', selectRowCount, t(entityLabel)),
    content: <BulkEditPreviewTable {...{ visibleFields, designOptions }} />,
    buttonPrimary: <ModalButtonPrimary {...{ entity, rowData, context, toggleAllRowsSelected, visibleFields }} />,
    icon: 'icon-alert modal',
    type: MODAL_TYPES.alert
  }
}

const ModalButtonPrimary = ({ entity, rowData, context, toggleAllRowsSelected, visibleFields }) => {
  const t = useTranslate()
  const ptsForBulk = getRelatedPtsForBulk(entity, rowData, visibleFields)
  const copyToPtModalProps = getCopyToPtModalProps(
    context,
    ptsForBulk,
    toggleAllRowsSelected,
    visibleFields,
    entity,
    rowData
  )

  return ptsForBulk.validOnly.length > 0 ? (
    <ButtonPrimary modalButton content="changeDotDot" onClick={() => showCopyToPtModal({ ...copyToPtModalProps, t })} />
  ) : (
    <ButtonPrimary
      modalButton
      content="change"
      onClick={() => {
        context.setModalOpen(false)
        toggleAllRowsSelected(false)
        applyBulkEdit(visibleFields, 'endPointEdit', entity, rowData, context)
      }}
    />
  )
}

const getRelatedPtsForBulk = (entity, rowData, visibleFields) => {
  if (
    !isAssessment(entity) ||
    visibleFields.every(({ name }) => [NORM_KEY, START_SCREEN_KEY, END_SCREEN_KEY].includes(name))
  ) {
    return { allPts: 0, validOnly: 0 }
  }

  const relatedPtsArrays = rowData.map((row) => row.relatedPts)
  const flatPts = getFlatArray(relatedPtsArrays)
  const validPts = visibleFields.map((field) => getValidPtsForBulkChanges(flatPts, field.name, field.value))
  const intersection = getArrayIntersectionByKey(validPts, 'ptNumber')
  const filteredPts = getFilteredPts(visibleFields, intersection)
  return {
    allPts: flatPts,
    validOnly: filteredPts
  }
}

const getFilteredPts = (visibleFields, pts) => {
  const fieldNames = visibleFields.map((field) => field.name)
  const setBothDates = fieldNames.includes('validFrom') && fieldNames.includes('validUntil')
  if (setBothDates) {
    return pts
  } else {
    if (fieldNames.includes('validFrom')) {
      const newValidFrom = visibleFields.find((field) => field.name === 'validFrom')?.value
      return getPtsWhereValidFromIsValid(pts, newValidFrom)
    }
    if (fieldNames.includes('validUntil')) {
      const newValidUntil = visibleFields.find((field) => field.name === 'validUntil')?.value
      return getPtsWhereValidUntilIsValid(pts, newValidUntil)
    }
    return pts
  }
}

export const getPtsWhereValidFromIsValid = (pts, newValidFrom) => {
  if (!newValidFrom) {
    return pts
  }
  return pts.filter((pt) => pt.ptValidUntil > newValidFrom || !pt.ptValidUntil)
}

export const getPtsWhereValidUntilIsValid = (pts, newValidUntil) => {
  if (!newValidUntil) {
    return pts
  }
  return pts.filter((pt) => pt.ptValidFrom < newValidUntil || !pt.ptValidFrom)
}

export const validateBulkInput = (
  bulkData,
  setFormIsValid,
  setFromAndUntilDatesValid,
  rowData,
  entity,
  startedDurationValid
) => {
  const visibleFieldsCount = getVisibleFieldsCount(bulkData)
  const fromAndUntilDatesValid = validateStartedAndEndDates(entity, bulkData, rowData)
  setFromAndUntilDatesValid(fromAndUntilDatesValid)

  visibleFieldsCount > 0 && fromAndUntilDatesValid && startedDurationValid
    ? setFormIsValid(true)
    : setFormIsValid(false)
}

const validateStartedAndEndDates = (entity, bulkData, rowData) => {
  if (!isAssessment(entity) && !isPt(entity)) {
    return true
  }
  const newValidFrom = bulkData.validFrom.value || createDate(THE_PAST)
  const newValidUntil = bulkData.validUntil.value || createDate(THE_FUTURE)
  if (bulkData.validFrom.value && bulkData.validUntil.value) {
    return validateDateCombination(newValidFrom, newValidUntil)
  } else {
    return validateDatesAgainstCurrentValues(entity, rowData, newValidFrom, newValidUntil)
  }
}

const getVisibleFieldsCount = (bulkData) => {
  const visibleDefaultFieldsCount = Object.values(bulkData).filter((field) => field.visible).length
  const visibleCustomFields = bulkData.customFields.filter((cf) => cf.visible).length
  return visibleDefaultFieldsCount + visibleCustomFields
}

const validateDateCombination = (newValidFrom, newValidUntil) => {
  const newDateCombinationCorrect = newValidFrom < newValidUntil
  return newDateCombinationCorrect
}

const validateDatesAgainstCurrentValues = (entity, rowData, newValidFrom, newValidUntil) => {
  const validFromKey = getValidFromKey(entity)
  const validUntilKey = getValidUntilKey(entity)
  const currentValidFroms = rowData.map((row) => row[validFromKey])
  const currentValidUntils = rowData.map((row) => row[validUntilKey])

  const newValidFromCorrect = currentValidUntils.every(
    (currentValidUntil) => newValidFrom < currentValidUntil || !currentValidUntil
  )

  const newValidUntilCorrect = currentValidFroms.every(
    (currentValidFrom) => newValidUntil > currentValidFrom || !currentValidFrom
  )

  return newValidUntilCorrect && newValidFromCorrect
}

const getValidFromKey = (entity) => {
  if (isAssessment(entity)) return 'validFrom'
  if (isPt(entity)) return 'ptValidFrom'
}

const getValidUntilKey = (entity) => {
  if (isAssessment(entity)) return 'validUntil'
  if (isPt(entity)) return 'ptValidUntil'
}

export const scrollToTop = () => {
  const container = document.getElementsByClassName('bulk-sidebar-scroll-container')[0]
  container.scroll(0, 0)
}

const getBulkCapabilities = (entity, selectedRowData, loggedInAsAdmin, contacts) => ({
  canSendEmails: CapabilitiesHelper.canSendEmails(entity, selectedRowData),
  canBulkStartTimer: CapabilitiesHelper.canBulkStartTimer(entity, selectedRowData),
  canBulkResetTimer: CapabilitiesHelper.canBulkResetTimer(entity, selectedRowData),
  canDeactivatePts: CapabilitiesHelper.canDeactivatePts(entity, selectedRowData),
  canActivatePts: CapabilitiesHelper.canActivatePts(entity, selectedRowData),
  canSetCapabilities: CapabilitiesHelper.canSetCapabilities(entity, loggedInAsAdmin, contacts),
  canAddParticipantsToOtherTest: CapabilitiesHelper.canAddParticipantsToOtherTest(entity, selectedRowData),
  canDeleteEntities: CapabilitiesHelper.canDeleteEntities(entity, loggedInAsAdmin),
  canRestoreEntities: CapabilitiesHelper.canRestoreEntities(selectedRowData, loggedInAsAdmin, entity),
  canArchiveEntities: CapabilitiesHelper.canArchiveEntities(selectedRowData, loggedInAsAdmin, entity),
  canDownloadReports: CapabilitiesHelper.canDownloadReports(entity, selectedRowData),
  canResendMails: CapabilitiesHelper.canResendMail(entity)
})

const getCopyToPtModalProps = (context, ptsForBulk, toggleAllRowsSelected, visibleFields, entity, rowData) => ({
  context: context,
  ptsForBulk: ptsForBulk,
  buttonPrimary: (
    <ButtonPrimary
      modalButton
      content="applyChangesToPts"
      onClick={() => {
        context.setModalOpen(false)
        toggleAllRowsSelected(false)
        applyBulkEdit(visibleFields, 'endPointEdit', entity, rowData, context).then(() => {
          applyBulkEdit(visibleFields, 'endPointEdit', ENTITIES.pts, ptsForBulk.validOnly, context)
        })
      }}
    />
  ),
  buttonSecondary: (
    <ButtonSecondary
      modalButton
      content="doNotApplyChangesToPts"
      onClick={() => {
        context.setModalOpen(false)
        toggleAllRowsSelected(false)
        applyBulkEdit(visibleFields, 'endPointEdit', entity, rowData, context)
      }}
    />
  )
})

export const applyBulkEdit = async (visibleFields, endPointType = 'endPointEdit', entity, rowData, context) => {
  const endPoint = API_DATA[entity][endPointType]
  const payload = preparePayload(entity, rowData, visibleFields)
  context.setUpdatesArePaused(true)

  const responseData = await fetchData(payload, endPoint, context)

  console.log('bulk response:', responseData)

  try {
    updateEntities(responseData.response.data, context)
    if (responseData?.response?.resultsToUpdate) {
      const resultNrs = responseData?.response?.resultsToUpdate?.resultNrs || []
      const processResultNrs = responseData?.response?.resultsToUpdate?.processResultNrs || []
      await updateResults(resultNrs, processResultNrs, context)
    }
    setStatusBar({
      controller: context.statusBarController,
      type: STATUS_BAR_TYPES.success,
      text: 'fieldsSuccessfullyChanged'
    })
  } catch (e) {
    console.error(e)
  } finally {
    context.setUpdatesArePaused(false)
  }
}

const preparePayload = (entity, rowData, visibleFields) => {
  const key = API_DATA[entity].idKey
  const ids = rowData.map((row) => row[key])

  const payload = { [key]: ids }
  addFieldsToPayload(visibleFields, payload)
  addGlobalStatusToPayload(visibleFields, payload)
  addCustomFieldsToPayload(visibleFields, payload)
  return payload
}

const addFieldsToPayload = (visibleFields, payload) => {
  visibleFields.forEach((field) => {
    if (field.name === NORM_KEY && field.value === DEFAULT_NORM_NR) {
      const obj = { normNr: '' }
      return Object.assign(payload, obj)
    }
    if (field.name === START_SCREEN_KEY && field.value === DEFAULT_SCREEN_NR_START) {
      const obj = { screenNrStart: '' }
      return Object.assign(payload, obj)
    }
    if (field.name === END_SCREEN_KEY && field.value === DEFAULT_SCREEN_NR_END) {
      const obj = { screenNrEnd: '' }
      return Object.assign(payload, obj)
    }
    if (!field.isGlobalStatus) {
      const val = getFormattedValue(field)
      const obj = { [field.name]: val }
      return Object.assign(payload, obj)
    }
  })
}

const addGlobalStatusToPayload = (visibleFields, payload) => {
  const globalStatus = visibleFields.filter((field) => field.isGlobalStatus)

  const globalStatusArray = []

  globalStatus.map((globalStatus) => {
    const obj = {
      statusKey: globalStatus.name,
      statusValue: globalStatus.value
    }
    globalStatusArray.push(obj)
    return globalStatusArray
  })

  Object.assign(payload, { globalStatus: globalStatusArray })
}

const addCustomFieldsToPayload = (visibleFields, payload) => {
  const customFields = visibleFields.filter((field) => field.isCustomField)

  const customFieldsArray = []

  customFields.map((customField) => {
    const obj = {
      customFieldKey: customField.publicKey,
      customFieldValue: customField.value
    }
    customFieldsArray.push(obj)
    return customFieldsArray
  })

  Object.assign(payload, { customFields: customFieldsArray })
}

const getFormattedValue = (field) => {
  if (field.name === 'validFrom' || field.name === 'validUntil') {
    return formatDate(field.value).androFormat || ''
  }
  return field.value
}

export const getValidPtsForBulkChanges = (pts, key) => {
  if (!pts || !Array.isArray(pts)) return []
  switch (key) {
    case 'validFrom':
      return pts.filter((pt) => CapabilitiesHelper.canBulkEditPtValidFrom([pt]))
    case 'validUntil':
      return pts.filter((pt) => CapabilitiesHelper.canBulkEditPtValidUntil([pt]))
    case 'invitedDuration':
      return pts.filter((pt) => CapabilitiesHelper.canBulkEditPtTimer([pt]))
    case 'startedDuration':
      return pts.filter((pt) => CapabilitiesHelper.canBulkEditPtStartedDuration([pt]))
    default:
      return pts
  }
}
