import styled from 'styled-components'
import { CustomTableCheckbox } from '../../table/tableElements'

const RadioList = ({ disabled, options, setterFunction, stateValue }) => (
  <RadioListUl>
    {options.map((option) => {
      const isChecked = option.value === stateValue
      const className = isChecked ? 'svg-icon icon-circle' : ''
      return (
        <RadioListLi key={option.label}>
          <RadioListLabel disabled={disabled} onClick={() => setterFunction(option.value)}>
            <CustomTableCheckbox
              type="radio"
              checked={isChecked}
              onKeyPress={() => setterFunction(option.value)}
              className={'custom-checkbox ' + className + (disabled ? ' disabled' : '')}
              disabled={disabled}
            />
            <span>{option.label}</span>
          </RadioListLabel>
        </RadioListLi>
      )
    })}
  </RadioListUl>
)

export default RadioList

const RadioListUl = styled.ul`
  padding: 0;
  display: grid;
  gap: var(--space-5);
  grid-template-columns: auto 1fr;
`

const RadioListLi = styled.li`
  list-style-type: none !important;
`

const RadioListLabel = styled.label`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: var(--space-3);
  pointer-events: ${(props) => (props.disabled ? 'none' : '')};
  span {
    line-height: var(--lh-2);
  }
`
