import potentialsProfile from '../../assets/reports/de/potentials_profile.pdf'
import potentialsFeedback from '../../assets/reports/de/potentials_feedback.pdf'
import potentialsStatistics from '../../assets/reports/de/potentials_statistics.pdf'
import potentialsCrosstest from '../../assets/reports/de/potentials_crosstest.pdf'
import traitsVerbalisation from '../../assets/reports/de/traits_verbalisation.pdf'
import traitsPotentialsRisks from '../../assets/reports/de/traits_potentials_risks.pdf'
import traitsMatching from '../../assets/reports/de/traits_matching.pdf'
import traitsInterview from '../../assets/reports/de/traits_interview.pdf'
import traitsProfile from '../../assets/reports/de/traits_profile.pdf'
import traitsFeedback from '../../assets/reports/de/traits_feedback.pdf'
import goalsProfile from '../../assets/reports/de/goals_profile.pdf'
import goalsMatching from '../../assets/reports/de/goals_matching.pdf'
import goalsVerbalisation from '../../assets/reports/de/goals_verbalisation.pdf'
import goalsRadar from '../../assets/reports/de/goals_radar.pdf'

import potentialsProfileEnglish from '../../assets/reports/en/potentials_profile.pdf'
import potentialsFeedbackEnglish from '../../assets/reports/en/potentials_feedback.pdf'
import potentialsStatisticsEnglish from '../../assets/reports/en/potentials_statistics.pdf'
import potentialsCrosstestEnglish from '../../assets/reports/en/potentials_crosstest.pdf'
import traitsVerbalisationEnglish from '../../assets/reports/en/traits_verbalisation.pdf'
import traitsPotentialsRisksEnglish from '../../assets/reports/en/traits_potentials_risks.pdf'
import traitsMatchingEnglish from '../../assets/reports/en/traits_matching.pdf'
import traitsInterviewEnglish from '../../assets/reports/en/traits_interview.pdf'
import traitsProfileEnglish from '../../assets/reports/en/traits_profile.pdf'
import traitsFeedbackEnglish from '../../assets/reports/en/traits_feedback.pdf'
import goalsProfileEnglish from '../../assets/reports/en/goals_profile.pdf'
import goalsMatchingEnglish from '../../assets/reports/en/goals_matching.pdf'
import goalsVerbalisationEnglish from '../../assets/reports/en/goals_verbalisation.pdf'
import goalsRadarEnglish from '../../assets/reports/en/goals_radar.pdf'

import useTranslate from '../hooks/useTranslate'

export const loginMessages = {
  logout: 'logoutSuccess',
  expelled: 'sessionEnded',
  wrongAccessData: 'incorrectCredentials'
}

export const NOT_SET_TEXT = 'notSet'
export const NO_VALID_FROM_SET_TEXT = 'immediately'
export const NO_VALID_UNTIL_SET_TEXT = 'unlimitedValidUntil'

export const INVITATION_STATUS = {
  notSet: { label: NOT_SET_TEXT, value: 0 },
  notInvited: { label: 'notInvited', value: 1 },
  invited: { label: 'invited', value: 2 },
  reminded: { label: 'reminded', value: 3 }
}

export const CAMERA_SUPERVISION_WITHOUT_ID_CHECK = 1
export const CAMERA_SUPERVISION_WITH_ID_CHECK = 2

export const SELECT_OPTIONS = {
  yesNo: [
    { label: 'no', value: false },
    { label: 'yes', value: true }
  ],
  setValidFrom: [
    { label: 'immediately', value: false },
    { label: 'setStartTime', value: true }
  ],
  setValidUntil: [
    { label: 'unlimitedValidUntil', value: false },
    { label: 'setEndTime', value: true }
  ],
  setTimer: [
    { label: 'noTimer', value: false },
    { label: 'setTimer', value: true }
  ],
  setStartedDuration: [
    { label: 'unlimitedStartedDuration', value: false },
    { label: 'limitTime', value: true }
  ],
  contactTitles: [
    { value: null, label: '' },
    { value: 1, label: 'Dr.' },
    { value: 2, label: 'Prof.' }
  ],
  contactSalutations: [
    { value: null, label: '' },
    { value: 'm', label: 'mrSalutation' },
    { value: 'f', label: 'mrsSalutation' }
  ],
  isAnonymous: [
    { label: 'namedExecution', value: false },
    { label: 'anonymousExecution', value: true }
  ],
  userCapabilities: [
    { label: 'noAuthorization', value: 0 },
    { label: 'viewerAuthorization', value: 1 },
    { label: 'editorAuthorization', value: 2 }
  ],
  isSupervised: [
    { label: 'withoutSupervision', value: false },
    { label: 'withSupervision', value: true }
  ],
  useChildItems: [
    { label: 'notActivated', value: false },
    { label: 'recommendedActivated', value: true }
  ],
  inHub: [
    { label: 'doNotShow', value: false },
    { label: 'show', value: true }
  ],
  gender: [
    { label: 'maleGender', value: 'm' },
    { label: 'femaleGender', value: 'f' },
    { label: 'diverseGender', value: 'd' },
    { label: 'notSpecified', value: 'n' }
  ],
  durationMultiplier: [
    { label: 'none', value: '1' },
    { label: '+ 10 %', value: '1.1' },
    { label: '+ 20 %', value: '1.2' },
    { label: '+ 25 %', value: '1.25' },
    { label: '+ 30 %', value: '1.3' },
    { label: '+ 40 %', value: '1.4' },
    { label: '+ 50 %', value: '1.5' },
    { label: '+ 60 %', value: '1.6' },
    { label: '+ 70 %', value: '1.7' },
    { label: '+ 80 %', value: '1.8' },
    { label: '+ 90 %', value: '1.9' },
    { label: '+ 100 %', value: '2' }
  ],
  useFileUploadShort: [
    { label: 'blankParticipant', value: false },
    { label: 'participantList', value: true }
  ],
  emailTypes: [
    { label: 'invitationEmail', value: 'invitation' },
    { label: 'reminderEmail', value: 'reminder' }
  ],
  invitationStatus: [
    INVITATION_STATUS.notSet,
    INVITATION_STATUS.notInvited,
    INVITATION_STATUS.invited,
    INVITATION_STATUS.reminded
  ],
  bulkEdit: [
    { label: 'keep', value: false },
    { label: 'changeDotDot', value: true }
  ],
  customFieldTypes: [
    { label: 'Text', value: 'text' },
    { label: 'customFieldSelection', value: 'select' }
  ],
  createNewProcess: [
    { label: 'existingProcess', value: false },
    { label: 'newProcess', value: true }
  ],
  useProcessResults: [
    { label: 'doNotUseProcessResult', value: false },
    { label: 'useProcessResult', value: true }
  ],
  anonymizeTimeSpans: [
    { label: 'oneMonth', value: 30 },
    { label: 'threeMonths', value: 90 },
    { label: 'sixMonths', value: 180 },
    { label: 'twelveMonths', value: 365 },
    { label: 'twentyFourMonths', value: 730 },
    { label: 'never', value: 0 }
  ],
  languages: [
    { label: 'german', value: 'de' },
    { label: 'english', value: 'en' }
  ],
  displayModes: [
    { label: 'lightMode', value: 'light', icon: 'icon-sun' },
    { label: 'darkMode', value: 'dark', icon: 'icon-moon' }
  ],
  onOff: [
    { label: 'on', value: true },
    { label: 'off', value: false }
  ],
  cameraSupervision: [
    { label: 'off', value: 0 },
    { label: 'withoutIdCheck', value: CAMERA_SUPERVISION_WITHOUT_ID_CHECK },
    { label: 'withIdCheck', value: CAMERA_SUPERVISION_WITH_ID_CHECK }
  ],
  tabTracking: [
    { label: 'notActivated', value: false },
    { label: 'recommendedActivated', value: true }
  ],
  reminderTriggerType: [
    { label: 'after_invitation', value: 'after_invitation' },
    { label: 'before_valid_until', value: 'before_valid_until' }
  ],
  activate: [
    { label: 'dontActivate', value: false },
    { label: 'activate', value: true }
  ]
}

export const BULK_SELECT_PLACEHOLDER = 'pleaseSelect'

export const FIELD_LABELS = {
  validFrom: 'validFrom',
  validUntil: 'validUntil',
  invitedDuration: 'invitedDuration',
  startedDuration: 'startedDuration',
  isSupervised: 'isSupervised',
  useChildItems: 'useChildItems',
  inHub: 'inHub',
  testLanguages: 'testLanguages',
  testDesign: 'testDesign',
  email: 'emailAddress',
  salutation: 'salutation',
  firstName: 'firstName',
  lastName: 'lastName',
  phoneNumber: 'phoneNumber',
  elementName: 'elementName',
  description: 'comment',
  personalName: 'personalName',
  pNumber: 'pNumber',
  participantName: 'participantName',
  ptNumber: 'ptNumber',
  isAnonymous: 'dataSecuritySettings',
  password: 'password',
  oldPassword: 'oldPassword',
  newPassword: 'newPassword',
  street: 'street',
  address_addition: 'address_addition',
  zipCode: 'zipCode',
  city: 'city',
  country: 'country',
  contactIsAdmin: 'contactIsAdmin',
  gender: 'gender',
  durationMultiplier: 'durationMultiplier',
  credits: 'credits',
  maxCredits: 'maxCredits',
  useFileUpload: 'dataSource',
  invitation: 'invitation',
  reminder: 'reminder',
  invitationStatus: 'invitationStatus',
  referenceAssessment: 'referenceAssessment',
  role: 'role',
  language: 'language',
  tabTracking: 'tabTracking',
  cameraSupervision: 'cameraSupervision',
  gradingRule: 'gradingRule',
  startScreen: 'startScreen',
  endScreen: 'endScreen'
}

export const COUNTRIES = [
  { value: 'DE', label: 'Deutschland' },
  { value: 'AD', label: 'Andorra' },
  { value: 'AE', label: 'Vereinigte Arabische Emirate' },
  { value: 'AF', label: 'Afghanistan' },
  { value: 'AG', label: 'Antigua und Barbuda' },
  { value: 'AI', label: 'Anguilla' },
  { value: 'AL', label: 'Albanien' },
  { value: 'AM', label: 'Armenien' },
  { value: 'AN', label: 'Niederländische Antillen' },
  { value: 'AO', label: 'Angola' },
  { value: 'AQ', label: 'Antarktis' },
  { value: 'AR', label: 'Argentinien' },
  { value: 'AS', label: 'Amerikanisch-Samoa' },
  { value: 'AT', label: 'Österreich (Austria)' },
  { value: 'AU', label: 'Australien' },
  { value: 'AW', label: 'Aruba' },
  { value: 'AZ', label: 'Azerbaijan' },
  { value: 'BA', label: 'Bosnien-Herzegovina' },
  { value: 'BB', label: 'Barbados' },
  { value: 'BD', label: 'Bangladesh' },
  { value: 'BE', label: 'Belgien' },
  { value: 'BF', label: 'Burkina Faso' },
  { value: 'BG', label: 'Bulgarien' },
  { value: 'BH', label: 'Bahrain' },
  { value: 'BI', label: 'Burundi' },
  { value: 'BJ', label: 'Benin' },
  { value: 'BM', label: 'Bermudas' },
  { value: 'BN', label: 'Brunei Darussalam' },
  { value: 'BO', label: 'Bolivien' },
  { value: 'BR', label: 'Brasilien' },
  { value: 'BS', label: 'Bahamas' },
  { value: 'BT', label: 'Bhutan' },
  { value: 'BV', label: 'Bouvet Island' },
  { value: 'BW', label: 'Botswana' },
  { value: 'BY', label: 'Weißrußland (Belarus)' },
  { value: 'BZ', label: 'Belize' },
  { value: 'CA', label: 'Canada' },
  { value: 'CC', label: 'Cocos (Keeling) Islands' },
  { value: 'CD', label: 'Demokratische Republik Kongo' },
  { value: 'CF', label: 'Zentralafrikanische Republik' },
  { value: 'CG', label: 'Kongo' },
  { value: 'CH', label: 'Schweiz' },
  { value: 'CI', label: "Elfenbeinküste (Cote D'Ivoire)" },
  { value: 'CK', label: 'Cook Islands' },
  { value: 'CL', label: 'Chile' },
  { value: 'CM', label: 'Kamerun' },
  { value: 'CN', label: 'China' },
  { value: 'CO', label: 'Kolumbien' },
  { value: 'CR', label: 'Costa Rica' },
  { value: 'CS', label: 'Tschechoslowakei (ehemalige)' },
  { value: 'CU', label: 'Kuba' },
  { value: 'CV', label: 'Kap Verde' },
  { value: 'CX', label: 'Christmas Island' },
  { value: 'CY', label: 'Zypern' },
  { value: 'CZ', label: 'Tschechische Republik' },
  { value: 'DJ', label: 'Djibouti' },
  { value: 'DK', label: 'Dänemark' },
  { value: 'DM', label: 'Dominica' },
  { value: 'DO', label: 'Dominikanische Republik' },
  { value: 'DZ', label: 'Algerien' },
  { value: 'EC', label: 'Ecuador' },
  { value: 'EE', label: 'Estland' },
  { value: 'EG', label: 'Ägypten' },
  { value: 'EH', label: 'Westsahara' },
  { value: 'ER', label: 'Eritrea' },
  { value: 'ES', label: 'Spanien' },
  { value: 'ET', label: 'Äthiopien' },
  { value: 'FI', label: 'Finnland' },
  { value: 'FJ', label: 'Fiji' },
  { value: 'FK', label: 'Falkland-Inseln (Malvinas)' },
  { value: 'FM', label: 'Micronesien' },
  { value: 'FO', label: 'Faröer-Inseln' },
  { value: 'FR', label: 'Frankreich' },
  { value: 'FX', label: 'France, Metropolitan' },
  { value: 'GA', label: 'Gabon' },
  { value: 'GD', label: 'Grenada' },
  { value: 'GE', label: 'Georgien' },
  { value: 'GF', label: 'Französisch Guiana' },
  { value: 'GH', label: 'Ghana' },
  { value: 'GI', label: 'Gibraltar' },
  { value: 'GL', label: 'Grönland' },
  { value: 'GM', label: 'Gambia' },
  { value: 'GN', label: 'Guinea' },
  { value: 'GP', label: 'Guadeloupe' },
  { value: 'GQ', label: 'Äquatorialguinea' },
  { value: 'GR', label: 'Griechenland' },
  { value: 'GS', label: 'Südgeorgien und Südliche Sandwich-Inseln' },
  { value: 'GT', label: 'Guatemala' },
  { value: 'GU', label: 'Guam' },
  { value: 'GW', label: 'Guinea-Bissau' },
  { value: 'GY', label: 'Guyana' },
  { value: 'HK', label: 'Kong Hong' },
  { value: 'HM', label: 'Heard und Mc Donald Islands' },
  { value: 'HN', label: 'Honduras' },
  { value: 'HT', label: 'Haiti' },
  { value: 'HU', label: 'Ungarn' },
  { value: 'ID', label: 'Indonesien' },
  { value: 'IE', label: 'Irland' },
  { value: 'IL', label: 'Israel' },
  { value: 'IN', label: 'Indien' },
  { value: 'IO', label: 'British Indian Ocean Territory' },
  { value: 'IQ', label: 'Irak' },
  { value: 'IR', label: 'Iran (Islamische Republik)' },
  { value: 'IS', label: 'Island' },
  { value: 'IT', label: 'Italien' },
  { value: 'JM', label: 'Jamaica' },
  { value: 'JO', label: 'Jordanien' },
  { value: 'JP', label: 'Japan' },
  { value: 'KE', label: 'Kenya' },
  { value: 'KG', label: 'Kirgisien' },
  { value: 'KH', label: 'Königreich Kambodscha' },
  { value: 'KI', label: 'Kiribati' },
  { value: 'KM', label: 'Komoren' },
  { value: 'KN', label: 'Saint Kitts und Nevis' },
  { value: 'KP', label: 'Korea, Volksrepublik' },
  { value: 'KR', label: 'Korea' },
  { value: 'KW', label: 'Kuwait' },
  { value: 'KY', label: 'Kayman Islands' },
  { value: 'KZ', label: 'Kasachstan' },
  { value: 'LA', label: 'Laos' },
  { value: 'LB', label: 'Libanon' },
  { value: 'LC', label: 'Saint Lucia' },
  { value: 'LI', label: 'Liechtenstein' },
  { value: 'LK', label: 'Sri Lanka' },
  { value: 'LR', label: 'Liberia' },
  { value: 'LS', label: 'Lesotho' },
  { value: 'LT', label: 'Littauen' },
  { value: 'LU', label: 'Luxemburg' },
  { value: 'LV', label: 'Lettland' },
  { value: 'LY', label: 'Libyen' },
  { value: 'MA', label: 'Marokko' },
  { value: 'MC', label: 'Monaco' },
  { value: 'MD', label: 'Moldavien' },
  { value: 'MG', label: 'Madagaskar' },
  { value: 'MH', label: 'Marshall-Inseln' },
  { value: 'MK', label: 'Mazedonien, ehem. Jugoslawische Republik' },
  { value: 'ML', label: 'Mali' },
  { value: 'MM', label: 'Myanmar' },
  { value: 'MN', label: 'Mongolei' },
  { value: 'MO', label: 'Macao' },
  { value: 'MP', label: 'Nördliche Marianneninseln' },
  { value: 'MQ', label: 'Martinique' },
  { value: 'MR', label: 'Mauretanien' },
  { value: 'MS', label: 'Montserrat' },
  { value: 'MT', label: 'Malta' },
  { value: 'MU', label: 'Mauritius' },
  { value: 'MV', label: 'Malediven' },
  { value: 'MW', label: 'Malawi' },
  { value: 'MX', label: 'Mexico' },
  { value: 'MY', label: 'Malaysien' },
  { value: 'MZ', label: 'Mozambique' },
  { value: 'NA', label: 'Namibia' },
  { value: 'NC', label: 'Neu Kaledonien' },
  { value: 'NE', label: 'Niger' },
  { value: 'NF', label: 'Norfolk Island' },
  { value: 'NG', label: 'Nigeria' },
  { value: 'NI', label: 'Nicaragua' },
  { value: 'NL', label: 'Niederlande' },
  { value: 'NO', label: 'Norwegen' },
  { value: 'NP', label: 'Nepal' },
  { value: 'NR', label: 'Nauru' },
  { value: 'NU', label: 'Niue' },
  { value: 'NZ', label: 'Neuseeland' },
  { value: 'OM', label: 'Oman' },
  { value: 'PA', label: 'Panama' },
  { value: 'PE', label: 'Peru' },
  { value: 'PF', label: 'Französisch Polynesien' },
  { value: 'PG', label: 'Papua Neuguinea' },
  { value: 'PH', label: 'Philippinen' },
  { value: 'PK', label: 'Pakistan' },
  { value: 'PL', label: 'Polen' },
  { value: 'PM', label: 'St. Pierre und Miquelon' },
  { value: 'PN', label: 'Pitcairn' },
  { value: 'PR', label: 'Puerto Rico' },
  { value: 'PT', label: 'Portugal' },
  { value: 'PW', label: 'Palau' },
  { value: 'PY', label: 'Paraguay' },
  { value: 'QA', label: 'Katar' },
  { value: 'RE', label: 'Reunion' },
  { value: 'RO', label: 'Rumänien' },
  { value: 'RU', label: 'Russische Föderation' },
  { value: 'RW', label: 'Ruanda' },
  { value: 'SA', label: 'Saudi Arabien' },
  { value: 'SB', label: 'Salomonen' },
  { value: 'SC', label: 'Seychellen' },
  { value: 'SD', label: 'Sudan' },
  { value: 'SE', label: 'Schweden' },
  { value: 'SG', label: 'Singapur' },
  { value: 'SH', label: 'St. Helena' },
  { value: 'SI', label: 'Slovenien' },
  { value: 'SJ', label: 'Svalbard und Jan Mayen Islands' },
  { value: 'SK', label: 'Slowakei' },
  { value: 'SL', label: 'Sierra Leone' },
  { value: 'SM', label: 'San Marino' },
  { value: 'SN', label: 'Senegal' },
  { value: 'SO', label: 'Somalia' },
  { value: 'SR', label: 'Surinam' },
  { value: 'ST', label: 'Sao Tome und Principe' },
  { value: 'SV', label: 'El Salvador' },
  { value: 'SY', label: 'Syrien, Arabische Republik' },
  { value: 'SZ', label: 'Swaziland' },
  { value: 'TC', label: 'Turk und Caicos-Inseln' },
  { value: 'TD', label: 'Tschad' },
  { value: 'TF', label: 'Französisches Südl.Territorium' },
  { value: 'TG', label: 'Togo' },
  { value: 'TH', label: 'Thailand' },
  { value: 'TJ', label: 'Tadschikistan' },
  { value: 'TK', label: 'Tokelau' },
  { value: 'TM', label: 'Turkmenistan' },
  { value: 'TN', label: 'Tunesien' },
  { value: 'TO', label: 'Tonga' },
  { value: 'TP', label: 'Ost-Timor' },
  { value: 'TR', label: 'Türkei' },
  { value: 'TT', label: 'Trinidad und Tobago' },
  { value: 'TV', label: 'Tuvalu' },
  { value: 'TW', label: 'Taiwan' },
  { value: 'TZ', label: 'Tansania, United Republic of' },
  { value: 'UA', label: 'Ukraine' },
  { value: 'UG', label: 'Uganda' },
  { value: 'GB', label: 'Großbritannien' },
  { value: 'US', label: 'Vereinigte Staaten' },
  { value: 'UM', label: 'Vereinigte Staaten, Minor Outlying Islands' },
  { value: 'UY', label: 'Uruguay' },
  { value: 'UZ', label: 'Usbekistan' },
  { value: 'VA', label: 'Vatikanstaat' },
  { value: 'VC', label: 'Saint Vincent und Grenadines' },
  { value: 'VE', label: 'Venezuela' },
  { value: 'VG', label: 'Virgin Islands (Britisch)' },
  { value: 'VI', label: 'Virgin Islands (U.S.)' },
  { value: 'VN', label: 'Vietnam' },
  { value: 'VU', label: 'Vanuatu' },
  { value: 'WF', label: 'Wallis und Futuna Islands' },
  { value: 'WS', label: 'Samoa' },
  { value: 'YE', label: 'Jemen' },
  { value: 'YT', label: 'Mayotte' },
  { value: 'YU', label: 'Jugoslawien' },
  { value: 'ZA', label: 'Südafrika' },
  { value: 'ZM', label: 'Sambia' },
  { value: 'ZW', label: 'Zimbabwe' }
]

export const REPORT_MODULES = [
  {
    key: 'traitsProfile',
    androKey: 'traitsProfile',
    previewFile: {
      de: traitsProfile,
      en: traitsProfileEnglish
    },
    previewPageCount: 1,
    headline: 'resultsOverview',
    description: 'meaningfulPersonalityProfile',
    price: 0,
    isOptional: false
  },
  {
    key: 'potentialsProfile',
    androKey: 'potentialsProfile',
    previewFile: {
      de: potentialsProfile,
      en: potentialsProfileEnglish
    },
    previewPageCount: 1,
    headline: 'resultsOverview',
    description: 'meaningfulPerformanceProfile',
    price: 0,
    isOptional: false
  },
  {
    key: 'goalsProfile',
    androKey: 'goalsProfile',
    previewFile: {
      de: goalsProfile,
      en: goalsProfileEnglish
    },
    previewPageCount: 1,
    headline: 'resultsOverview',
    description: 'meaningfulInterestProfile',
    price: 0,
    isOptional: false
  },
  {
    key: 'potentialsCrossTest',
    androKey: 'potentialsCrossTest',
    previewFile: {
      de: potentialsCrosstest,
      en: potentialsCrosstestEnglish
    },
    previewPageCount: 1,
    headline: 'crossTestResult',
    description: 'prePostTestComparison',
    price: 0,
    isOptional: false
  },
  {
    key: 'goalsVerbalisation',
    androKey: 'verbalisation',
    previewFile: {
      de: goalsVerbalisation,
      en: goalsVerbalisationEnglish
    },
    previewPageCount: 1,
    headline: 'resultDescription',
    description: 'shortExplanationsInterestDimensions',
    price: 0,
    isOptional: true
  },
  {
    key: 'traitsVerbalisation',
    androKey: 'verbalisation',
    previewFile: {
      de: traitsVerbalisation,
      en: traitsVerbalisationEnglish
    },
    previewPageCount: 1,
    headline: 'resultDescription',
    description: 'shortExplanationsPersonalityFacets',
    price: 0,
    isOptional: true
  },
  {
    key: 'traitsMatching',
    androKey: 'matching',
    previewFile: {
      de: traitsMatching,
      en: traitsMatchingEnglish
    },
    previewPageCount: 1,
    headline: 'jobMatching',
    description: 'compareCandidatesWithProfile',
    price: 1,
    isOptional: true
  },
  {
    key: 'traitsPotentialsRisks',
    androKey: 'potentialsRisks',
    previewFile: {
      de: traitsPotentialsRisks,
      en: traitsPotentialsRisksEnglish
    },
    previewPageCount: 1,
    headline: 'potentialsAndRisks',
    description: 'detailedBehavioralHints',
    price: 1,
    isOptional: true
  },
  {
    key: 'traitsInterview',
    androKey: 'interview',
    previewFile: {
      de: traitsInterview,
      en: traitsInterviewEnglish
    },
    previewPageCount: 1,
    headline: 'interviewQuestions',
    description: 'relevantInterviewQuestions',
    price: 1,
    isOptional: true
  },
  {
    key: 'traitsFeedback',
    androKey: 'feedbackAvailable',
    previewFile: {
      de: traitsFeedback,
      en: traitsFeedbackEnglish
    },
    previewPageCount: 2,
    headline: 'participantFeedback',
    description: 'resultFeedbackForParticipants',
    price: 1,
    isOptional: true
  },
  {
    key: 'potentialsStatistics',
    androKey: 'potentialsStatistics',
    previewFile: {
      de: potentialsStatistics,
      en: potentialsStatisticsEnglish
    },
    previewPageCount: 1,
    headline: 'statisticsAndAnomalies',
    description: 'detailedTestStatistics',
    price: 0,
    isOptional: true
  },
  {
    key: 'potentialsFeedback',
    androKey: 'feedbackAvailable',
    previewFile: {
      de: potentialsFeedback,
      en: potentialsFeedbackEnglish
    },
    previewPageCount: 2,
    headline: 'participantFeedback',
    description: 'resultFeedbackForParticipants',
    price: 1,
    isOptional: true
  },
  {
    key: 'goalsMatching',
    androKey: 'matching',
    previewFile: {
      de: goalsMatching,
      en: goalsMatchingEnglish
    },
    previewPageCount: 1,
    headline: 'jobMatching',
    description: 'compareCandidatesWithProfile',
    price: 1,
    isOptional: true
  },
  {
    key: 'goalsRadar',
    androKey: 'radarPlot',
    previewFile: {
      de: goalsRadar,
      en: goalsRadarEnglish
    },
    previewPageCount: 1,
    headline: 'radarPlot',
    isDisabled: false,
    description: 'explanationGoalsRadarModule',
    price: 0,
    isOptional: true
  }
]

export const NORM_KEY = 'normNr'
export const SCREEN_KEY = 'screenNr'
export const PT_INVITATION_KEY = 'publicKey'

export const API_DATA = {
  processes: {
    idKey: 'processUuid',
    endPointEdit: 'edit_process',
    endPointAdd: 'add_process',
    endPointArchiveDelete: 'archive_delete_process',
    endPointRestore: 'unarchive_process',
    elementNamePlural: 'processes'
  },
  assessments: {
    idKey: 'assessmentUuid',
    endPointEdit: 'edit_assessment',
    endPointAdd: 'add_assessment',
    endPointArchiveDelete: 'archive_delete_assessment',
    endPointRestore: 'unarchive_assessment',
    elementNamePlural: 'assessments'
  },
  participants: {
    idKey: 'pNr',
    endPointEdit: 'edit_participant',
    endPointAdd: 'add_participant',
    endPointArchiveDelete: 'archive_delete_participant',
    endPointRestore: 'unarchive_participant',
    elementNamePlural: 'participants',
    participantReport: 'participant_report',
    participantReportsZip: 'participant_reports_zip'
  },
  pts: {
    idKey: 'ptNumber',
    endPointEdit: 'edit_pt',
    endPointAdd: 'add_pts',
    endPointAddJson: 'add_json_participants',
    endPointArchiveDelete: 'archive_delete_pt',
    endPointRestore: 'unarchive_pt',
    endPointDownloadPdfs: 'pt_reports_zip',
    elementNamePlural: 'pts'
  },
  contacts: {
    idKey: 'contactUuid',
    endPointEdit: 'edit_contact',
    endPointAdd: 'add_contact',
    endPointCheckMail: 'check_contact_mail',
    endPointChangePassword: 'change_password',
    endPointDelete: 'delete_contacts',
    elementNamePlural: 'contacts'
  },
  customers: {
    endPointEdit: 'edit_customer',
    endPointAdd: 'add_customer',
    endPointDelete: 'delete_customer',
    elementNamePlural: 'customers',
    endPointEditAnonymizeTimespan: 'edit_anon_timespan'
  },
  contactProcesses: {
    idKey: 'contactUuid',
    idKeySecondary: 'processUuid',
    endPointEdit: 'edit_contact_capabilities',
    elementNamePlural: 'contactProcesses',
    endPointBulkCapabilities: 'bulk_edit_capabilities'
  },
  usedConfigs: {
    idKey: 'configUuid',
    elementNamePlural: 'usedConfigs'
  },
  results: {
    idKey: 'resultNr',
    elementNamePlural: 'results'
  },
  processResults: {
    idKey: 'resultNr',
    elementNamePlural: 'processResults',
    endPointRecalculate: 'recalculate'
  },
  billing: {
    endPointEdit: 'edit_billing_info'
  },
  creditBookings: {
    idKey: 'bookingNr',
    elementNamePlural: 'creditBookings',
    endPointAdd: 'create_booking'
  },
  notifications: {
    elementNamePlural: 'notifications',
    endPointEdit: 'set_seen',
    idKey: 'notificationUuid'
  },
  tableColumns: {
    endPointEdit: 'edit_table_columns'
  },
  contactSettings: {
    endPointGet: 'get_contact_settings',
    endPointEdit: 'edit_contact_settings'
  },
  sendEmails: 'send_emails',
  customFieldDefinitions: {
    endPointEdit: 'edit_custom_field_definitions',
    endPointDelete: 'delete_custom_field_definition'
  },
  invoices: {
    endPointGet: 'get_invoice',
    elementNamePlural: 'invoices'
  },
  customNorms: {
    elementNamePlural: 'customNorms',
    idKey: NORM_KEY
  },
  dimensionLogics: {
    elementNamePlural: 'dimensionLogics',
    idKey: 'dimLogicNr'
  },
  customScreens: {
    elementNamePlural: 'customScreens',
    idKey: SCREEN_KEY
  },
  ptInvitationTemplates: {
    idKey: PT_INVITATION_KEY
  },
  reminders: {
    elementNamePlural: 'reminders',
    idKey: 'reminderNr',
    endPointEdit: 'save_reminder',
    endPointArchiveDelete: 'delete_reminder',
    endPointChangeStatus: 'change_reminder_status',
    endPointDuplicate: 'duplicate_reminder'
  },
  participantMailLogs: {
    elementNamePlural: 'participantMailLogs',
    idKey: 'email_nr',
    endPointArchiveDelete: 'archive_delete_participant_mail_logs',
    endPointRestore: 'unarchive_participant_mail_logs'
  }
}

export const ENTITIES = {
  processes: API_DATA.processes.elementNamePlural,
  assessments: API_DATA.assessments.elementNamePlural,
  participants: API_DATA.participants.elementNamePlural,
  pts: API_DATA.pts.elementNamePlural,
  usedConfigs: API_DATA.usedConfigs.elementNamePlural,
  bookings: API_DATA.creditBookings.elementNamePlural,
  users: 'users',
  credits: 'credits',
  configs: 'configs',
  tans: 'tans',
  accessData: 'accessData',
  accessDataHub: 'accessDataHub',
  ptResults: 'pt-results',
  emails: 'emails',
  invoices: 'invoices',
  results: 'results',
  reminders: 'reminders',
  participantMailLogs: 'participantMailLogs'
}

export const SUCCESS_PAGE_TYPES = {
  newParticipants: 1,
  ptsOnly: 2
}

export const BULK_ACTIONS = {
  setCapabilities: 1,
  addToOtherTest: 2,
  delete: 3,
  archive: 4,
  unarchive: 5,
  startTimer: 6,
  resetTimer: 7,
  downloadSelectionAsCsv: 9,
  deactivate: 10,
  activate: 11,
  sendEmail: 12,
  resendEmail: 13
}

export const BULK_EDITABLE_FIELDS = {
  elektryonTemplates: 1,
  validFrom: 2,
  validUntil: 3,
  invitedDuration: 4,
  startedDuration: 5,
  isInvited: 6,
  isVisible: 7,
  pAnon: 8,
  invitationStatus: 9,
  norm: 10,
  startScreen: 11,
  endScreen: 12
}

export const EMAIL_VALIDATION_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const USER_CAPABILITIES = {
  none: 0,
  view: 1,
  edit: 2
}

export const STATUS_BAR_TYPES = {
  success: 1,
  error: 2,
  notice: 3,
  loading: 4
}

export const FORM_ERRORS = {
  nameAlreadyInUse: 'nameAlreadyInUse',
  nameAlreadyRequested: 'nameAlreadyRequested',
  nameEmpty: 'nameEmpty',
  emailAlreadyRequested: 'emailAlreadyRequested',
  emailAlreadyInUse: 'emailAlreadyInUse',
  emailInvalid: 'emailInvalid',
  emailInvalidShort: 'emailInvalidShort',
  newPasswordTooShort: 'newPasswordTooShort',
  fieldEmpty: 'fieldEmpty',
  labelIsDuplicate: 'labelIsDuplicate',
  forbiddenCharacter: 'forbiddenCharacter',
  testNameCannotBeEmpty: 'testNameCannotBeEmpty',
  enterProcessName: 'enterProcessName',
  endTimeBeforeStartTime: 'endTimeBeforeStartTime',
  oldPasswordIncorrent: 'oldPasswordIncorrect',
  enterPassword: 'enterPassword'
}

export const CUSTOMER_VISIBILITY_STATUS = {
  visible: 1,
  archived: 2,
  deleted: 3
}

export const STATUS_BAR_DURATION = 4000

export const BULK_PARAMS = {
  archived: [{ name: 'visibility', value: CUSTOMER_VISIBILITY_STATUS.archived }],
  visible: [{ name: 'visibility', value: CUSTOMER_VISIBILITY_STATUS.visible }],
  deleted: [{ name: 'visibility', value: CUSTOMER_VISIBILITY_STATUS.deleted }],
  deactivate: [{ name: 'ptDeactivated', value: true }],
  activate: [{ name: 'ptDeactivated', value: false }]
}

export const LANGUAGE_NAMES = {
  1: 'germanFormal',
  2: 'english',
  3: 'turkish',
  4: 'arabic',
  5: 'french',
  6: 'germanInformal'
}

export const LOCALES = {
  1: 'de',
  2: 'en',
  3: 'tr',
  4: 'ar',
  5: 'fr',
  6: 'ded'
}

export const LANGUAGE_IDS = {
  germanFormal: 1,
  english: 2,
  turkish: 3,
  arabic: 4,
  french: 5,
  germanInformal: 6
}

export const languageSortingOrder = [
  LANGUAGE_IDS.germanFormal,
  LANGUAGE_IDS.germanInformal,
  LANGUAGE_IDS.english,
  LANGUAGE_IDS.french
]

export const ERROR_MSG = <span style={{ color: 'var(--error-message-color)' }}>- Error -</span>

export const CONFIG_VARIANTS = [
  {
    id: 'Standard',
    headline: 'Standard',
    description: 'descriptionStandard'
  },
  {
    id: 'QuickScan',
    headline: 'QuickScan',
    description: 'descriptionQuickScan'
  },
  {
    id: 'Cross-Test',
    headline: 'Crosstest',
    description: 'descriptionCrosstest'
  },
  {
    id: 'Demo',
    headline: 'Demo',
    description: 'descriptionDemo'
  }
]

export const ANONYM_MSG = 'anonymous'
export const PARTICIPANT_SPECIAL_NAMES = ['ANONYM', '']

export const BOOKING_KEYS = {
  pt_finished: 1,
  pt_reset: 2,
  credit_charge_perseo: 3,
  credit_charge_contact: 4,
  credit_charge_monthly: 5,
  misc: 99
}

export const TEST_TYPE_POTENTIALS = 'Potentials'
export const TEST_TYPE_TRAITS = 'Traits'
export const TEST_TYPE_GOALS = 'Goals'
export const TEST_TYPE_POTENTIALS_CROSS = 'Potentials Cross-Test'
export const TEST_TYPE_LATERAL = 'Laterale Führung'

export const PT_STATUS = {
  added: 0,
  ready: 1,
  started: 2,
  finished: 3,
  waiting: 4,
  deactivated: 5,
  rejected: 6,
  expired: 7
}

export const PT_STATUS_NAMES = {
  0: 'added',
  1: 'ready',
  2: 'started',
  3: 'finished',
  4: 'waiting',
  5: 'deactivated',
  6: 'excluded',
  7: 'expired'
}

export const CREDIT_PRICES = {
  prices_2022: [
    { count: 1, price: 3.5 },
    { count: 100, price: 3.0 },
    { count: 500, price: 2.7 },
    { count: 1000, price: 2.5 },
    { count: 2000, price: 2.3 },
    { count: 5000, price: 2.1 }
  ],
  prices_2023: [
    { count: 1, price: 3.8 },
    { count: 100, price: 3.2 },
    { count: 500, price: 2.85 },
    { count: 1000, price: 2.65 },
    { count: 2000, price: 2.4 },
    { count: 5000, price: 2.2 }
  ]
}

export const TAB_TRACKING_INDEX = {
  noWindowChanges: 1,
  someWindowChanges: 2,
  manyWindowChanges: 3
}

export const PAYMENT_METHOD_INVOICE = 'invoice'
export const PAYMENT_METHOD_PAYPAL = 'paypal'
export const PAYMENT_METHOD_CREDIT_CARD = 'creditCard'

export const PAYMENT_OPTIONS = [
  {
    title: PAYMENT_METHOD_INVOICE,
    content: 'invoice',
    icon: 'svg-icon icon-invoice',
    hint: 'receiveInvoiceByEmail'
  },
  {
    title: PAYMENT_METHOD_PAYPAL,
    content: 'PayPal',
    icon: 'svg-icon icon-paypal',
    hint: 'redirectToPayPal'
  },
  {
    title: PAYMENT_METHOD_CREDIT_CARD,
    content: 'creditCard',
    icon: 'svg-icon icon-credit-card',
    hint: 'creditCardHint'
  }
]

export const MANDATORY_BILLING_FIELDS = [
  'billingCustomer',
  'billingStreet',
  'billingZipCode',
  'billingCity',
  'billingCountry',
  'billingEmail'
]

export const TRAITS_TARGET_GROUPS = {
  junior: 1,
  professional: 2,
  leadership: 3,
  sales: 4
}

export const NUMBER_INPUT_ALLOWED_KEYS = [
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  'Backspace',
  'ArrowLeft',
  'ArrowRight',
  'Insert',
  'Delete',
  'Tab'
]

export const EMAIL_NOTIFICATION_OPTIONS = [
  {
    id: 1,
    label: 'immediately'
  },
  {
    id: 2,
    label: 'dailyFrequency'
  },
  {
    id: 3,
    label: 'weeklyFrequency'
  },
  {
    id: 4,
    label: 'monthlyFrequency'
  },
  {
    id: 5,
    label: 'never'
  }
]

export const DASHBOARD_SHOW_RESULTS_LIMIT = 4
export const UPDATE_ALL_DATA_SECONDS = 20

export const THE_PAST = '1900-01-01 00:00:00'
export const THE_FUTURE = '2100-01-01 00:00:00'

export const TABLE_PAGE_SIZE_OPTIONS = [25, 50, 100]

export const PARTICIPANT_UPLOAD_HEADERS = [
  { androName: 'firstname', label: FIELD_LABELS.firstName },
  { androName: 'lastname', label: FIELD_LABELS.lastName },
  { androName: 'email', label: FIELD_LABELS.email },
  { androName: 'gender', label: FIELD_LABELS.gender }
]

export const EMAIL_TEXTS = {
  invitationSubject: 'invitationMailSubject',
  reminderSubject: 'reminderMailSubject',
  reminderSubjectCasual: 'reminderMailSubjectCasual'
}

export const EMAIL_FROM_ADDRESS = 'service@perseo-assessment.de'
export const EMAIL_FROM_NAME = {
  de: 'PERSEO Eignungstests',
  en: 'PERSEO Assessment'
}

export const GLOBAL_STATUS_KEYS = {
  invitationStatus: 'invitation'
}
export const CUSTOM_CONFIG_LABEL = 'customTest'

export const NO_CHANGE_LABEL = 'noChanges'

export const MODAL_TYPES = {
  notification: 'notification-modal',
  alert: 'alert-modal',
  payment: 'payment',
  deleteAccount: 'delete-account',
  help: 'help',
  mailPreview: 'mail-preview'
}

export const EMAIL_TYPES = {
  invitation: 1,
  reminder: 2
}

export const EMAIL_STATUS = {
  fail: 0,
  success: 1
}

export const MAX_CHARS = {
  processName: 60,
  processDescription: 100,
  testName: 60,
  testDescription: 100,
  default: 250
}

export const PRIVATE_MAIL_DOMAINS = [
  'gmail',
  'googlemail',
  'aol',
  'gmx',
  'freenet',
  'web',
  'yahoo',
  't-online',
  'outlook',
  '1und1',
  'hotmail',
  'icloud',
  'posteo',
  'trashmail',
  'muellmail',
  'mail',
  'live'
]
export const MissingReferenceNotice = () => {
  const t = useTranslate()
  return <span style={{ color: 'var(--text-color-secondary)', fontStyle: 'italic' }}>{t('noReference')}</span>
}

export const PECULIARITY_LEVELS = {
  low: 1,
  medium: 2,
  high: 3
}

export const BREAKPOINT_MOBILE = 600

export const GERMAN_ZIP_REGEX = /^(?!01000|99999)(0[1-9]\d{3}|[1-9]\d{4})$/
export const NUMBERS_ONLY_REGEX = /^[0-9]*$/

export const PASSWORD_MIN_LENGTH = 8

export const UNPERMITTED_AIDS_RESULTS = {
  questionNotShown: null,
  didNotUseUnpermittedAids: 1,
  usedUnpermittedAids: 2
}

export const PAYPAL_SUCCESS_STATUS = 'COMPLETED'
export const PAYPAL_SANDBOX_CLIENT_ID =
  'ASXQeXXMVuM6W_7TmOvOVnFmImGuGNeqYlyL6GywrIl28eV98hvFdcaTkie7uiDQsUvHl9HBwvQktbJv'
export const PAYPAL_LIVE_CLIENT_ID = 'ASuHAbDDW0EfDje4h1ZGJue7hRNiVET8IV5R2B_jVQkjDuZg3RpiUMB_B-McMlJPbjfJ6uNmuT8_IYIW'

export const STRIPE_API_TEST_KEY =
  'pk_test_51OcNCGIUyCROHUxvhwDxxbvnGcRa5NlVG58H7WTPVI9OrJQgi6CAuPAX9UHen8Jd1Nw0VbRkaZj5j6Hx0HoAsJSI00UxNI2sQd'
export const STRIPE_API_LIVE_KEY =
  'pk_live_51OcNCGIUyCROHUxvZG3uAJ2LyPgedWFUAccRlWOiQpXTmj7Koe3IQcnbAB1dRkFJ4EaIDSWYAAVqzERPKPulWnNW00lxb8ESO8'

export const GERMAN_VAT_TAX_RATE = 0.19

export const DEFAULT_LANGUAGE = 'de'
export const DEFAULT_DISPLAY_MODE = 'light'

export const LANG_CODES = {
  de: 'de-DE',
  en: 'en-US'
}

export const EXCEL_FILE_TYPES = [
  'application/vnd.ms-excel', // '.xls'
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' // '.xlsx'
]

export const CSV_FILE_TYPES = ['text/csv', 'text/plain']

export const SALUTATION_TYPES = {
  politeLastName: 'politeLastName',
  casualLastName: 'casualLastName',
  casualFirstName: 'casualFirstName',
  helloFirstName: 'helloFirstName',
  none: 'none'
}

export const FILTER_TYPES = {
  textInput: 'textInput',
  datePicker: 'datePicker',
  switch: 'switch',
  select: 'select'
}

export const FIELD_CATEGORIES = {
  process: 'process',
  test: 'test',
  participant: 'participant',
  pt: 'pt',
  customFields: 'customFields',
  booking: 'booking',
  invoice: 'invoice',
  email: 'email'
}

export const CONTENT_TYPES = {
  time: 'time',
  number: 'number',
  text: 'text',
  date: 'date'
}

export const genderLabels = { m: 'maleGender', f: 'femaleGender', d: 'diverseGender', n: 'notSpecified' }

export const INVOICE_STATUS_PAID = 3
export const INVOICE_STATUS_WARNING = [4, 5, 6, 7]

export const INVOICE_STATUS_NAMES = {
  1: 'draft',
  2: 'sent',
  3: 'paid',
  4: 'overdue',
  5: 'overdue', // reminder 1
  6: 'overdue', // reminder 2
  7: 'overdue', // reminder 3
  8: 'canceled',
  9: 'refunded'
}

export const DEFAULT_NORM_NR = 'default_norm'
export const DEFAULT_NORM_NR_TRAITS = 'default_norm_traits'
export const NEW_NORM_NR = 'new_norm'
export const DEFAULT_DIM_LOGIC_NR = 'default_logic'
export const NEW_DIM_LOGIC_NR = 'new_logic'

export const DEFAULT_SCREEN_NR_START = 'default_start_screen'
export const DEFAULT_SCREEN_NR_END = 'default_end_screen'
export const NEW_SCREEN_NR = 'new_screen'

export const NEW_TEMPLATE_NR = 'new_template'
export const DEFAULT_TEMPLATE_NR = 'default_invitation'

export const SCREEN_TYPE_START = 'startScreen'
export const SCREEN_TYPE_END = 'endScreen'

export const START_SCREEN_KEY = 'screenNrStart'
export const END_SCREEN_KEY = 'screenNrEnd'

export const PERSEO_DEFAULT = 'PERSEO Standard'
