import React, { useContext, useMemo } from 'react'
import DataTable from '../../components/table/DataTable'
import ProcessRowSubComponent from './ProcessRowSubComponent'
import { ContentContainer } from '../../components/table/tableElements'
import { ENTITIES } from '../../utils/constants/constants'
import { AppContext } from '../../utils/context/AppContext'
import { getProcessTableCols } from './processUtils'
import ScrollContainer from '../../components/scrollContainer/ScrollContainer'

const Processes = () => {
  const { completeDataSet, loggedInAsAdmin } = useContext(AppContext)
  const columns = useMemo(() => getProcessTableCols(), [completeDataSet.customTableSettings.processes])

  return (
    <ScrollContainer type="main">
      <ContentContainer>
        <DataTable
          data={completeDataSet.processes}
          columns={columns}
          RowSubComponent={ProcessRowSubComponent}
          showRowSelection
          tableName={ENTITIES.processes}
          hasSubrows
          showAddButton={loggedInAsAdmin}
          showColumnController
          showFilters
        />
      </ContentContainer>
    </ScrollContainer>
  )
}

export default Processes
