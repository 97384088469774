import { isTraitsOrGoals } from '../utils/helper/Helper'
import { createDate } from '../utils/helper/dateTimeHelper'

export class TestConfig {
  constructor({ ...props }) {
    this.entity = 'TestConfig'
    this.availableLanguages = props.availableLanguages
    this.configCreditsCost = props.configCreditsCost
    this.configDuration = props.configDuration
    this.configName = props.configName
    this.configType = props.configType
    this.configUuid = props.configUuid
    this.configVariant = props.configVariant
    this.configVersion = props.configVersion
    this.configVersionComment = props.configVersionComment
    this.created = createDate(props.created)
    this.dimensions = props.dimensions
    this.modified = createDate(props.modified)
    this.norm = props.norm
    this.parentConfig = props.parentConfig
    this.referenceGroup = props.referenceGroup
    this.isLatest = props.isLatest
  }
  getModuleCount() {
    const visibleDimensions = this.dimensions.filter((dim) => dim.dimensionVisibility)

    if (isTraitsOrGoals(this.configType)) {
      return visibleDimensions.length
    }

    const modules = visibleDimensions.map((dim) => dim.subtests.length)
    const moduleCount = modules.reduce((prev, curr) => prev + curr, 0)
    return moduleCount
  }
  isDemo() {
    return this.configVariant === 'Demo'
  }
  isCrossTest() {
    return this.configVariant === 'Cross-Test'
  }
  isQuickScan() {
    return this.configVariant === 'QuickScan'
  }
  isStandard() {
    return this.configVariant === 'Standard'
  }
}
