import React from 'react'
import { TileContainer } from '../table/tableElements'
import ScreenSelectTile from '../tiles/ScreenSelectTile'

const ScreensTab = ({ assessment }) => {
  return (
    <TileContainer style={{ maxWidth: '950px' }}>
      <ScreenSelectTile assessment={assessment} type="start"/>
      <ScreenSelectTile assessment={assessment} type="end"/>
    </TileContainer>
  )
}
export default ScreensTab
