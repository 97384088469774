import { DEFAULT_NORM_NR, DEFAULT_NORM_NR_TRAITS } from '../../../utils/constants/constants'

const getDefaultNorms = (t) => {
  return [
    {
      name: t('standardTemplate'),
      normNr: DEFAULT_NORM_NR,
      disabled: true,
      id: DEFAULT_NORM_NR,
      createdBy: 'PERSEO',
      normTable: [
        {
          lower_limit: 0,
          upper_limit: 30,
          grade: '5',
          verbalisation: 'Eine weitere Teilnahme am Verfahren kann nicht empfohlen werden.'
        },
        {
          lower_limit: 30,
          upper_limit: 40,
          grade: '4',
          verbalisation: 'Eine weitere Teilnahme am Verfahren kann mit großen Einschränkungen empfohlen werden.'
        },
        {
          lower_limit: 40,
          upper_limit: 50,
          grade: '3',
          verbalisation: 'Eine weitere Teilnahme am Verfahren kann mit Einschränkungen empfohlen werden.'
        },
        {
          lower_limit: 50,
          upper_limit: 60,
          grade: '2',
          verbalisation: 'Eine weitere Teilnahme am Verfahren kann empfohlen werden.'
        },
        {
          lower_limit: 60,
          upper_limit: 100,
          grade: '1',
          verbalisation: 'Eine weitere Teilnahme am Verfahren kann bedenkenlos empfohlen werden.'
        }
      ]
    },
    {
      name: t('standardTemplateTraits'),
      normNr: DEFAULT_NORM_NR_TRAITS,
      disabled: true,
      id: DEFAULT_NORM_NR_TRAITS,
      createdBy: 'PERSEO',
      normTable: [
        {
          lower_limit: 0,
          upper_limit: 30,
          grade: '5',
          verbalisation:
            'Sehr schlechte Passung zwischen Persönlichkeitsprofil und Stellenanforderungen. Eine weitere Teilnahme am Verfahren kann nicht empfohlen werden.'
        },
        {
          lower_limit: 30,
          upper_limit: 50,
          grade: '4',
          verbalisation:
            'Schlechte Passung zwischen Persönlichkeitsprofil und Stellenanforderungen. Eine weitere Teilnahme am Verfahren kann mit großen Einschränkungen empfohlen werden.'
        },
        {
          lower_limit: 50,
          upper_limit: 70,
          grade: '3',
          verbalisation:
            'Mittlere Passung zwischen Persönlichkeitsprofil und Stellenanforderungen. Eine weitere Teilnahme am Verfahren kann mit Einschränkungen empfohlen werden.'
        },
        {
          lower_limit: 70,
          upper_limit: 90,
          grade: '2',
          verbalisation:
            'Gute Passung zwischen Persönlichkeitsprofil und Stellenanforderungen. Eine weitere Teilnahme am Verfahren kann empfohlen werden.'
        },
        {
          lower_limit: 90,
          upper_limit: 100,
          grade: '1',
          verbalisation:
            'Sehr gute Passung zwischen Persönlichkeitsprofil und Stellenanforderungen. Eine weitere Teilnahme am Verfahren kann bedenkenlos empfohlen werden.'
        }
      ]
    }
  ]
}

export const getAllNorms = (customNorms, t) => {
  const defaultNorms = getDefaultNorms(t)
  return [...defaultNorms, ...customNorms].map((n) => ({
    ...n,
    isDirty: false
  }))
}

export const getNormOptions = (allNorms) => {
  return allNorms.map((norm) => ({
    label: norm.name,
    value: norm.normNr
  }))
}

export const getCorrectNorm = (assessment, normNr, customNorms) => {
  const customNorm = customNorms.find((cn) => cn.normNr === normNr)
  const defaultNorm = assessment.relatedConfig.norm

  return customNorm || defaultNorm
}

export const NAME_MAX_CHARS = 45
