import { PASSWORD_MIN_LENGTH } from '../../../utils/constants/constants'
import useTranslate from '../../../utils/hooks/useTranslate'
import NormsHelp from '../../helpModal/NormsHelp'
import ScreensHelp from '../../helpModal/ScreensHelp'
import { Entry, TimeLineContainer } from '../../timeline/timeLineElements'
import { B, Table, WizardStep, WizardStepContainer } from '../userManualElements'

const Chapter5English = () => {
  const t = useTranslate()
  return (
    <>
      <h2 id="chapter-5">5 Settings</h2>
      <p>
        To access the settings, first open the menu by clicking on <B>Menu</B> in the top right corner of the menu bar.
        Then click on the <B>Settings</B> option. A second navigation bar will open on the left side, allowing you to
        navigate to different settings pages.
      </p>
      <h3 id="chapter-5-1">5.1 Company</h3>
      <h4 id="chapter-5-1-1">5.1.1 Account Settings</h4>
      <h5>Company Data</h5>
      <p>
        Here you can enter and edit the master data for your company / organization. This function is available only to
        administrators.
      </p>
      <h5>Billing Data</h5>
      <p>
        As an administrator, you can enter and edit billing data here. In addition to the standard fields, you can
        create two additional fields with labels and content. For example, if you need an order number on the invoice,
        enter „Order Number“ under <em>Additional Field 1 Label</em> and the required number under{' '}
        <em>Additional Field 1 Content</em>.
      </p>
      <h5>Account</h5>
      <p>
        As an administrator, you can delete your company account here. Please note that all data will be lost. You can
        delete your user account in the user management.
      </p>
      <h4 id="chapter-5-1-2">5.1.2 User Management</h4>
      <p>As an administrator, you can view and manage all users of the system here.</p>
      <h5>Set Permissions</h5>
      <p>Here you can set permissions per process. The following three options are available:</p>
      <UserRolesTable />
      <div className="textbox important">
        <p>Users with administrator permissions are automatically editors for all processes.</p>
      </div>
      <h5>Settings and Actions</h5>
      <p>
        Under <em>Personal Data</em>, you can edit the user&apos;s master data and grant or remove administrator
        permissions.
      </p>
      <p>
        Under <em>Actions</em>, you can delete the user account. Deleted users can no longer log in and will not receive
        any notifications. If more than one user is assigned to the customer account, you can also delete your own user
        account here. You will be automatically logged out afterward. Deleted users can be re-added at any time.
      </p>
      <h5>Create User</h5>
      <p>
        To open the <em>Create User</em> process, click on <B>Menu</B> at the top right corner of the window, then click
        on
        <B>Settings</B>, and then on <B>User Management</B>. Finally, click on <B>Create New User</B>.
      </p>
      <TimeLineContainer className="wizard">
        <Entry>
          <WizardStepContainer>
            <WizardStep>1. User Data</WizardStep>
            <ul>
              <li>Enter the data for the new user in the form.</li>
              <li>
                Choose whether the new user should have administrator permissions. If the new user receives
                administrator permissions, the following page will be skipped.
              </li>
            </ul>
          </WizardStepContainer>
        </Entry>
        <Entry>
          <WizardStepContainer>
            <WizardStep>2. Set Permissions</WizardStep>
            <ul>
              <li>Set permissions for the new user.</li>
            </ul>
          </WizardStepContainer>
        </Entry>
        <Entry>
          <WizardStepContainer>
            <WizardStep>3. Review Entries</WizardStep>
            <ul>
              <li>Review your entries.</li>
              <li>
                Click <B>Invite User</B>. The user will then receive an email with their access credentials.
              </li>
            </ul>
          </WizardStepContainer>
        </Entry>
      </TimeLineContainer>

      <h4 id="chapter-5-1-3">5.1.3 {t('dataMaintenance')}</h4>
      <h5>{t('automaticAnonymization')}</h5>
      <p>{t('anonymizationExplanation')}</p>
      <h5>Automatic Archiving</h5>
      <p>{t('archiveExplanation')}</p>

      <h4 id="chapter-5-1-4">5.1.4 {t('customization')}</h4>

      <h5>Custom Fields</h5>
      <p>
        Here you can create custom fields for your participants. In the participant overview, you can enter data into
        your fields and then filter and sort based on them. You can also upload data into your fields via CSV upload,
        such as external IDs from an applicant management system.
      </p>
      <CustomFieldsTable />

      <h5>{t('gradingRules')}</h5>
      <NormsHelp />

      <h5>{t('customScreens')}</h5>
      <ScreensHelp />

      <h4 id="chapter-5-1-5">5.1.5 Email Settings</h4>

      <h5>Email Templates</h5>
      <p>Here, you can create and save your own email templates.</p>
      <p>
        Start by selecting an existing template from the list. Afterward, you can customize the email subject,
        salutation, and two free-text fields. The login credentials will be automatically inserted between the two
        free-text fields. To save your template, click on <B>Save...</B>.
      </p>

      <h5>Automatic Reminders</h5>
      <p>
        Here you have the option to create and manage automatic email reminders for your tests. Reminders
        with the status <em>Active</em> are sent automatically at the specified sending time.
      </p>
      <p>
        To add a new reminder, click on <B>+ Reminder</B>.
      </p>
      <TimeLineContainer className="wizard">
        <Entry>
          <WizardStepContainer>
            <WizardStep>1. Subject and Content</WizardStep>
            <ul>
              <li>You can customize the subject, salutation, and two free-text fields.</li>
              <li>The login credentials will automatically be inserted between the two free-text fields.</li>
            </ul>
          </WizardStepContainer>
        </Entry>
        <Entry>
          <WizardStepContainer>
            <WizardStep>2. Preview</WizardStep>
            <ul>
              <li>A preview of your email will be displayed on this page.</li>
              <li>
                You can modify the preview data under <em>Data for Preview</em>.
              </li>
              <li>
                To send a sample email, enter an email address in the corresponding field in the sidebar and press{' '}
                <B>Send Now</B>.
              </li>
            </ul>
          </WizardStepContainer>
        </Entry>
        <Entry>
          <WizardStepContainer>
            <WizardStep>3. Settings</WizardStep>
            <ul>
              <li>Give the reminder a meaningful title.</li>
              <li>
                Optionally, you can enter a name and an email address for the <em>Reply-To</em> field. If the recipient
                clicks <B>Reply</B>, the response will be sent to the address entered here.
              </li>
              <li>
                Specify the sending time. If you select the <em>after invitation</em> option, only participants who
                previously received an invitation email will be reminded. If you select the <em>before expiration</em>{' '}
                option, the test must have an expiration date set.
              </li>
              <li>Choose whether the reminder should be activated immediately after creation.</li>
            </ul>
          </WizardStepContainer>
        </Entry>
        <Entry>
          <WizardStepContainer>
            <WizardStep>4. Select Tests</WizardStep>
            <ul>
              <li>Select at least one test for which the reminders should be sent.</li>
            </ul>
          </WizardStepContainer>
        </Entry>
        <Entry>
          <WizardStepContainer>
            <WizardStep>5. Review Inputs</WizardStep>
            <ul>
              <li>Review your inputs.</li>
              <li>
                Click on <B>Save</B>.
              </li>
            </ul>
          </WizardStepContainer>
        </Entry>
      </TimeLineContainer>

      <h3 id="chapter-5-2">5.2 Users</h3>
      <h4 id="chapter-5-2-1">5.2.1 User Settings</h4>
      <h5>Personal Data</h5>
      <p>
        Here you can view and edit your personal data. Note that the email address entered here serves as the login for
        the customer area.
      </p>
      <h5>Password</h5>
      <p>
        Here you can change your password for login. Enter your old password first, followed by the new password in the
        designated fields. The password must be at least {PASSWORD_MIN_LENGTH} characters long.
      </p>
      <h4 id="chapter-5-2-2">5.2.2 Notifications</h4>
      <h5>Completed Tests</h5>
      <p>
        Here you can set how often you want to be notified by email about newly completed tests. The notification
        includes all tests visible to you.
      </p>
      <h5>Newsletter</h5>
      <p>
        Here you can subscribe to and unsubscribe from our newsletter. In the newsletter, we inform you about news and
        changes to our tests and software approximately twice a year.
      </p>
      <h5>Start Messages</h5>
      <p>Here you can find all previous startup messages and review them.</p>
      <h4 id="chapter-5-2-3">5.2.3 Display and Language</h4>
      <p>
        Here you can switch between light and dark mode and set the language for the customer area. Please note that the
        language selected here only affects the customer area. You can set the language of the tests in the test
        options.
      </p>
    </>
  )
}

export default Chapter5English

const UserRolesTable = () => {
  const header = ['Option', 'Description']
  const data = [
    ['No Access', 'Process is not visible to this user.'],
    ['Viewer', 'User can view the process but cannot edit it.'],
    ['Editor', 'User can view and edit the process.']
  ]
  return <Table name="user-roles" {...{ header, data }} />
}

const CustomFieldsTable = () => {
  const header = ['Field', 'Description']
  const data = [
    ['Label', 'The name of your new field.'],
    ['Type', 'Choose between text input and dropdown field.'],
    [
      'Options',
      'If you want to create a dropdown field, you can enter the available options here. Options should be separated by commas.'
    ],
    [
      'Field Key',
      'The field key is used to reference your field technically. You need the field key when uploading data into your custom field via CSV upload.'
    ]
  ]
  return <Table name="user-roles" {...{ header, data }} />
}
