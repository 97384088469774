import { useEffect, useState } from 'react'

const useStickyColumnShadow = (width, visibleColumns, page, scrollPosition) => {
  const [shadow, setShadow] = useState({
    checkboxCol: false,
    actionsCol: false,
    shadowCol: false
  })

  const SAFETY_OFFSET = 1

  useEffect(() => {
    const checkboxColumn = document.querySelectorAll('.checkbox-col')[0]
    const actionsColumn = document.querySelectorAll('.actions-col')[0]
    const shadowColumn = document.querySelectorAll('.shadow-col')[0]

    if (checkboxColumn) {
      const lastStickyColumn = document.querySelectorAll('.data-table th:nth-child(2)')[0]
      const checkboxColumnRect = checkboxColumn.getBoundingClientRect()
      const lastColumnRect = lastStickyColumn.getBoundingClientRect()
      const hasOverlap = checkboxColumnRect.right - SAFETY_OFFSET > lastColumnRect.left
      setShadow((prev) => ({
        ...prev,
        checkboxCol: hasOverlap
      }))
    }

    if (shadowColumn) {
      const lastStickyColumn = document.querySelectorAll('.data-table th:nth-child(2)')[0]
      const shadowColumnRect = shadowColumn.getBoundingClientRect()
      const lastColumnRect = lastStickyColumn.getBoundingClientRect()
      const hasOverlap = shadowColumnRect.right - SAFETY_OFFSET > lastColumnRect.left

      setShadow((prev) => ({
        ...prev,
        shadowCol: hasOverlap
      }))
    }

    if (actionsColumn) {
      const lastStickyColumn = document.querySelectorAll('.data-table th:nth-last-child(3)')[0]
      const actionsColumnRect = actionsColumn.getBoundingClientRect()
      const lastColumnRect = lastStickyColumn.getBoundingClientRect()
      const hasOverlap = actionsColumnRect.left + SAFETY_OFFSET < lastColumnRect.right
      setShadow((prev) => ({
        ...prev,
        actionsCol: hasOverlap
      }))
    }
  }, [width, visibleColumns, page, scrollPosition])

  return shadow
}

export default useStickyColumnShadow
