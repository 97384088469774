import { useContext, useEffect, useState } from 'react'
import { AppContext } from '../../../utils/context/AppContext'
import useTranslate from '../../../utils/hooks/useTranslate'
import useEventListener from '../../../utils/hooks/useEventListener'
import { getWeightsSum } from '../processResultSettingsTile/processResultUtils'
import {
  fetchData,
  sanitizePercentValue,
  setStatusBar,
  updateEntities,
  updateResults
} from '../../../utils/helper/Helper'
import { DEFAULT_DIM_LOGIC_NR, STATUS_BAR_TYPES } from '../../../utils/constants/constants'
import { EditDiscardButton, EditSaveButton, Icon } from '../../customInput/customInputElements'
import styled from 'styled-components'
import MatchingScale from './MatchingScale'
import { GreyButton } from '../../../utils/elements/miscElements'

const DimensionDisplay = ({ config, assessment, currentDimLogicNr, editBlocked }) => {
  const context = useContext(AppContext)
  const { language, completeDataSet } = context
  const t = useTranslate()
  const initialData = getInitialData(config, currentDimLogicNr, completeDataSet)
  const canEdit = assessment.relatedProcess.isEditor() && !editBlocked

  const [data, setData] = useState(initialData)
  const [editMode, setEditMode] = useState(false)
  const hasMatchpoints = data.some((dim) => dim.matchPoints)

  const handleKeyDown = (event) => {
    if (event.code === 'Escape') {
      event.preventDefault()
      setData(initialData)
      setEditMode(false)
    }
    if (event.code === 'Enter') {
      if (editMode) {
        event.preventDefault()
        handleSave()
      }
    }
  }

  useEventListener('keydown', handleKeyDown)

  useEffect(() => {
    setData(initialData)
  }, [currentDimLogicNr])

  const sum = getWeightsSum(data.map((entry) => entry.weight))
  const isValid = sum === 100

  const handleWeightChange = (value, index) => {
    const sanitizedValue = sanitizePercentValue(value)
    const updatedWeights = data.map((dim, i) => ({
      ...dim,
      weight: i === index ? sanitizedValue : dim.weight
    }))
    setData(updatedWeights)
  }

  const handleSave = async () => {
    await saveDimensionLogic(context, assessment, data)
    setEditMode(false)
    setStatusBar({
      controller: context.statusBarController,
      type: STATUS_BAR_TYPES.success,
      text: 'ruleSavedSuccessfully',
      setVisible: true
    })
  }

  const infoText = hasMatchpoints ? 'profileSaveHint' : 'weightSaveHint'
  const editButtonText = hasMatchpoints ? 'editProfile' : 'editScheme'

  return (
    <div>
      <div className="table-outer-container matching-norm-table">
        <div className="table-inner-container">
          <WeightTable className="weight-table" hasMatchpoints={hasMatchpoints} editMode={editMode}>
            <thead>
              <tr>
                <th>Dimension</th>
                {hasMatchpoints && <th className="match-points">{t('scoreLimits')}</th>}
                <th className="weight">{t('weight')}</th>
              </tr>
            </thead>
            <tbody>
              {config.dimensions.map((dimension, index) => (
                <tr key={dimension.dimensionNr}>
                  <td>{dimension.dimensionName[language]}</td>
                  {hasMatchpoints && (
                    <td className="match-points">
                      <MatchingScale
                        rowIndex={index}
                        matchPoints={data[index].matchPoints}
                        setData={setData}
                        editMode={editMode}
                      />
                    </td>
                  )}
                  <td className="weight">
                    {editMode ? (
                      <WeightInput
                        type="text"
                        className={!isValid ? 'has-error' : ''}
                        value={data[index].weight}
                        onChange={(e) => handleWeightChange(e.target.value, index)}
                      />
                    ) : (
                      <WeightDisplay>{data[index].weight} %</WeightDisplay>
                    )}
                  </td>
                </tr>
              ))}
              {editMode && (
                <tr className="footer">
                  <td>{t('sum')}</td>
                  {hasMatchpoints && <td className="match-points"></td>}
                  <td className="weight">{!isNaN(sum) ? sum : ''} %</td>
                </tr>
              )}
            </tbody>
          </WeightTable>
        </div>
      </div>

      {editMode && (
        <>
          <ButtonContainer>
            <InfoText className="text-secondary">{t(infoText)}</InfoText>
            <EditDiscardButton
              onClick={() => {
                setData(initialData)
                setEditMode(false)
              }}>
              <Icon red style={{ transform: 'scale(0.7)' }} className="svg-icon icon-x-noborder-mask" />
            </EditDiscardButton>
            <EditSaveButton disabled={!isValid} onClick={handleSave}>
              <Icon className="svg-icon icon-check-noborder-mask" />
            </EditSaveButton>
          </ButtonContainer>
        </>
      )}
      {!editMode && canEdit && (
        <EditButton onClick={() => setEditMode(!editMode)}>
          <span>{t(editButtonText)}</span>
        </EditButton>
      )}
    </div>
  )
}

export default DimensionDisplay

const EditButton = styled(GreyButton)`
  font-size: var(--fs-2);
  margin-top: var(--space-3);
  border-radius: var(--bdr-2);
  padding: var(--space-2) var(--space-3);
  height: auto;
  &:hover {
    background-color: var(--small-button-hover-color);
  }
`
const saveDimensionLogic = async (context, assessment, data) => {
  const { completeDataSet } = context
  const payload = {
    dimLogicNr:
      completeDataSet.dimensionLogics.find((dl) => dl.assessmentUuid === assessment.assessmentUuid)?.dimLogicNr || null,
    dimensions: data,
    assessmentUuid: assessment.assessmentUuid
  }

  const responseData = await fetchData(payload, 'save_dimension_logic', context, null)

  try {
    const { resultNrs, processResultNrs } = responseData.response.resultsToUpdate
    updateEntities(responseData.response.data, context)
    await updateResults(resultNrs, processResultNrs, context)
  } catch (e) {
    console.error(e)
  }
}

const getInitialData = (config, currentDimLogicNr, completeDataSet) => {
  if (currentDimLogicNr === DEFAULT_DIM_LOGIC_NR) {
    return config.dimensions.map((dim) => ({
      dimension_nr: dim.dimensionNr,
      weight: parseInt(dim.dimensionWeight * 100),
      matchPoints: dim.dimensionMatchingNorm ? getRowFormat(dim.dimensionMatchingNorm) : null
    }))
  }

  return completeDataSet.dimensionLogics.find((dl) => dl.dimLogicNr === currentDimLogicNr).dimensions
}

const getRowFormat = (dimensionMatchingNorm) => {
  const upperLimits = dimensionMatchingNorm
    .map((row) => ({
      limit: parseInt((row.upper_limit - 1) / 10),
      value: row.value
    }))
    .filter((row) => row.limit)

  return Array.from(Array(10))
    .map((_, i) => upperLimits.find((row) => i < row.limit).value)
    .map((val) => parseInt(val))
}

const WeightTable = styled.table`
  pointer-events: ${(props) => (props.editMode ? '' : 'none')};

  th:first-child {
    width: ${(props) => (props.hasMatchpoints ? '' : '100%')};
  }
  td.weight,
  th.weight {
    text-align: right;
  }
  td.weight {
    padding: ${(props) => (props.editMode ? '0 12px' : '8px 12px')};
  }
  th.match-points {
    width: 100%;
    text-align: left;
  }
  .footer td {
    background-color: var(--table-in-tab-th-bg);
  }
  input {
    width: var(--space-8);
    text-align: right;
    padding: 2px 8px !important;
    height: auto;
  }
`

const InfoText = styled.div`
  font-weight: var(--fw-1);
`

const ButtonContainer = styled.div`
  display: flex;
  gap: var(--space-2);
  justify-content: flex-end;
  margin-top: var(--space-2);
`

const WeightInput = styled.input`
  pointer-events: initial;
  text-align: center;
  width: 50%;
  padding: 2px !important;
  border-radius: var(--bdr-1) !important;
  font-size: var(--fs-2) !important;
`

const WeightDisplay = styled.span`
  font-size: var(--fs-2);
`
