import styled from 'styled-components'
import { getOptionByValue } from './../../utils/helper/Helper'
import { CustomInputList, Tile, TileHeadline } from '../table/tableElements'
import { useContext } from 'react'
import CustomInput from '../customInput/CustomInput'
import { API_DATA, DEFAULT_NORM_NR, ERROR_MSG, PERSEO_DEFAULT } from '../../utils/constants/constants'
import { AppContext } from '../../utils/context/AppContext'
import { getNormOptions } from '../editor/normEditor/normUtils'
import NormsHelp from '../helpModal/NormsHelp'
import CapabilitiesHelper from '../../utils/helper/CapabilitiesHelper'
import NormsTable from '../normsTable/NormsTable'

const NormsTile = ({ assessment }) => {
  const context = useContext(AppContext)
  const { customNorms } = context.completeDataSet
  const currentNormNr = assessment.normNr || DEFAULT_NORM_NR
  const allNorms = [{ name: PERSEO_DEFAULT, normNr: DEFAULT_NORM_NR }, ...customNorms]

  const options = getNormOptions(allNorms)
  const canEdit = CapabilitiesHelper.canEditAssessment(assessment)

  return (
    <Tile width="190px">
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <TileHeadline content="grading" />
      </div>
      <CustomInputList style={{ marginTop: 0 }}>
        <CustomInput
          label="gradingRule"
          content={getOptionByValue(options, currentNormNr).label || ERROR_MSG}
          options={options}
          value={currentNormNr}
          apiData={API_DATA.assessments}
          idValue={assessment.assessmentUuid}
          dataKey="normNr"
          type="select"
          isNorm
          helpContent={<NormsHelp />}
          disabled={!canEdit}
          NormsTable={NormsTable}
          assessment={assessment}
        />
      </CustomInputList>
    </Tile>
  )
}

export default NormsTile

export const LabelIconContainer = styled.div`
  display: flex;
  justify-content: space-between;
  button {
    background-color: transparent;
    padding: 0;
    border-radius: 2px;
    z-index: 9;
  }
  .icon-pen {
    transform: scale(0.8);
  }
  .textlink {
    font-size: var(--fs-1);
    border: none;
    line-height: var(--lh-2);
    background-color: transparent;
    padding: 0;
    z-index: 9;
    border-radius: var(--bdr-1);
  }
`
