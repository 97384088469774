import { SCREEN_TYPE_END, SCREEN_TYPE_START } from '../../../utils/constants/constants'
import { ButtonPrimary } from '../../../utils/elements/miscElements'
import useEventListener from '../../../utils/hooks/useEventListener'
import useTranslate from '../../../utils/hooks/useTranslate'
import { DialogContainer } from '../normEditor/NormTableButtons'

const ScreenTypeSelection = ({ addEmptyScreen, closeDialog }) => {
  const t = useTranslate()
  const startScreenLabel = `+ ${t('startScreen')}`
  const endScreenLabel = `+ ${t('endScreen')}`

  const handleKeyDown = (event) => {
    if (event.code === 'Escape') {
      event.preventDefault()
      closeDialog()
    }
  }

  useEventListener('keydown', handleKeyDown)

  return (
    <DialogContainer style={{ top: '40px' }}>
      <div style={{ display: 'grid', gap: '8px' }}>
        <ButtonPrimary
          style={{ width: '124px' }}
          modalButton
          content={startScreenLabel}
          onClick={() => addEmptyScreen(SCREEN_TYPE_START)}
        />
        <ButtonPrimary
          style={{ width: '124px' }}
          modalButton
          onClick={() => addEmptyScreen(SCREEN_TYPE_END)}
          content={endScreenLabel}
        />
      </div>
    </DialogContainer>
  )
}

export default ScreenTypeSelection
