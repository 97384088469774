import ScrollContainer from '../scrollContainer/ScrollContainer'
import Editor from '../editor/Editor'

const EmailTemplateSettingsComponent = () => (
  <ScrollContainer type="settings">
    <div style={{ marginBottom: 'var(--space-8)' }}>
      <Editor key="emailTemplateEditor" type="emailTemplates" />
    </div>
  </ScrollContainer>
)

export default EmailTemplateSettingsComponent
