import React, { useContext } from 'react'
import ScrollContainer from '../../components/scrollContainer/ScrollContainer'
import { PageHeadline, SubHeadline } from '../../utils/elements/miscElements'
import useTranslate from '../../utils/hooks/useTranslate'
import { AppContext } from '../../utils/context/AppContext'
import DataTable from '../../components/table/DataTable'
import { FIELD_CATEGORIES } from '../../utils/constants/constants'
import {
  getAssessmentFreeTextFilterCol,
  getAssessmentProcessNameCol,
  getNumberPtsCol,
  getSpacerCol
} from '../../components/table/tableCols/tableCols'

const TestSelectionForReminder = ({ selectedRows, setSelectedRows }) => {
  const t = useTranslate()
  const { completeDataSet } = useContext(AppContext)
  const columns = React.useMemo(getCols, [])

  return (
    <>
      <PageHeadline>{t('selectTests')}</PageHeadline>
      <ScrollContainer>
        <SubHeadline style={{ color: 'var(--text-color-secondary)' }}>{t('testsForReminderExplanation')}</SubHeadline>
        <DataTable
          data={completeDataSet.assessments}
          columns={columns}
          showRowSelection
          hideResultCount
          hideArchiveButton
          tableName="test-select-for-reminder"
          showFilters
          hideBulkMenu
          selectedRows={selectedRows}
          onSelectedRowsChange={setSelectedRows}
        />
      </ScrollContainer>
    </>
  )
}

export default TestSelectionForReminder

const getCols = () => [
  getAssessmentFreeTextFilterCol(),
  {
    Header: 'Test',
    accessor: 'assessmentName',
    id: 'assessmentName',
    canExport: true,
    filterOptions: { showInFieldSelector: true, category: FIELD_CATEGORIES.test, label: 'testName' }
  },
  getAssessmentProcessNameCol(true),
  getNumberPtsCol(true),
  getSpacerCol()
]
