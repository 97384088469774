import React, { useContext } from 'react'
import ScrollContainer from '../../../components/scrollContainer/ScrollContainer'
import { PageHeadline, SubHeadline } from '../../../utils/elements/miscElements'
import useTranslate from '../../../utils/hooks/useTranslate'
import { AppContext } from '../../../utils/context/AppContext'
import DataTable from '../../../components/table/DataTable'
import { getParticipantNumberCol } from '../../../pages/dashboard/dashboardUtils'
import {
  getParticipantAssessmentNameCol,
  getParticipantEmailCol,
  getParticipantFreeTextCol,
  getParticipantLastMailDateCol,
  getParticipantProcessCol,
  getPtsAndResultsCol,
  getSpacerCol
} from '../../../components/table/tableCols/tableCols'

const ParticipantSelectForEmails = ({ selectedRows, setSelectedRows }) => {
  const t = useTranslate()
  const { completeDataSet } = useContext(AppContext)
  const participantsWithEmail = completeDataSet.participants.filter((p) => p.pMail)

  const columns = React.useMemo(() => getCols(t), [])

  return (
    <>
      <PageHeadline>{t('participantSelect')}</PageHeadline>
      <ScrollContainer>
        <SubHeadline>{t('selectParticipantForMail')}</SubHeadline>
        <DataTable
          data={participantsWithEmail}
          columns={columns}
          showRowSelection
          showFilters
          tableName="participant-select-for-email"
          selectedRows={selectedRows}
          hideArchiveButton
          hideResultCount
          hideBulkMenu
          onSelectedRowsChange={setSelectedRows}
        />
        <div className="text-secondary" style={{ marginTop: 'var(--space-2)' }}>
          {t('participantSelectForEmailHint')}
        </div>
      </ScrollContainer>
    </>
  )
}

export default ParticipantSelectForEmails

const getCols = (t) => [
  getParticipantFreeTextCol(t),
  getParticipantNumberCol(),
  getParticipantEmailCol(true),
  getParticipantProcessCol(),
  getPtsAndResultsCol(),
  getParticipantLastMailDateCol(true),
  getParticipantAssessmentNameCol(),
  getSpacerCol()
]
