import { useContext } from 'react'
import useTranslate from '../../../utils/hooks/useTranslate'
import { CustomInputList, Tile, TileHeadline } from '../../table/tableElements'
import { AppContext } from '../../../utils/context/AppContext'
import { getOptionByValue } from '../../../utils/helper/Helper'
import { API_DATA, DEFAULT_DIM_LOGIC_NR, ERROR_MSG, PERSEO_DEFAULT } from '../../../utils/constants/constants'
import CustomInput from '../../customInput/CustomInput'
import DimensionDisplay from './DimensionDisplay'
import WeightsHelp from '../../helpModal/WeightsHelp'

const PotentialsWeightTile = ({ assessment }) => {
  const config = assessment.relatedConfig
  const t = useTranslate()
  const { completeDataSet } = useContext(AppContext)

  const options = getOptions(completeDataSet.dimensionLogics, assessment, t)
  const currentDimLogicNr = assessment.dimLogicNr || DEFAULT_DIM_LOGIC_NR
  const canApplyWeights = assessment.relatedProcess.isEditor()

  return (
    <Tile width="280px">
      <TileHeadline content="weighting" />
      <CustomInputList>
        <CustomInput
          key={currentDimLogicNr}
          label="weightingScheme"
          content={getOptionByValue(options, currentDimLogicNr).label || ERROR_MSG}
          options={options}
          value={currentDimLogicNr}
          apiData={API_DATA.assessments}
          idValue={assessment.assessmentUuid}
          dataKey="dimLogicNr"
          type="select"
          isDimensionLogic
          helpContent={<WeightsHelp />}
          disabled={!canApplyWeights}
          DimensionDisplay={DimensionDisplay}
          config={config}
          assessment={assessment}
        />
      </CustomInputList>
    </Tile>
  )
}

export default PotentialsWeightTile

export const getOptions = (dimensionLogics, assessment, t) => {
  const defaultOption = [
    {
      label: PERSEO_DEFAULT,
      value: DEFAULT_DIM_LOGIC_NR
    }
  ]

  const customOptions = dimensionLogics
    .filter((dl) => dl.assessmentUuid === assessment.assessmentUuid)
    .map((dl) => ({
      label: dl.dimensions.some((dim) => dim.matchPoints) ? t('customProfile') : t('customScheme'),
      value: dl.dimLogicNr
    }))

  return [...defaultOption, ...customOptions]
}
