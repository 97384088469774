import { useContext } from 'react'
import useTranslate from '../../utils/hooks/useTranslate'
import ScrollContainer from '../scrollContainer/ScrollContainer'
import DataTable from '../table/DataTable'
import { getActionsCol, getDateCreatedCol, getSpacerCol } from '../table/tableCols/tableCols'
import { AppContext } from '../../utils/context/AppContext'
import Badge from '../badge/Badge'
import { PT_STATUS } from '../../utils/constants/constants'
import { getReminderRule } from '../../wizards/editReminder/reminderUtils'
import { getTruncatedList } from '../../utils/helper/Helper'

const EmailReminderOverview = () => {
  const t = useTranslate()
  const { completeDataSet } = useContext(AppContext)
  const data = completeDataSet.reminders

  const columns = [
    {
      Header: '',
      id: 'shadow-col',
      className: 'shadow-col',
      accessor: () => ''
    },
    {
      Header: 'title',
      accessor: (row) => row.title || ''
    },
    {
      Header: 'sendTime',
      accessor: 'rule',
      Cell: ({ row }) => <div>{getReminderRule(row.original.days, row.original.triggerType, t)}</div>
    },
    {
      Header: 'relatedTests',
      accessor: (row) => {
        const assessmentNames = completeDataSet.assessments
          .filter((ass) => row.assessmentUuids.includes(ass.assessmentUuid))
          .map((ass) => ass.assessmentName)
        return getTruncatedList(assessmentNames, t, 1)
      },
      Cell: ({ value }) => <div>{value}</div>
    },
    {
      Header: 'status',
      accessor: 'active',
      Cell: ({ value }) => <ReminderStatusLabel active={value} />
    },
    getDateCreatedCol(),
    getSpacerCol(),
    getActionsCol()
  ]

  return (
    <ScrollContainer type="main" className="email-reminder-overview">
      <DataTable data={data} columns={columns} tableName="reminders" hideArchiveButton showAddButton smallHeadline />
    </ScrollContainer>
  )
}

export default EmailReminderOverview

const ReminderStatusLabel = ({ active }) => {
  const t = useTranslate()
  const style = {
    display: 'inline-grid',
    width: 'fit-content',
    gridTemplateColumns: '15px 1fr',
    alignItems: 'baseline'
  }
  const status = active ? PT_STATUS.finished : PT_STATUS.deactivated
  return (
    <div style={style}>
      <Badge style={{ transform: 'translateY(-1px)' }} status={status} />
      <span style={{ textTransform: 'capitalize' }}>{active ? t('active') : t('inactive')}</span>
    </div>
  )
}
