export const secondsToHms = (d) => {
  d = Number(d)
  const h = Math.floor(d / 3600)
  const m = Math.floor((d % 3600) / 60)

  const hDisplay = h > 0 ? '0' + h + ':' : '00:'
  const mDisplay = m < 10 ? '0' + m : m
  return hDisplay + mDisplay
}

export const secondsToDHM = (seconds) => {
  const days = Math.floor(seconds / (3600 * 24))
  const hours = Math.floor((seconds % (3600 * 24)) / 3600)
  const minutes = Math.floor((seconds % 3600) / 60)
  return [days, hours, minutes]
}

export const formatDate = (date) => {
  if (!date) return ''

  const year = date.getFullYear()
  const month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
  const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()

  const hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
  const minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
  const seconds = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()

  const andromedaFormat = [year, month, day].join('-') + ' ' + [hours, minutes, seconds].join(':')
  const urlParamFormat = [year, month, day].join('')
  const dateOnly = [day, month, year].join('.')

  return {
    androFormat: andromedaFormat,
    urlParamFormat: urlParamFormat,
    dateOnly: dateOnly
  }
}

export const createDate = (date) => {
  if (!date) {
    return null
  }

  if (date instanceof Date) {
    return date
  }

  // safari date format fix
  return new Date(date.replace(/-/g, '/'))
}

export const getTimeSpans = () => {
  const currentDate = new Date()

  return {
    today: {
      start: startOfDay(currentDate),
      end: endOfDay(currentDate)
    },
    thisWeek: {
      start: startOfWeek(currentDate),
      end: endOfWeek(startOfWeek(currentDate))
    },
    thisMonth: {
      start: startOfMonth(currentDate),
      end: endOfMonth(currentDate)
    },
    thisYear: {
      start: startOfYear(currentDate),
      end: endOfYear(currentDate)
    }
  }
}

export const getFilteredDateArray = (dateArray) => {
  const timeSpans = getTimeSpans()
  return {
    today: dateArray.filter((date) => date > timeSpans.today.start && date <= timeSpans.today.end),
    thisWeek: dateArray.filter((date) => date > timeSpans.thisWeek.start && date <= timeSpans.thisWeek.end),
    thisMonth: dateArray.filter((date) => date > timeSpans.thisMonth.start && date <= timeSpans.thisMonth.end),
    thisYear: dateArray.filter((date) => date > timeSpans.thisYear.start && date <= timeSpans.thisYear.end)
  }
}

export const getSecondsFromDHM = (days, hours, minutes) => {
  const daySeconds = days * 24 * 60 * 60
  const hourSeconds = hours * 60 * 60
  const minuteSeconds = minutes * 60
  return daySeconds + hourSeconds + minuteSeconds
}

export const startOfDay = (date) => new Date(date.getFullYear(), date.getMonth(), date.getDate())
export const endOfDay = (date) => new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 999)
export const startOfMonth = (date) => new Date(date.getFullYear(), date.getMonth(), 1)
export const endOfMonth = (date) => new Date(date.getFullYear(), date.getMonth() + 1, 0, 23, 59, 59, 999)
export const startOfYear = (date) => new Date(date.getFullYear(), 0, 1)
export const endOfYear = (date) => new Date(date.getFullYear(), 11, 31, 23, 59, 59, 999)
export const endOfWeek = (startOfWeek) => adjustDays(startOfWeek, 6)
export const adjustDays = (date, days) => new Date(date.getFullYear(), date.getMonth(), date.getDate() + days)

export const startOfWeek = (date) => {
  const day = date.getDay()
  const diff = date.getDate() - day + (day === 0 ? -6 : 1) // adjust when day is Sunday
  return new Date(date.getFullYear(), date.getMonth(), diff)
}

export const getLatestDateFromArray = (dates) => {
  if (!dates.length) {
    return null
  }
  return new Date(Math.max(...dates))
}
