import {
  ButtonPrimary,
  ButtonSecondary,
  ErrorMessage,
  Label,
  OptionDescription,
  OptionEntryWide,
  WizardForm,
  selectStyles
} from '../../../utils/elements/miscElements'
import useTranslate from '../../../utils/hooks/useTranslate'
import { getDefaultNewTitle, setEmailSubject } from '../sendEmailUtils'
import styled from 'styled-components'
import SalutationSelection from '../subComponents/SalutationSelection'
import { FORM_ERRORS, MODAL_TYPES, STATUS_BAR_TYPES } from '../../../utils/constants/constants'
import { useContext } from 'react'
import { AppContext } from '../../../utils/context/AppContext'
import { fetchData, getOptionByValue, setState, setStatusBar, updateEntities } from '../../../utils/helper/Helper'
import Select from 'react-select'
import SaveTemplateModal from '../subComponents/SaveTemplateModal'
import { useEffect } from 'react'

const EmailTemplate = ({ data, setData }) => {
  const t = useTranslate()
  const subjectEmpty = data.subject.length === 0
  const context = useContext(AppContext)

  const buttonStyles = {
    flexGrow: 0,
    minHeight: '32px',
    minWidth: '80px',
    fontSize: 'var(--fs-2)',
    width: 'auto',
    marginLeft: 'auto'
  }
  const options = getOptions(data)

  useEffect(() => {
    const newTemplateName = getDefaultNewTitle(data.templateName, data.saveMethod, t)
    setState(setData, 'newTemplateName', newTemplateName)
  }, [data.templateName, data.saveMethod])

  useEffect(() => {
    updateModalProps(context, data, setData)
  }, [data.saveMethod, data.newTemplateName])

  return (
    <WizardForm>
      {!data.hideTemplateManager && (
        <OptionEntryWide hasHint>
          <Label fullWidth>{t('template')}</Label>
          <Select
            options={options}
            onChange={(selectedOption) => changeTemplate(selectedOption, data, setData)}
            value={getOptionByValue(options, data.templateKey)}
            styles={selectStyles}
            isSearchable={false}
          />
          <OptionDescription description="createAndSaveNewTemplates" />
        </OptionEntryWide>
      )}

      <OptionEntryWide hasHint>
        <Label fullWidth style={{ lineHeight: '18px' }}>
          {t('subject')}
        </Label>
        <div>
          <input
            className={subjectEmpty ? 'has-error' : ''}
            value={data.subject}
            onChange={(e) => setEmailSubject(e, setData)}
            type="text"
            style={{ width: '100%' }}
          />

          <ErrorMessage visible={subjectEmpty}>{t(FORM_ERRORS.fieldEmpty)}</ErrorMessage>
        </div>
      </OptionEntryWide>

      <OptionEntryWide hasHint>
        <Label fullWidth style={{ lineHeight: '18px' }}>
          {t('salutation')}
        </Label>
        <SalutationSelection {...{ data, setData }} />
      </OptionEntryWide>

      <OptionEntryWide hasHint>
        <Label fullWidth>{t('freeTextTop')}</Label>
        <TextArea rows={5} onChange={(e) => handleInputChange(e, setData, 'topText')} value={data.topText} />
      </OptionEntryWide>

      <OptionEntryWide hasHint>
        <AccessDataPlaceholder>{t('accessDataPlaceholder')}</AccessDataPlaceholder>
      </OptionEntryWide>

      <OptionEntryWide hasHint>
        <Label fullWidth>{t('freeTextBottom')}</Label>
        <TextArea rows={5} onChange={(e) => handleInputChange(e, setData, 'bottomText')} value={data.bottomText} />
      </OptionEntryWide>
      {!data.hideTemplateManager && (
        <OptionEntryWide hasHint>
          <ButtonSecondary
            style={buttonStyles}
            content={'saveAsTemplate'}
            onClick={(e) => openSaveTemplateModal(data, setData, context, e)}
          />
        </OptionEntryWide>
      )}
    </WizardForm>
  )
}

export default EmailTemplate

const openSaveTemplateModal = (data, setData, context, e) => {
  e.preventDefault()
  setState(setData, 'saveMethod', 'add')
  updateModalProps(context, data, setData)
  context.setModalOpen(true)
}

const updateModalProps = (context, data, setData) => {
  const nameEmpty = data.newTemplateName.length === 0
  context.setModalProps({
    headline: 'saveTemplate',
    content: <SaveTemplateModal {...{ data, setData }} />,
    buttonPrimary: (
      <ButtonPrimary
        disabled={nameEmpty}
        onClick={(e) => saveTemplate(data, setData, context, e)}
        modalButton
        content="save"
      />
    ),
    icon: 'icon-email modal',
    type: MODAL_TYPES.alert
  })
}

const handleInputChange = (e, setData, dataKey) => {
  setData((prev) => ({
    ...prev,
    [dataKey]: e.target.value,
    isDirty: true
  }))
}

export const AccessDataPlaceholder = styled.div`
  border-radius: var(--bdr-2);
  padding: var(--space-3);
  line-height: 18px !important;
  text-align: center;
  background-color: var(--box-background);
  font-weight: var(--fw-2);
`

export const TextArea = styled.textarea`
  width: 100%;
  border: 1px solid var(--border-grey);
  border-radius: var(--bdr-2);
  padding: var(--space-3);
  line-height: var(--lh-3);
  resize: none;
`

const saveTemplate = async (data, setData, context, e) => {
  e.preventDefault()
  context.setModalOpen(false)

  const payload = {
    title: data.newTemplateName,
    public_key: data.saveMethod === 'edit' ? data.templateKey : null,
    template_data: {
      salutationType: data.salutationType,
      subject: data.subject,
      topText: data.topText,
      bottomText: data.bottomText
    }
  }

  const responseData = await fetchData(payload, 'save_invitation_template', context, 'dataChangeFailed', false)

  try {
    updateEntities(responseData.response.data, context)

    setData((prev) => ({
      ...prev,
      templateKey: responseData.response.data.ptInvitationTemplates[0].publicKey,
      isDirty: false
    }))
    setStatusBar({
      controller: context.statusBarController,
      type: STATUS_BAR_TYPES.success,
      text: 'templateSavedSuccessfully',
      setVisible: true
    })
  } catch (e) {
    console.error(e)
  }
}

const changeTemplate = (selectedOption, data, setData) => {
  setState(setData, 'templateKey', selectedOption.value)
  const optionChanged = data.templateKey !== selectedOption.value
  if (optionChanged) {
    setState(setData, 'isDirty', false)
  }
}

const getOptions = (data) => {
  return data.availableTemplates.map((template) => {
    const dirty = data.isDirty && template.publicKey === data.templateKey
    return {
      label: <TemplateName name={template.name} dirty={dirty} />,
      value: template.publicKey
    }
  })
}

const TemplateName = ({ name, dirty }) => {
  const t = useTranslate()
  return (
    <>
      <span>{name}</span>
      {dirty && <span style={{ color: 'var(--text-color-secondary)' }}> ({t('changed')})</span>}
    </>
  )
}
