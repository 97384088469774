import styled from 'styled-components'
import useTranslate from '../../../utils/hooks/useTranslate'
import { shakeElement } from '../../../utils/helper/Helper'
import NormTableButtons from './NormTableButtons'

const NormEditorTable = ({ currentNorm, dispatch }) => {
  const t = useTranslate()

  const handleGradeChange = (target, rowIndex) => {
    const GRADE_MAX_CHARS = 4

    if (target.value.length > GRADE_MAX_CHARS) {
      shakeElement(target)
      return
    }

    dispatch({
      type: 'updated_table',
      value: target.value,
      field: 'grade',
      rowIndex: rowIndex
    })
  }

  const handleVerbalisationChange = (target, rowIndex) => {
    const VERBALISATION_MAX_CHARS = 120
    if (target.value.length > VERBALISATION_MAX_CHARS) {
      shakeElement(target)
      return
    }
    dispatch({
      type: 'updated_table',
      value: target.value,
      field: 'verbalisation',
      rowIndex: rowIndex
    })
  }

  return (
    <Table className="norm-editor">
      <thead>
        <tr>
          <th>{t('from')}</th>
          <th>{t('upTo')}</th>
          <th>{t('grade')}</th>
          <th>{t('description')}</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {currentNorm.normTable.map((row, i, orig) => (
          <tr key={i}>
            <td className="lower-limit">
              <input type="number" value={row.lower_limit} disabled />
            </td>
            <td className="upper-limit">
              <input
                className={row.upperTooLow || row.upper_limit === '' ? 'has-error' : ''}
                type="number"
                value={Math.min(row.upper_limit, 100)}
                disabled={i === orig.length - 1}
                onChange={(e) =>
                  dispatch({
                    type: 'updated_table',
                    value: parseInt(e.target.value),
                    field: 'upper_limit',
                    rowIndex: i
                  })
                }
              />
            </td>
            <td className="grade">
              <input
                className={row.grade === '' ? 'has-error' : ''}
                type="number"
                value={row.grade}
                onChange={(e) => handleGradeChange(e.target, i)}
              />
            </td>
            <td>
              <input
                className={row.verbalisation === '' ? 'has-error' : ''}
                type="text"
                value={row.verbalisation}
                onChange={(e) => handleVerbalisationChange(e.target, i)}
              />
            </td>
            <td>
              <NormTableButtons
                i={i}
                dispatch={dispatch}
                showDelete={i > 0 && i < orig.length - 1}
                showAdd={i < orig.length - 1}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

export default NormEditorTable

const Table = styled.table`
  width: 100%;
  background-color: transparent;

  th,
  td {
    padding: var(--space-3) var(--space-2);
  }
  td:nth-child(-n + 3),
  th:nth-child(-n + 3) {
    width: 60px;
    text-align: center;
    input {
      text-align: right;
    }
  }
  th:last-child {
    width: 80px;
  }
  td:last-child {
    height: 1px;
  }
  input {
    width: 100%;
  }
`
