import React from 'react'
import { TableContentContainer } from '../../components/table/tableElements'
import { getAverage, getDurationString, getTestTypeName } from '../../utils/helper/Helper'
import {
  CONTENT_TYPES,
  FIELD_CATEGORIES,
  FIELD_LABELS,
  PERSEO_DEFAULT,
  PT_STATUS,
  PT_STATUS_NAMES
} from '../../utils/constants/constants'
import { secondsToDHM } from '../../utils/helper/dateTimeHelper'
import useTranslate from '../../utils/hooks/useTranslate'
import DateDisplay from '../../components/dateDisplay/DateDisplay'
import {
  getActionsCol,
  getAssessmentFreeTextFilterCol,
  getAssessmentNameCol,
  getAssessmentProcessNameCol,
  getCreatedByCol,
  getDateCreatedCol,
  getNumberPtsCol,
  getSpacerCol,
  getVisibilityCol
} from '../../components/table/tableCols/tableCols'

export const getAssessmentTableCols = (t, completeDataSet) => [
  getAssessmentFreeTextFilterCol(),
  getAssessmentNameCol(),
  getAssessmentProcessNameCol(),
  getNumberPtsCol(),
  {
    Header: 'duration',
    id: 'testDuration',
    accessor: (row) => getDurationString(row.relatedConfig.configDuration, t).short,
    filterOptions: { showInFieldSelector: true, category: FIELD_CATEGORIES.test },
    canExport: true,
    Cell: ({ value }) => <TableContentContainer>{value}</TableContentContainer>
  },
  {
    Header: FIELD_LABELS.validFrom,
    id: 'validFrom',
    filterOptions: { showInFieldSelector: true, category: FIELD_CATEGORIES.test, contentType: CONTENT_TYPES.date },
    canExport: true,
    accessor: (row) => row.validFrom,
    Cell: ({ value }) => (
      <TableContentContainer>
        <DateDisplay date={value} format={'dateAndTime'} />
      </TableContentContainer>
    )
  },
  {
    Header: FIELD_LABELS.validUntil,
    id: 'validUntil',
    filterOptions: { showInFieldSelector: true, category: FIELD_CATEGORIES.test, contentType: CONTENT_TYPES.date },
    canExport: true,
    accessor: (row) => row.validUntil,
    Cell: ({ value }) => (
      <TableContentContainer>
        <DateDisplay date={value} format={'dateAndTime'} />
      </TableContentContainer>
    )
  },
  {
    Header: FIELD_LABELS.invitedDuration,
    canExport: true,
    filterOptions: {
      showInFieldSelector: true,
      category: FIELD_CATEGORIES.test,
      contentType: CONTENT_TYPES.time,
      excludedValues: [0]
    },
    show: false,
    id: 'invitedDuration',
    accessor: (row) => row.invitedDuration || '',
    Cell: ({ row }) => {
      const t = useTranslate()
      const invitedDuration = row.original.invitedDuration
      return (
        <TableContentContainer>
          <span>{invitedDuration ? t('dhmString', ...secondsToDHM(invitedDuration)) : ''}</span>
        </TableContentContainer>
      )
    }
  },
  {
    Header: FIELD_LABELS.startedDuration,
    canExport: true,
    filterOptions: {
      showInFieldSelector: true,
      category: FIELD_CATEGORIES.test,
      contentType: CONTENT_TYPES.time,
      excludedValues: [0]
    },
    show: false,
    id: 'startedDuration',
    accessor: (row) => row.startedDuration || '',
    Cell: ({ row }) => {
      const t = useTranslate()
      const startedDuration = row.original.startedDuration
      return (
        <TableContentContainer>
          <span>{startedDuration ? t('dhmString', ...secondsToDHM(startedDuration)) : ''}</span>
        </TableContentContainer>
      )
    }
  },
  {
    Header: FIELD_LABELS.gradingRule,
    canExport: true,
    filterOptions: {
      showInFieldSelector: true,
      category: FIELD_CATEGORIES.test,
      contentType: CONTENT_TYPES.text
    },
    show: false,
    id: 'gradingRule',
    accessor: (row) => completeDataSet.customNorms.find((cn) => cn.normNr === row.normNr)?.name || PERSEO_DEFAULT,
    Cell: ({ value }) => <TableContentContainer>{value}</TableContentContainer>
  },
  {
    Header: FIELD_LABELS.startScreen,
    canExport: true,
    filterOptions: {
      showInFieldSelector: true,
      category: FIELD_CATEGORIES.test,
      contentType: CONTENT_TYPES.text
    },
    show: false,
    id: 'startScreen',
    accessor: (row) =>
      completeDataSet.customScreens.find((cs) => cs.screenNr === row.screens.screenNrStart)?.name || PERSEO_DEFAULT,
    Cell: ({ value }) => <TableContentContainer>{value}</TableContentContainer>
  },
  {
    Header: FIELD_LABELS.endScreen,
    canExport: true,
    filterOptions: {
      showInFieldSelector: true,
      category: FIELD_CATEGORIES.test,
      contentType: CONTENT_TYPES.text
    },
    show: false,
    id: 'endScreen',
    accessor: (row) =>
      completeDataSet.customScreens.find((cs) => cs.screenNr === row.screens.screenNrEnd)?.name || PERSEO_DEFAULT,
    Cell: ({ value }) => <TableContentContainer>{value}</TableContentContainer>
  },
  {
    Header: 'testType',
    canExport: true,
    filterOptions: {
      showInFieldSelector: true,
      category: FIELD_CATEGORIES.test,
      contentType: CONTENT_TYPES.text
    },
    show: false,
    id: 'testType',
    accessor: (row) => t(getTestTypeName(row.relatedConfig.configType)),
    Cell: ({ value }) => <TableContentContainer>{value}</TableContentContainer>
  },
  getCreatedByCol('assessmentCreatedBy', FIELD_CATEGORIES.test),
  getDateCreatedCol('assessmentCreated', FIELD_CATEGORIES.test),
  getVisibilityCol(FIELD_CATEGORIES.test),
  getSpacerCol(),
  getActionsCol()
]

export const getTestStats = (ptList) => {
  const ptsWithResults = ptList.filter((pt) => pt.hasResult())

  const allResults = ptsWithResults.map((pt) => pt.relatedResult.normResult)
  const allResultsFiltered = allResults.filter((n) => n)

  const allGrades = ptsWithResults.map((pt) => parseFloat(pt.relatedResult.grade))
  const allGradesFiltered = allGrades.filter((n) => n)

  const testStats = [
    {
      id: 'totalParticipants',
      value: ptList.length,
      label: 'totalParticipants'
    },
    {
      id: PT_STATUS.waiting,
      value: ptList.filter((pt) => pt.isWaiting()).length,
      label: PT_STATUS_NAMES[PT_STATUS.waiting]
    },
    {
      id: PT_STATUS.ready,
      value: ptList.filter((pt) => pt.isReady()).length,
      label: PT_STATUS_NAMES[PT_STATUS.ready]
    },
    {
      id: PT_STATUS.started,
      value: ptList.filter((pt) => pt.isStarted()).length,
      label: PT_STATUS_NAMES[PT_STATUS.started]
    },
    {
      id: PT_STATUS.finished,
      value: ptList.filter((pt) => pt.isFinished()).length,
      label: PT_STATUS_NAMES[PT_STATUS.finished]
    },
    {
      id: PT_STATUS.expired,
      value: ptList.filter((pt) => pt.isExpired()).length,
      label: PT_STATUS_NAMES[PT_STATUS.expired]
    },
    {
      id: PT_STATUS.deactivated,
      value: ptList.filter((pt) => pt.isDeactivated()).length,
      label: PT_STATUS_NAMES[PT_STATUS.deactivated]
    },
    {
      id: 'avgPoints',
      value: getAverage(allResultsFiltered),
      label: 'averagePoints'
    },
    {
      id: 'avgGrade',
      value: getAverage(allGradesFiltered),
      label: 'averageGrade'
    }
  ]

  return testStats
}
