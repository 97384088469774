import styled from 'styled-components'
import useTranslate from '../../utils/hooks/useTranslate'
import BarDiagram from '../charts/BarDiagram'
import GradeLabel from '../gradeLabel/GradeLabel'
import { Tile, TileHeadline } from '../table/tableElements'
import { getCorrectNorm } from '../editor/normEditor/normUtils'
import { useContext } from 'react'
import { AppContext } from '../../utils/context/AppContext'
import { getFormattedNumber } from '../../utils/helper/Helper'

const ResultsTile = ({ assessment, stats }) => {
  const t = useTranslate()
  const { completeDataSet, language } = useContext(AppContext)
  const { customNorms } = completeDataSet
  const usedNorm = getCorrectNorm(assessment, assessment.normNr, customNorms)

  const avgPoints = stats.find((stat) => stat.id === 'avgPoints').value
  const avgGrade = stats.find((stat) => stat.id === 'avgGrade').value

  return (
    <Tile width="400px">
      <TileHeadline content="results" />
      {avgPoints ? (
        <>
          <ResultsOuterContainer style={{ gridTemplateColumns: 'repeat(5, 1fr)' }}>
            <ResultsInnerContainer style={{ gridColumn: avgGrade ? 2 : 3 }}>
              <ResultLabel>{t('points')} Ø</ResultLabel>
              <Result>{getFormattedNumber(avgPoints, language, 2)}</Result>
            </ResultsInnerContainer>

            {avgGrade && (
              <ResultsInnerContainer style={{ gridColumn: 4 }}>
                <ResultLabel>
                  <GradeLabel label={usedNorm.gradeLabel} /> Ø
                </ResultLabel>
                <Result>{getFormattedNumber(avgGrade, language, 2)}</Result>
              </ResultsInnerContainer>
            )}
          </ResultsOuterContainer>
          <div style={{ height: '230px' }}>
            <BarDiagram
              data={getResultStats(assessment.relatedPts)}
              axisLeftFormat={(e) => Math.floor(e) === e && e}
              margin={{ top: 8, right: 0, bottom: 50, left: 28 }}
              useDefaultColors
            />
          </div>
        </>
      ) : (
        <NoResultsNotice>{t('noResultsYet')}</NoResultsNotice>
      )}
    </Tile>
  )
}

export default ResultsTile

export const ResultsOuterContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: var(--space-5) 0;
  justify-items: center;
`

export const ResultsInnerContainer = styled.div`
  display: grid;
  text-align: left;
`

export const ResultLabel = styled.span`
  font-size: var(--fs-0);
  line-height: var(--lh-2);
  text-transform: uppercase;
  color: var(--text-color-secondary);
  font-weight: var(--fw-3);
`

export const Result = styled.span`
  font-size: var(--fs-7);
  line-height: var(--lh-2);
  font-weight: var(--fw-2);
`

const getResultStats = (ptData) => {
  const ptsWithResult = ptData.filter((pt) => pt.hasResult())
  const arr = [
    {
      group: '< 30',
      value: ptsWithResult.filter((pt) => pt.relatedResult.normResult < 30).length
    },
    {
      group: '30-39',
      value: ptsWithResult.filter((pt) => pt.relatedResult.normResult >= 30 && pt.relatedResult.normResult < 40).length
    },
    {
      group: '40-49',
      value: ptsWithResult.filter((pt) => pt.relatedResult.normResult >= 40 && pt.relatedResult.normResult < 50).length
    },
    {
      group: '50-59',
      value: ptsWithResult.filter((pt) => pt.relatedResult.normResult >= 50 && pt.relatedResult.normResult < 60).length
    },
    {
      group: '60-69',
      value: ptsWithResult.filter((pt) => pt.relatedResult.normResult >= 60 && pt.relatedResult.normResult < 70).length
    },
    {
      group: '≥ 70',
      value: ptsWithResult.filter((pt) => pt.relatedResult.normResult >= 70).length
    }
  ]

  return arr
}

const NoResultsNotice = styled.div`
  font-size: var(--fs-8);
  line-height: var(--lh-4);
  color: var(--grey-500);
  text-align: center;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: var(--letter-spacing-headline);
`
