import { useContext } from 'react'
import { AppContext } from '../../utils/context/AppContext'
import useTranslate from '../../utils/hooks/useTranslate'

const ScreensHelp = () => {
  const context = useContext(AppContext)
  const lang = context.language
  if (lang === 'en') {
    return <ScreensHelpEnglish />
  } else {
    return <ScreensHelpGerman />
  }
}

export default ScreensHelp

const ScreensHelpGerman = () => {
  const t = useTranslate()
  return (
    <div>
      <p>
        Jeder Test enthält eine Start- und einen Abschlussseite. Unter{' '}
        <em>
          Menü {'>'} Einstellungen {'>'} Personalisierung {'>'} Start- und Abschlussseiten
        </em>{' '}
        können Sie eigene Start- und Abschlussseiten für Ihre Tests anlegen.
      </p>
      <p>
        Die <em>Startseite</em> können Sie z.B. dazu nutzen, den Teilnehmern für ihre Bewerbung zu danken oder sich
        selbst vorzustellen. Auf der <em>Abschlussseite</em> können Sie z.B. einen Ausblick über das weitere
        Bewerbungsverfahren geben oder Kontaktdaten für Rückfragen bereitstellen.
      </p>
      <p>
        Um eine Start- oder Abschlussseite einem Test zuzuweisen, müssen Sie diese in der Test-Detailansicht unter <em>{t('screens')}</em> auswählen.
      </p>
      <div className="textbox important">
        <p>
          Bitte denken Sie daran, Ihre Seiten in allen Sprachen anzulegen, die für Ihren Test aktiviert sind. Deutsch
          (Sie) und Deutsch (Du) gelten dabei als verschiedene Sprachen.
        </p>
      </div>
    </div>
  )
}

const ScreensHelpEnglish = () => {
  const t = useTranslate()
  return (
    <div>
      <p>
        Each test includes a start and an end screen. Under{' '}
        <em>
          Menu {'>'} Settings {'>'} Customization {'>'} Start and End Screens
        </em>{' '}
        you can create your own start and end screens for your tests.
      </p>
      <p>
        You can use the <em>start screen</em> to, for example, thank participants for their application or introduce yourself.
        On the <em>end screen</em>, you can, for example, provide an overview of the next steps in the application process or
        offer contact details for further questions.
      </p>
      <p>
        To assign a start or end screen to a test, you need to select it in the test detail view under <em>{t('screens')}</em>.
      </p>
      <div className="textbox important">
        <p>
          Please remember to create your screens in all languages activated for your test. German (formal) and German (informal) are considered different languages.
        </p>
      </div>
    </div>
  )
}
