import styled from 'styled-components'
import { ButtonPrimary, ButtonSecondary, Label } from '../../../utils/elements/miscElements'
import { useEffect, useRef, useState } from 'react'
import useTranslate from '../../../utils/hooks/useTranslate'
import { NAME_MAX_CHARS } from '../normEditor/normUtils'
import { shakeElement } from '../../../utils/helper/Helper'

const SaveAsDialog = ({ name, onCancel, onOk }) => {
  const t = useTranslate()
  const inputRef = useRef(null)
  const [newName, setNewName] = useState(name + ' ' + t('copy'))

  useEffect(() => {
    inputRef.current.focus()
  }, [])

  const handleNameChange = (target) => {
    const value = target.value
    if (value.length > NAME_MAX_CHARS) {
      shakeElement(target)
      return
    }
    setNewName(value)
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Escape') {
      onCancel()
    }
    if (e.key === 'Enter') {
      onOk(newName)
    }
  }

  return (
    <SaveAsDialogWrapper>
      <div style={{ display: 'grid', gap: '2px' }}>
        <Label>{t('saveAs')} …</Label>
        <input
          ref={inputRef}
          type="text"
          value={newName}
          onChange={(e) => handleNameChange(e.target)}
          onKeyDown={handleKeyDown}
        />
      </div>
      <div style={{ display: 'grid', gap: '8px', gridTemplateColumns: '1fr 1fr' }}>
        <ButtonSecondary modalButton content="cancel" onClick={onCancel} />
        <ButtonPrimary modalButton onClick={() => onOk(newName)} content="save" />
      </div>
    </SaveAsDialogWrapper>
  )
}

export default SaveAsDialog

const SaveAsDialogWrapper = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  background: var(--norm-save-as-dialog-bg);
  padding: var(--space-4);
  box-shadow: var(--shadow-default);
  border-radius: var(--bdr-3);
  display: grid;
  gap: 8px;
  width: 308px;
  z-index: 9;
`
