import { BULK_ACTIONS, ENTITIES, MODAL_TYPES } from '../../utils/constants/constants'
import { getEntityLabel } from '../../utils/helper/Helper'
import useTranslate from '../../utils/hooks/useTranslate'

export const getAlertModalHeadline = (entity, count, action, t) => {
  const entityLabel = t(getEntityLabel(entity, count))
  const countLabel = count === 1 ? '' : count
  const actionLabel = t(getActionLabel(action))
  return t('alertModalHeadline', countLabel, entityLabel, actionLabel)
}

export const getPrimaryButtonText = (action) => {
  switch (action) {
    case BULK_ACTIONS.delete:
      return 'delete'
    case BULK_ACTIONS.archive:
      return 'putInArchive'
    case BULK_ACTIONS.unarchive:
      return 'unarchive'
    case BULK_ACTIONS.deactivate:
      return 'deactivate'
    case BULK_ACTIONS.activate:
      return 'activate'
    case BULK_ACTIONS.resendEmail:
      return 'send'
    default:
      return ''
  }
}

export const AlertModalText = ({ entity, count, action }) => {
  const t = useTranslate()
  const entityLabel = t(getEntityLabel(entity, count))
  const entityLabelPrefixed = t(getEntityLabelPrefixed(entity, count, entityLabel))
  const actionLabel = t(getActionLabel(action))
  const noRestore = t('irreversibleStep')
  const testCannotStartHint = t('deactivatedTestsCannotBePerformed')
  const ptsWillBeDeactivatedHint = t('unfinishedTestsDeactivated')

  switch (entity) {
    case ENTITIES.processes:
    case ENTITIES.reminders:
    case ENTITIES.participantMailLogs:
      return (
        <span>
          {t('confirmEdit', entityLabelPrefixed, actionLabel)} {action === BULK_ACTIONS.delete && noRestore}
        </span>
      )
    case ENTITIES.assessments:
      return (
        <span>
          {t('confirmTestEdit', entityLabelPrefixed, actionLabel)} {action === BULK_ACTIONS.delete && noRestore}{' '}
          {action === BULK_ACTIONS.unarchive && t('unarchivedProcessesFromArchive')}
        </span>
      )
    case ENTITIES.participants:
      return (
        <span>
          {t('confirmParticipantEdit', entityLabelPrefixed, actionLabel)} {action === BULK_ACTIONS.delete && noRestore}{' '}
          {action === BULK_ACTIONS.unarchive && t('unarchivedTestsAndProcessesFromArchive')}
        </span>
      )
    case ENTITIES.pts:
      return (
        <span>
          {t('confirmEdit', entityLabelPrefixed, actionLabel)} {action === BULK_ACTIONS.delete ? noRestore : ''}{' '}
          {action === BULK_ACTIONS.archive && ptsWillBeDeactivatedHint}
          {action === BULK_ACTIONS.deactivate && testCannotStartHint}
          {action === BULK_ACTIONS.unarchive && t('unarchivedParticipantsTestsAndProcessesFromArchive')}
        </span>
      )
    default:
      return <></>
  }
}

const getEntityLabelPrefixed = (entity, count, entityLabel) => {
  switch (entity) {
    case ENTITIES.pts:
      return count === 1 ? 'theSelectedPt' : 'theSelectedPts'
    case ENTITIES.processes:
      return count === 1 ? 'theSelectedProcess' : 'theSelectedProcesses'
    case ENTITIES.participants:
      return count === 1 ? 'theSelectedParticipant' : 'theSelectedParticipants'
    case ENTITIES.assessments:
      return count === 1 ? 'theSelectedTest' : 'theSelectedTests'
    case ENTITIES.reminders:
      return 'theSelectedReminder'
    case ENTITIES.participantMailLogs:
      return count === 1 ? 'theSelectedMail' : 'theSelectedMails'
    default:
      return `${count} ${entityLabel}`
  }
}

const getActionLabel = (action) => {
  switch (action) {
    case BULK_ACTIONS.archive:
      return 'putInArchive'
    case BULK_ACTIONS.delete:
      return 'delete'
    case BULK_ACTIONS.unarchive:
      return 'unarchive'
    case BULK_ACTIONS.deactivate:
      return 'deactivate'
    case BULK_ACTIONS.activate:
      return 'activate'
    case BULK_ACTIONS.resendEmail:
      return 'sendAgain'
    default:
      return ''
  }
}

export const showCopyToPtModal = ({ context, ptsForBulk, buttonPrimary, buttonSecondary, t }) => {
  const numberPtLabel = getNumberPtLabel(ptsForBulk, t)
  const modalProps = {
    headline: 'applySettingsToTestings',
    content: t('copyToPtExplanation', numberPtLabel),
    buttonSecondary: buttonSecondary,
    buttonPrimary: buttonPrimary,
    icon: 'icon-alert modal',
    type: MODAL_TYPES.alert
  }
  context.setModalProps(modalProps)
  context.setModalOpen(true)
}

const getNumberPtLabel = (ptsForBulk, t) => {
  const hasInvalidPts = ptsForBulk.validOnly.length < ptsForBulk.allPts.length
  const label = hasInvalidPts ? (
    <span style={{ color: 'var(--modal-highlight-text-color)' }}>
      {ptsForBulk.validOnly.length} {t('of')} {ptsForBulk.allPts.length}
    </span>
  ) : (
    <span>{ptsForBulk.validOnly.length}</span>
  )
  return label
}

export const getModalContainerClassName = (props) => {
  let className = props.type
  if (props.isWelcomeNotification) {
    className += ' welcome-modal'
  }
  return className
}
