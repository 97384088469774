import styled from 'styled-components'
import { AppContext } from '../../utils/context/AppContext'
import { useContext, useEffect, useState } from 'react'
import { STATUS_BAR_TYPES, STATUS_BAR_DURATION } from '../../utils/constants/constants'
import useTranslate from '../../utils/hooks/useTranslate'

const StatusBar = () => {
  const t = useTranslate()

  const { statusBarController } = useContext(AppContext)
  const { statusBarVisible, statusBarType, statusBarContent, setStatusBarVisible } = statusBarController

  const text = Array.isArray(statusBarContent) ? statusBarContent[0] : statusBarContent
  const param = Array.isArray(statusBarContent) ? t(statusBarContent[1]) : null

  const [timerId, setTimerId] = useState(null)
  const [hasBeenVisible, setHasBeenVisible] = useState(false)

  useEffect(() => {
    if (statusBarVisible && !hasBeenVisible) {
      setHasBeenVisible(true)
    }
  }, [statusBarVisible])

  useEffect(() => {
    if (statusBarType === STATUS_BAR_TYPES.loading && timerId) {
      clearTimeout(timerId)
      setTimerId(null)
    }
    if (statusBarType && statusBarType !== STATUS_BAR_TYPES.loading && !timerId) {
      const id = setTimeout(() => {
        setStatusBarVisible(false)
        setTimerId(null)
      }, STATUS_BAR_DURATION)
      setTimerId(id)
    }
  }, [statusBarVisible, statusBarType])

  const getVisibilityClass = () => {
    if (statusBarVisible) {
      return 'status-bar-visible'
    }
    if (hasBeenVisible) {
      return 'is-fading'
    }
    return ''
  }

  return (
    <OuterWrapper className={`statusbar ${getVisibilityClass()}`}>
      <InnerWrapper type={statusBarType}>
        <Icon className={`svg-icon ${getIcon(statusBarType)}`} style={{ userSelect: 'none' }} />
        <span style={{ lineHeight: 'var(--lh-2)' }}>{param !== null ? t(text, param) : t(text)}</span>
      </InnerWrapper>
    </OuterWrapper>
  )
}

export default StatusBar

const getIcon = (type) => {
  switch (type) {
    case STATUS_BAR_TYPES.success:
      return 'icon-check'
    case STATUS_BAR_TYPES.loading:
      return 'spinner'
    case STATUS_BAR_TYPES.error:
    case STATUS_BAR_TYPES.notice:
    default:
      return 'icon-alert'
  }
}

const getBgColor = (type) => {
  switch (type) {
    case STATUS_BAR_TYPES.error:
      return 'var(--status-bar-error-bg-color)'
    case STATUS_BAR_TYPES.success:
      return 'var(--status-bar-success-bg-color)'
    default:
      return 'var(--status-bar-neutral-bg-color)'
  }
}

const getBorderColor = (type) => {
  switch (type) {
    case STATUS_BAR_TYPES.error:
      return 'var(--status-bar-error-border-color)'
    case STATUS_BAR_TYPES.success:
      return 'var(--status-bar-success-border-color)'
    default:
      return 'var(--status-bar-neutral-border-color)'
  }
}

const Icon = styled.span`
  width: var(--icon-2);
  height: var(--icon-2);
`

const InnerWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: var(--space-4);
  align-items: center;
  width: 100%;
  background-color: ${(props) => getBgColor(props.type)};
  padding: var(--space-4);
  border-style: solid;
  border-width: 1px;
  border-color: ${(props) => getBorderColor(props.type)};
  border-radius: var(--bdr-2);
  font-size: var(--fs-3);
  box-shadow: 5px 5px 8px 0px var(--status-bar-box-shadow-color);
`

const OuterWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: var(--inset);
  margin-left: calc(var(--main-nav-width) + var(--inset));
  left: 0;
  right: 0;
  max-width: 400px;
  z-index: 9999;
  pointer-events: none;
  transform: translateY(100px);
  @media screen and (max-width: 768px) {
    margin-left: 70px;
    margin-right: var(--inset);
  }
  @media screen and (max-width: 600px) {
    max-width: unset;
  }
`
