import { SettingsContainer } from './sharedElements/sharedElements'
import ScrollContainer from '../scrollContainer/ScrollContainer'
import TabGroup from '../tabGroup/TabGroup'
import useTranslate from '../../utils/hooks/useTranslate'
import EmailTemplateSettingsComponent from './EmailTemplateSettingsComponent'
import EmailReminderOverview from './EmailReminderOverview'

const EmailSettings = () => {
  const t = useTranslate()

  const content = (
    <SettingsContainer>
      <ScrollContainer type="settings">
        <h2>{t('emailSettings')}</h2>
        <TabGroup
          position="settings-nav"
          tabs={[
            {
              label: 'emailTemplates',
              id: 'emailTemplates',
              content: <EmailTemplateSettingsComponent />
            },
            {
              label: 'automaticReminders',
              id: 'automaticReminders',
              content: <EmailReminderOverview />
            }
          ]}
        />
      </ScrollContainer>
    </SettingsContainer>
  )

  return content
}

export default EmailSettings
