import {
  API_DATA,
  FIELD_LABELS,
  NOT_SET_TEXT,
  NO_VALID_FROM_SET_TEXT,
  NO_VALID_UNTIL_SET_TEXT
} from '../../utils/constants/constants'
import { secondsToDHM } from '../../utils/helper/dateTimeHelper'
import useTranslate from '../../utils/hooks/useTranslate'
import CustomInput from '../customInput/CustomInput'
import DateDisplay from '../dateDisplay/DateDisplay'
import StartedDurationHelp from '../helpModal/StartedDurationHelp'
import TimerHelp from '../helpModal/TimerHelp'
import { CustomInputList, Tile, TileHeadline } from '../table/tableElements'

const AssessmentTimeSettingsTile = ({ assessment, canEdit }) => {
  const validFrom = assessment.validFrom
  const validUntil = assessment.validUntil
  const t = useTranslate()
  return (
    <Tile minWidth="200px">
      <TileHeadline content="testDurationAndTiming" />
      <CustomInputList>
        <CustomInput
          label={FIELD_LABELS.validFrom}
          content={validFrom ? <DateDisplay date={validFrom} format="dateAndTimeString" /> : t(NO_VALID_FROM_SET_TEXT)}
          value={validFrom}
          dataKey="validFrom"
          type="calendar"
          idValue={assessment.assessmentUuid}
          apiData={API_DATA.assessments}
          disabled={!canEdit}
          showCopyToPtAlert
        />

        <CustomInput
          label={FIELD_LABELS.validUntil}
          content={
            validUntil ? <DateDisplay date={validUntil} format="dateAndTimeString" /> : t(NO_VALID_UNTIL_SET_TEXT)
          }
          value={validUntil}
          dataKey="validUntil"
          type="calendar"
          idValue={assessment.assessmentUuid}
          apiData={API_DATA.assessments}
          disabled={!canEdit}
          showCopyToPtAlert
        />

        <CustomInput
          label={FIELD_LABELS.invitedDuration}
          content={
            assessment.invitedDuration ? t('dhmString', ...secondsToDHM(assessment.invitedDuration)) : t(NOT_SET_TEXT)
          }
          dataKey="invitedDuration"
          type="time-picker"
          value={assessment.invitedDuration}
          idValue={assessment.assessmentUuid}
          apiData={API_DATA.assessments}
          disabled={!canEdit}
          showCopyToPtAlert
          helpContent={<TimerHelp />}
        />

        <CustomInput
          label={FIELD_LABELS.startedDuration}
          content={
            assessment.startedDuration ? t('dhmString', ...secondsToDHM(assessment.startedDuration)) : t(NOT_SET_TEXT)
          }
          dataKey="startedDuration"
          type="time-picker"
          value={assessment.startedDuration}
          idValue={assessment.assessmentUuid}
          apiData={API_DATA.assessments}
          disabled={!canEdit}
          showCopyToPtAlert
          minDuration={assessment.relatedConfig.configDuration}
          helpContent={<StartedDurationHelp />}
        />
      </CustomInputList>
    </Tile>
  )
}

export default AssessmentTimeSettingsTile
