import { SELECT_OPTIONS } from '../../../utils/constants/constants'
import useTranslate from '../../../utils/hooks/useTranslate'
import RadioList from './RadioList'

const ProcessResultToggle = ({ process, useProcessResult, setUseProcessResult, setShowSaveButton }) => {
  const t = useTranslate()
  const handleToggle = (value) => {
    if (process.isEditor()) {
      setShowSaveButton(true)
      setUseProcessResult(value)
    }
  }
  return (
    <RadioList
      options={t(SELECT_OPTIONS.useProcessResults)}
      setterFunction={handleToggle}
      stateValue={useProcessResult}
      disabled={!process.isEditor()}
    />
  )
}

export default ProcessResultToggle
