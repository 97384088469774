import React, { useContext, useMemo } from 'react'
import AssessmentRowSubComponent from './AssessmentRowSubComponent'
import DataTable from '../../components/table/DataTable'
import { ContentContainer } from '../../components/table/tableElements'
import { ENTITIES } from '../../utils/constants/constants'
import { AppContext } from '../../utils/context/AppContext'
import { getAssessmentTableCols } from './assessmentUtils'
import ScrollContainer from '../../components/scrollContainer/ScrollContainer'
import useTranslate from '../../utils/hooks/useTranslate'

const Assessments = () => {
  const context = useContext(AppContext)
  const t = useTranslate()
  const columns = useMemo(
    () => getAssessmentTableCols(t, context.completeDataSet),
    [context.completeDataSet.customTableSettings.assessments]
  )

  return (
    <ScrollContainer type="main">
      <ContentContainer>
        <DataTable
          data={context.completeDataSet.assessments}
          columns={columns}
          RowSubComponent={AssessmentRowSubComponent}
          showRowSelection
          tableName={ENTITIES.assessments}
          hasSubrows
          showFilters
          showAddButton
          showColumnController
        />
      </ContentContainer>
    </ScrollContainer>
  )
}

export default Assessments
